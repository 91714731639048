import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { OptionalDatePickerDialogComponent } from './optional-date-picker-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		MatDialogModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		MatNativeDateModule,
		ReactiveFormsModule,
		A11yModule
	],
	declarations: [
		OptionalDatePickerDialogComponent
	],
	entryComponents: [
		OptionalDatePickerDialogComponent
	],
	exports: [
		OptionalDatePickerDialogComponent
	]
})
export class OptionalDatePickerDialogModule {}
