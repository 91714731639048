import { Component, Inject, OnInit } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { HeyGenPrompt } from '../hey-gen-prompt-editor.component';

@Component({
	selector: 'feature-flag-dialog',
	template: require('./hey-gen-prompt-creator.component.html'),
	styles: [require('./hey-gen-prompt-creator.component.scss')]
})
export class HeyGenPromptCreatorComponent implements OnInit {

	public prompt: HeyGenPrompt;
	public form: FormGroup;

	constructor (
		public dialogRef: GoDialogRef,
		public http: HttpClient,
		@Inject(GO_MODAL_DATA) public data: { prompt?: HeyGenPrompt }
	) {}

	public ngOnInit (): void {
		this.prompt = this.data.prompt || {
			promptName: '(change me)',
			aiRole: 'Interviewer',
			humanRole: 'Interviewee',
			startPrompt: 'Introduce yourself to the interviewer and begin the interview by asking the interviewee to introduce themselves.',
			finalPrompt: 'Close the interview by thanking the interviewer for their time, and telling them you will get back to them if we will be proceeding to next steps.',
			mainPrompt: 'Give a response to the interviewee. Only say 1 to 2 sentences. Ask followup questions for clarity or when more detail is needed.',
			context: 'Conduct a brief, 5-10 minute mock interview for a 7th grade school teacher position at a public elementary school. Act as the principal conducting the interview. Ask a few key questions that cover the following areas: 1) Introduction and background, 2) teaching philosophy and methods, 3) curriculum and instruction. Once the interview is complete, thank them for their time and let them know someone will get back to them about next steps.',
			index: null
		};

		this.form = new FormGroup({
			promptName: new FormControl(this.prompt.promptName, [Validators.required]),
			aiRole: new FormControl(this.prompt.aiRole, [Validators.required]),
			humanRole: new FormControl(this.prompt.humanRole, [Validators.required]),
			startPrompt: new FormControl(this.prompt.startPrompt, [Validators.required]),
			finalPrompt: new FormControl(this.prompt.finalPrompt, [Validators.required]),
			mainPrompt: new FormControl(this.prompt.mainPrompt, [Validators.required]),
			context: new FormControl(this.prompt.context, [Validators.required])
		});
	}

	public cancel () {
		this.dialogRef.close(null);
	}

	public close () {
		this.prompt.promptName = this.form.get('promptName').value;
		this.prompt.aiRole = this.form.get('aiRole').value;
		this.prompt.humanRole = this.form.get('humanRole').value;
		this.prompt.startPrompt = this.form.get('startPrompt').value;
		this.prompt.mainPrompt = this.form.get('mainPrompt').value;
		this.prompt.finalPrompt = this.form.get('finalPrompt').value;
		this.prompt.context = this.form.get('context').value;
		this.dialogRef.close(this.prompt);
	}
}
