import { Inject } from '@angular/core';
import {
	ChangeDetectionStrategy,
	Component
} from '@angular/core';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import { DownloadPWADialogComponent } from 'ngx/go-modules/src/components/dialogs/download-pwa/download-pwa-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PWAService, pwaServiceToken } from 'go-modules/services/pwa/pwa.service';

@Component({
	selector: 'pwa-install-prompt',
	template: require('./pwa-install-prompt.component.html'),
	styles: [require('./pwa-install-prompt.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PWAInstallPromptComponent {
	private HAS_SEEN_PWA_INSTALL_PROMPT = 'HAS_SEEN_PWA_INSTALL_PROMPT';

	constructor (
		private dialog: MatDialog,
		@Inject(uaDetectToken) public UADetect: UADetectClass,
		@Inject(pwaServiceToken) private pwaService: PWAService
	) {}

	public shouldShowInstallPrompt () {
		// If this is not mobile return false
		if (!this.UADetect.isMobile()) return false;

		// If the app is already launched as a PWA return false
		if (this.UADetect.browserDetector.isPWA()) return false;

		// If the user has dismissed the prompt return false
		if (localStorage.getItem(this.HAS_SEEN_PWA_INSTALL_PROMPT) === 'true') return false;

		// If the brower is mobile safari return true
		if (this.UADetect.browserDetector.isMobileSafari()) return true;

		// If the device is Android and it is chrome and there is a A2HS prompt return true
		if (this.UADetect.isAndroid() &&
			this.UADetect.browserDetector.isChrome() &&
			this.pwaService.getA2HSPrompt()) {
			return true;
		}

		// Return false for all other browsers. Add them in once they are proven.
		return false;
	}

	public setHasSeenPWAInstallPrompt () {
		localStorage.setItem(this.HAS_SEEN_PWA_INSTALL_PROMPT, 'true');
	}

	public showiOSorAndroidInstall () {
		if (this.UADetect.isAndroid()) {
			this.showA2HSPrompt();
		} else {
			this.dialog.open(DownloadPWADialogComponent);
		}
	}

	private showA2HSPrompt () {
		if (!this.pwaService.getA2HSPrompt()) {
			return;
		}
		this.pwaService.launchPrompt();
	}
}
