import { Component } from '@angular/core';

@Component({
	selector: 'ngx-no-groups',
	template: require('./no-groups.component.html'),
	styles: [require('./no-groups.component.scss')]
})
export class NgxNoGroupsComponent {

	constructor () {}
}
