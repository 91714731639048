import * as dayjs from 'dayjs';
import { noop } from 'angular';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { ActivityEditorPanel, activityEditorPanelToken } from 'go-modules/activity-editor-panel/activity-editor-panel.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { Masquerade, masqueradeToken } from 'go-modules/masquerade/masquerade.service';
import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CONTENT_TYPES, LIBRARY_TYPES, MODES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { mediaToken } from 'go-modules/models/media/media.factory';
import { rubricEditorModalToken } from 'go-modules/modals/rubric-editor/modal.factory';
import { mediaPreviewToken } from 'go-modules/modals/media-preview/modal.factory';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { LibraryService, ShareLibraryItemResponse } from 'ngx/go-modules/src/services/library/library.service';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import { orgWideRubricReportToken } from 'go-modules/csv-report/org-wide-rubric-report/org-wide-rubric-report.service';
import { ShareLibraryItemDialogComponent } from 'ngx/go-modules/src/components/dialogs/share-library-item-dialog/share-library-item-dialog.component';
import { InvalidEmailsErrorDialogComponent } from 'ngx/go-modules/src/components/dialogs/invalid-emails-error-dialog/invalid-emails-error-dialog.component';
import { Types } from 'ngx/go-modules/src/services/media/media-types';
import { NgxMediaService } from 'ngx/go-modules/src/services/media/media.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { CollectionDestinationComponent, CollectionDestinationData } from 'ngx/go-modules/src/components/library/collection-destination/collection-destination.component';
import { DESTINATION_MODES } from 'ngx/go-modules/src/components/library/collection-destination/collection-destination.constants';
import { PreviewMarkerSetDialogComponent } from 'ngx/go-modules/src/components/dialogs/preview-marker-set-dialog/preview-marker-set-dialog.component';
import { MarkerSetDialogComponent } from '../../dialogs/marker-set-dialog/marker-set-dialog.component';
import {
	OptionalDatePickerDialogComponent
} from 'ngx/go-modules/src/components/dialogs/optional-date-picker-dialog/optional-date-picker-dialog.component';
import {
	ActivityInstructionsDialogComponent
} from 'ngx/go-modules/src/components/dialogs/activity-Instructions-dialog/activity-instructions-dialog.component';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { LibraryCollectionItemCacheService } from 'ngx/go-modules/src/services/library-collection-item-cache/library-collection-item-cache.service';
import type { LibraryCollection, LibraryCollectionItem, LibraryCollectionItemsAndFolders } from 'ngx/go-modules/src/interfaces/library/library-collection';
import type { ContentTypeType } from 'ngx/go-modules/src/interfaces/library/library-types';

@Component({
	selector: 'ngx-collection-item-viewer',
	template: require('./collection-item-viewer.component.html'),
	styles: [require('./collection-item-viewer.component.scss')]
})
export class CollectionItemViewerComponent implements OnInit, OnDestroy {
	@Input() public item: LibraryCollectionItem;
	@Input() public expanded: string[];
	public media: any;
	@Input() public mode: string;
	public modes = MODES;
	@Output() public selectItem: EventEmitter<any> = new EventEmitter();
	public toggledItemEl: HTMLButtonElement;
	@Input() public collections: LibraryCollection[];
	@Input() public archiveCollection: LibraryCollection;
	@Input() public selectedCollection: LibraryCollection;
	@Input() public selectedContentType: ContentTypeType;
	@Output() public loadNewItems: EventEmitter<any> = new EventEmitter();
	@Input() public collectionItems: LibraryCollectionItemsAndFolders;
	@Input() public items: LibraryCollectionItem[];
	@Input() public multiSelect: boolean;
	@Output() public setSelectAllChecked: EventEmitter<boolean> = new EventEmitter();
	@Input() public expandedHeight: number;
	@Input() public currentlySelected: any;
	public itemInUse: boolean;
	@Input() public orgSettings: any;
	public displayMenuOptions: boolean = true;
	public selectedGroup;
	private eventSubscription: any;
	@Output() public setActivityPanelIsOpen: EventEmitter<boolean> = new EventEmitter();
	@Output() public updateItems: EventEmitter<any> = new EventEmitter();
	@Output() public setCurrentlySelected: EventEmitter<any> = new EventEmitter();
	public iconClass;

	constructor (
		private activityService: NgxActivityService,
		private dialog: MatDialog,
		private eventService: EventService,
		private libraryService: LibraryService,
		private mediaService: NgxMediaService,
		private modal: GoModalService,
		private ngxGoToastService: NgxGoToastService,
		private translate: TranslateService,
		private featureFlag: NgxFeatureFlagService,
		private itemCacheService: LibraryCollectionItemCacheService,
		private elementRef: ElementRef,
		@Inject(uaDetectToken) public UADetect: UADetectClass,
		@Inject(activityEditorPanelToken) private activityEditorPanel: ActivityEditorPanel,
		@Inject(activityToken) private activityModel,
		@Inject(masqueradeToken) private masquerade: Masquerade,
		@Inject(mediaPreviewToken) private mediaPreviewModal,
		@Inject(mediaToken) private MediaModel,
		@Inject(orgWideRubricReportToken) private orgWideRubricReportService,
		@Inject(rubricEditorModalToken) private rubricEditorModal,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService
	) {}

	public ngOnInit () {
		this.selectedGroup = this.selectedService.getGroup() || this.selectedService.getAccount();
		// Watch for pubnub changes on library media
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.MEDIA_SYNC))
			.subscribe((ev: GoEvent) => {
				const originalItem = this.item;
				const allowedItemTypes = [CONTENT_TYPES.MEDIA as string, CONTENT_TYPES.DOCUMENTS as string];

				if (!allowedItemTypes.includes(originalItem.collection_item_type)) {
					return;
				}

				const isAllLibraryFlagOn = this.isAllLibraryFlagOn();
				let mediaId;

				if (isAllLibraryFlagOn) {
					mediaId = originalItem.item_id; // this will be the media_id since when the ALL_LIBRARY flag is on we're joining the media table on media.media_id', '=', 'library_collection_items.item_id'
				} else {
					mediaId = originalItem.item.media_id;
				}

				if (mediaId === Number(ev.data.media_id)) {
					if (isAllLibraryFlagOn) {
						originalItem.item = ev.data;
						originalItem.media_type = ev.data.media_type; // we need to do this since the media_type on originalItem is iniitally an empty string
						originalItem.filename = ev.data.filename; // we need to do this since the filename on originalItem doesn't initially have an extension

						this.resolveIconClass(originalItem);
					} else {
						Object.assign(originalItem.item, ev.data);
					}

					// If we are expanded already, handle the media we made for that
					if (this.media) {
						Object.assign(this.media, ev.data);
					}
				}
			});

		this.itemInUse = false;
		if (this.item.collection_item_type === CONTENT_TYPES.RUBRICS) {
			this.displayMenuOptions = false;
		}
		if (this.expanded.includes(this.item.hash)) {
			this.media = this.MediaModel.model(this.item.item);
		}
		if ((!this.userService.currentUser.is_root_owner || this.masquerade.isMasked()) && !this.isAllLibraryFlagOn()) {
			this.itemInUse = this.item.item.in_use;
		}

		this.resolveIconClass(this.item);
	}

	public ngOnDestroy (): void {
		this.eventSubscription?.unsubscribe();
	}

	public formatDate (date) {
		return dayjs(date).format('MMM D, YYYY');
	}

	public getAriaLabel (item) {
		const filename = this.isAllLibraryFlagOn() ? item.filename : item.item.filename;

		let translationKey;
		//if no filename, assume youtube
		const mediaType = filename ?
			this.mediaService.determineMediaType(filename) :
			Types.VIDEO;
		switch(item.collection_item_type)
		{
			case CONTENT_TYPES.RUBRICS:
				translationKey = 'library-collections_content-label-rubric';
				break;
			case CONTENT_TYPES.MARKERS:
				translationKey = 'library-collections_content-label-markers';
				break;
			case CONTENT_TYPES.MEDIA:
				translationKey = mediaType === Types.VIDEO ? 'library-collections_content-label-video' :
					'library-collections_content-label-audio';
				break;
			case CONTENT_TYPES.DOCUMENTS:
				translationKey = mediaType === Types.DOCUMENT ? 'library-collections_content-label-document' :
					'library-collections_content-label-image';
				break;
			case CONTENT_TYPES.ACTIVITIES:
				translationKey = 'library-collections_content-label-activities';
				break;
		}
		return this.translate.instant(translationKey, {name: item.name, date: this.formatDate(item.modified_at)});
	}

	public resolveIconClass (item) {
		const isAllLibraryFlagOn = this.isAllLibraryFlagOn();
		const filename = isAllLibraryFlagOn ? item.filename : item.item.filename;

		//if no filename, assume youtube
		const mediaType = filename ?
			this.mediaService.determineMediaType(filename) :
			Types.VIDEO;
		switch(item.collection_item_type)
		{
			case CONTENT_TYPES.RUBRICS:
				this.iconClass = 'ficon-rubric-filled';
				break;
			case CONTENT_TYPES.MARKERS:
				this.iconClass = 'ficon-app-tag-multiple';
				break;
			case CONTENT_TYPES.MEDIA:
				// Trust mediaType over filename (as thats more accurate until encoding is done)
				const itemMediaType = isAllLibraryFlagOn ? item.media_type : item.item.media_type;
				if (itemMediaType === Types.AUDIO) {
					this.iconClass = 'ficon-app-volume-medium';
				} else if (itemMediaType === '') {
					this.iconClass = 'ficon-spinner';
				} else {
					this.iconClass = mediaType === Types.VIDEO ? 'ficon-app-camcorder' : 'ficon-app-volume-medium';
				}
				break;
			case CONTENT_TYPES.DOCUMENTS:
				this.iconClass = mediaType === Types.DOCUMENT ? 'ficon-document-filled' : 'ficon-image-filled';
				break;
			case CONTENT_TYPES.ACTIVITIES:
				this.iconClass = this.isParentChildSync(item) ? 'ficon-activity-locked' : 'ficon-assignment';
				break;
		}
	}

	public loadRubricUsage (rubricItem) {
		this.libraryService.getRubricItemUsage(
			rubricItem.item.id, this.selectedService.getOrg().group_id
		).subscribe((result: any) => {
			this.displayMenuOptions = true;
			this.itemInUse = result.in_use;
		});
	}

	public expandItem (item) {
		const expanded = this.expanded;
		const hash = item.hash;
		if (expanded.includes(hash)) {
			expanded.splice(expanded.indexOf(hash), 1);
		} else {
			expanded.push(hash);
			setTimeout(() => {
				const expandedItem = this.elementRef.nativeElement;
				const expandedItemRect = expandedItem.getBoundingClientRect();
				const scrollContainer = document.querySelector('.collection-items-list') as HTMLElement;
				const scrollContainerRect = scrollContainer.getBoundingClientRect();
				if (expandedItemRect.bottom > scrollContainerRect.bottom) {
					expandedItem.scrollIntoView({
						behavior: 'smooth',
						block: 'end'
					});
				}
			});
		}

		this.media = this.MediaModel.model(item.item);
		const folderId = item.library_collection_folder_id;
		this.setCurrentlySelected.emit({ hash, folderId });
	}

	public showPreviewButton (item) {
		return item.collection_item_type === CONTENT_TYPES.RUBRICS ||
			item.collection_item_type === CONTENT_TYPES.MARKERS ||
			(item.collection_item_type === CONTENT_TYPES.DOCUMENTS && this.mediaService.isPreviewable(item.item)) ||
			item.collection_item_type === CONTENT_TYPES.ACTIVITIES;
	}

	public previewItem (item) {
		if(item.collection_item_type === CONTENT_TYPES.RUBRICS){
			this.openRubric(item.item_id, 'live');
		} else if (item.collection_item_type === CONTENT_TYPES.DOCUMENTS ||
			item.collection_item_type === CONTENT_TYPES.MEDIA){
			this.openMediaPreviewModal(item, false);
		} else if (item.collection_item_type === CONTENT_TYPES.MARKERS) {
			PreviewMarkerSetDialogComponent.open(this.dialog, {data: {
				markerSet: item.item
			}});
		} else if (item.collection_item_type === CONTENT_TYPES.ACTIVITIES) {
			this.activityService.get(item.item_id).subscribe((activity) => {
				activity = this.activityModel.model(activity);

				this.modal.open(ActivityInstructionsDialogComponent, false, {
					data: {
						activity,
						group: this.selectedGroup
					}
				});
			});
		}
	};

	public openRubric (schemaId, mode) {
		return this.rubricEditorModal.open({
			modalData: {
				options: {
					schemaId,
					mode,
					postData: {
						//if we end up copying the rubric then save to same collection
						add_to_collection: this.selectedCollection.id
					}
				}
			}
		}).result;
	};

	public openMediaPreviewModal (item, edit) {
		const media = this.MediaModel.model(item.item);
		return this.mediaPreviewModal.open({
			modalData: {
				media,
				edit,
				allowDownload: true
			}
		});
	}

	public edit (libraryItem) {
		if (!this.canEdit(libraryItem)) {
			return this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('library-collections_item_edit_error-title'),
					message: this.translate.instant('library-collections_item_edit_error-message')
				}
			}).afterClosed().subscribe(() => {
				this.toggledItemEl.focus();
			});
		}

		if (libraryItem.collection_item_type === CONTENT_TYPES.MEDIA ||
			libraryItem.collection_item_type === CONTENT_TYPES.DOCUMENTS) {
			const media = this.MediaModel.model(libraryItem.item);
			media.description = libraryItem.description;
			media.library_id = libraryItem.library_collection_ids[0];
			return this.mediaPreviewModal.open({
				modalData: {
					media,
					edit: true,
					allowDownload: true,
					groupId: this.selectedGroup.group_id
				}
			}).result
				.then((data) => {
					Object.assign(libraryItem.item, data);
					libraryItem.description = data.description;
					libraryItem.name = data.title;
					libraryItem.modified_at = data.modified_at;
				})
				.catch(noop)
				.finally(() => {
					this.toggledItemEl?.focus();
				});
		} else if (libraryItem.collection_item_type === CONTENT_TYPES.MARKERS) {
			return this.modal.open(MarkerSetDialogComponent, false, {
				data: {
					markerSet: libraryItem.item
				}
			}).afterClosed().subscribe((res) => {
				if (!res.dismissed) {
					Object.assign(libraryItem.item, res);
					libraryItem.description = res.description;
					libraryItem.name = res.title;
					libraryItem.modified_at = res.modified_at;
				}
				this.toggledItemEl?.focus();
			});
		} else if (libraryItem.collection_item_type === CONTENT_TYPES.RUBRICS) {
			this.libraryService.checkRubricUsage(libraryItem.item.id)
				.subscribe((response) => {
					if (response.in_use) {
						const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(
							ConfirmDialogComponent, {
								data: {
									title: this.translate.instant('modal-rubric-edit-copy_title'),
									message: this.translate.instant('modal-rubric-edit-copy_message'),
									confirmText: this.translate.instant('common_create-copy'),
									cancelText: this.translate.instant('common_cancel')
								} as ConfirmDialogData
							});

						return dialogRef.afterClosed().pipe(
							filter((confirm: boolean) => confirm)
						).subscribe(() => {
							//copy item and open rubric editor, allow skip if in select mode
							this.copyItem(libraryItem, true, this.mode === MODES.SELECT);
						});
					} else {
						return this.openRubric(libraryItem.item_id, 'edit')
							.then((data) => {
								Object.assign(libraryItem.item, data);
								libraryItem.name = data.title;
								libraryItem.description = data.desc;
								libraryItem.modified_at = data.modified_at;
							})
							.catch(noop)
							.finally(() => {
								this.toggledItemEl?.focus();
							});
					}
				});
		} else if (libraryItem.collection_item_type === CONTENT_TYPES.ACTIVITIES) {
			const headerOptions: any = {
				editMode: true,
				hideName: false
			};

			this.activityService.get(libraryItem.item.id).subscribe((activity) => {
				activity = this.activityModel.model(activity);
				this.setActivityPanelIsOpen.emit(true);
				activity.group_id = this.selectedGroup.group_id;

				const isOrgCollectionAndCanModifyItems =
					this.libraryService.isOrgCollectionAndCanModifyItems(this.selectedCollection);
				return this.activityEditorPanel.open({
					user: this.userService.currentUser,
					group: this.selectedGroup,
					activity,
					headerOptions,
					libraryMode: true,
					isOrgCollectionAndCanModifyItems,
					orgSettings: this.orgSettings,
					firstFocusSelector: '#activity-editor-name-input'
				}).result.then((newActivity) => {
					libraryItem.name = newActivity.name;
					libraryItem.modified_at = newActivity.modified_at;
					libraryItem.item.parent_child_sync = newActivity.parent_child_sync;
				}).catch(noop)
					.finally(() => {
						this.setActivityPanelIsOpen.emit(false);
						this.toggledItemEl?.focus();
					});
			});
		}
	}

	public canEdit (item) {
		return this.libraryService.isOrgCollectionAndCanModifyItems(this.selectedCollection) ||
			(this.collectionItems.user_ids.includes(item.created_by) &&
				this.selectedCollection.type !== LIBRARY_TYPES.ARCHIVE) ||
			(this.collectionItems.user_ids.includes(this.selectedCollection.created_by) &&
				this.selectedCollection.type === LIBRARY_TYPES.SHARED);
	}

	public setActiveToggle ($event) {
		this.toggledItemEl = $event.currentTarget;
		if (!this.displayMenuOptions) {
			if (this.isAllLibraryFlagOn()) {
				this.loadItemDetails('loadRubricUsage', this.item);
			} else {
				this.loadRubricUsage(this.item);
			}
		}
	}

	public copyItem (item, launchRubricEditor: boolean = false, allowSkip: boolean = false) {
		const destinationData: CollectionDestinationData = {
			collections: this.collections,
			selectedCollection: this.selectedCollection,
			contentType: item.collection_item_type,
			mode: DESTINATION_MODES.COPY,
			allowSkip
		};
		this.modal.open(CollectionDestinationComponent, false, {
			data: destinationData
		}).afterClosed().subscribe((res) => {
			if (res.dismissed) {
				if (res.hasFolderAdded) {
					if (res.collection.id === this.selectedCollection.id) {
						this.loadNewItems.emit({});
					}
				}
				return;
			}
			let {collection} = res;
			const {folder, autoSelect} = res;
			if (autoSelect) {
				collection = this.archiveCollection;
			}
			const items = [{
				collections: [{collection_id: collection.id} as any]
			}] as any[];

			if (folder?.id > 0) {
				items[0].collections[0].folder_id = folder.id;
			}

			// Handle items from archive that may not
			// have an actual libraryItem
			if (item.id) {
				items[0].itemId = item.id;
			} else {
				items[0].item = {
					item_id: item.item_id,
					item_type: item.collection_item_type
				};
			}

			this.libraryService.copyCollectionItem({
				items
			}).subscribe((copiedItems) => {
				if (launchRubricEditor) {
					this.openRubric(copiedItems.data[0].item_id, 'edit')
						.then((res) => {
							if (autoSelect) {
								this.selectItem.emit({item: {item: res}});
							} else {
								this.loadNewItems.emit({expandItem: {collection, folder, item: res}});
							}
						})
						.catch(noop);
				} else if (this.selectedCollection.id === collection.id){
					this.loadNewItems.emit({});
				} else {
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.SUCCESS,
						message: 'library-collections_copied'
					});
				}
			});
		});
	}

	public moveItem (item) {
		const destinationData: CollectionDestinationData = {
			collections: this.collections,
			selectedCollection: this.selectedCollection,
			contentType: item.collection_item_type,
			mode: this.canRestore(item) ? DESTINATION_MODES.RESTORE : DESTINATION_MODES.MOVE,
			item
		};
		this.modal.open(CollectionDestinationComponent, false, {
			data: destinationData
		}).afterClosed().subscribe((res) => {
			if (res.dismissed) {
				if (res.hasFolderAdded) {
					if (res.collection.id === this.selectedCollection.id) {
						this.loadNewItems.emit({});
					}
				}
				return;
			}
			const {collection, folder} = res;
			const data = {
				new_library_collection_id: collection.id,
				new_library_collection_folder_id: folder?.id ?? null,
				items: [
					{
						library_collection_id: this.selectedCollection.id,
						library_collection_folder_id: item.library_collection_folder_id,
						library_collection_item_id: item.id
					}
				]
			};
			this.libraryService.moveCollectionItem(data)
				.subscribe({
					next: () => {
						this.loadNewItems.emit({});
						// update cache for the moved item to update the new folder id
						item.library_collection_folder_id = data.new_library_collection_folder_id;
						this.itemCacheService.addItem(item);
					},
					error: () => {
						this.dialog.open(MessageDialogComponent, {
							data: {
								title: this.translate.instant('library-collections_item_move_error-title'),
								message: this.translate.instant('library-collections_item_move_error-message')
							}
						}).afterClosed().subscribe(() => {
							this.toggledItemEl.focus();
						});
					}
				});
		});
	}

	public shareItem (item) {
		const dialog$ = ShareLibraryItemDialogComponent.open(this.dialog, {data: {
			collection_id: item.library_collection_ids[0],
			item_id: item.id
		}}).afterClosed();

		dialog$.subscribe((res: ShareLibraryItemResponse) => {
			if (!res) return;
			if (res.invalidUsers.length > 0) {
				InvalidEmailsErrorDialogComponent.open(this.dialog, {data: {
					title: this.translate.instant('modal-share-library-item_share-fail_title'),
					message: this.translate.instant('modal-share-library-item_share-fail_message'),
					emails: res.invalidUsers
				}});
			} else {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'modal-share-library-item_share-success'
				});
			}
		});
	}

	// Either they created the shared collection
	// or they created the item in the collection (with the exception of archive)
	// or any legacy items not in my history
	public canRemove (item) {
		if (this.libraryService.isOrgCollectionAndCanModifyItems(this.selectedCollection)) {
			return true;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.MANAGED) {
			return this.userService.currentUser.is_root_content;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.ARCHIVE) {
			return false;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.DIRECT_SHARE ||
			item.shared_to === this.userService.currentUser.user_id) {
			return true;
		}
		if (item.legacy_id !== null) {
			return this.collectionItems.user_ids.includes(this.selectedCollection.created_by);
		}
		return this.collectionItems.user_ids.includes(item.created_by) ||
			(this.collectionItems.user_ids.includes(this.selectedCollection.created_by) &&
				this.selectedCollection.type === LIBRARY_TYPES.SHARED);
	}

	public canRestore (item) {
		return !item.location && item.id !== null && this.selectedCollection.type === LIBRARY_TYPES.ARCHIVE;
	}

	public canMove (item) {
		if (this.libraryService.isOrgCollectionAndCanModifyItems(this.selectedCollection)) {
			return true;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.MANAGED) {
			return this.userService.currentUser.is_root_content;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.ARCHIVE) {
			return false;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.DIRECT_SHARE ||
			item.shared_to === this.userService.currentUser.user_id) {
			if (item.collection_item_type === CONTENT_TYPES.ACTIVITIES && this.isParentChildSync(item)) {
				return false;
			}
			return true;
		}
		if (item.legacy_id !== null) {
			return this.collectionItems.user_ids.includes(this.selectedCollection.created_by);
		}
		return this.collectionItems.user_ids.includes(item.created_by) ||
			(this.collectionItems.user_ids.includes(this.selectedCollection.created_by) &&
				this.selectedCollection.type === LIBRARY_TYPES.SHARED);
	}

	public canShare () {
		return !this.orgSettings.block_copied_assignment_editing &&
			this.selectedCollection.type !== LIBRARY_TYPES.ARCHIVE;
	}

	public removeItem (item) {
		let removePromise = Promise.resolve(null);
		let title = this.translate.instant('library-collections_remove-item');
		let message = this.translate.instant('library-collections_remove-message');

		if (item.collection_item_type === CONTENT_TYPES.ACTIVITIES &&
			this.isParentChildSync(item) &&
			this.selectedCollection.type === LIBRARY_TYPES.ORG
		) {
			removePromise = new Promise((resolve, _reject) => {
				this.activityService.getChildrenCount(item.item_id).subscribe({
					next: (res) => {
						if (res.activities > 0) {
							title = this.translate.instant('modal-activity-remove-master-warning_title');
							message = this.translate.instant('modal-activity-remove-master-warning_message', {
								folders: res.activities
							});
						}
						return resolve(null);
					},
					error: () => {
						title = this.translate.instant('modal-activity-remove-master-warning_title');
						message = this.translate.instant('modal-activity-remove-master-warning_message', {
							folders: ''
						});
						return resolve(null);
					}
				});
			});
		}

		removePromise.then(() => {
			const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
				data: {
					title,
					message,
					confirmText: this.translate.instant('common_remove'),
					cancelText: this.translate.instant('common_cancel')
				} as ConfirmDialogData
			});

			dialogRef.afterClosed().pipe(
				filter((confirm: boolean) => confirm)
			).subscribe(() => {
				this.libraryService.removeCollectionItem(this.selectedCollection.id, [item])
					.subscribe(() => {
						const items = this.items
							.filter((index) => (index.id !== item.id));
						const collectionItems = this.collectionItems.items
							.filter((index) => (index.id !== item.id));
						this.items = items;
						this.collectionItems.items = collectionItems;
						this.updateItems.emit();
					});
			});
		});
	}

	public checkSelection () {
		this.setSelectAllChecked.emit(this.collectionItems.items.every((item) => item.checked));
	}

	public shouldShowEdit () {
		if (this.selectedCollection.type === LIBRARY_TYPES.MANAGED) {
			return this.userService.currentUser.is_root_content;
		}
		return this.selectedCollection.type !== LIBRARY_TYPES.ARCHIVE;
	}

	public downloadItem (item) {
		this.MediaModel.download(item.item_id);
	}

	public async downloadReport (item) {
		this.dialog.open(OptionalDatePickerDialogComponent, {
			data: { submitText: 'common_download', title: 'rubric-report-date-picker_title' }
		}).afterClosed().subscribe(async (res)=> {
			if (!res || res.dismissed) return;

			try {
				await this.orgWideRubricReportService.execute(
					item.item_id, this.selectedService.getOrg().group_id, res.start_date, res.end_date
				);
			} catch (error) {
				return this.dialog.open(MessageDialogComponent, {
					data: {
						title: this.translate.instant('rubric-report_download-failed-title'),
						message: this.translate.instant('rubric-report_download-failed-message')
					}
				}).afterClosed().subscribe(() => {
					this.toggledItemEl.focus();
				});
			}
		});
	}

	public shouldShowDownloadButton (item): boolean {
		const isAllLibraryFlagOn = this.isAllLibraryFlagOn();

		if (isAllLibraryFlagOn) {
			// it seems that items without filenames are youtube videos therefore shouldn't be downloadable
			return [CONTENT_TYPES.MEDIA, CONTENT_TYPES.DOCUMENTS].includes(item.collection_item_type) &&
				!!item.filename;
		}

		return !!item.item.filename;
	}

	public loadItemDetails (method, item) {
		let cachedItem;
		const isAllLibraryFlagOn = this.isAllLibraryFlagOn();

		if (isAllLibraryFlagOn) {
			cachedItem = this.itemCacheService.getItem(item.hash);

			// If not in cache, check if nested item is already available (library history)
			if (cachedItem == null && item.item != null) {
				this.itemCacheService.addItem(item);
				cachedItem = item;
			}
		}

		if (cachedItem) {
			this.item = cachedItem;

			if (method === 'expandItem') {
				this[method](cachedItem);
			} else {
				this[method](cachedItem);
			}
		} else if (isAllLibraryFlagOn) {
			this.libraryService.getCollectionItemDetails(item.id)
				.subscribe((res) => {
					const itemWithDetails = {...item, ...res};
					this.item = itemWithDetails;
					this.itemCacheService.addItem(itemWithDetails);

					if (method === 'expandItem') {
						this[method](itemWithDetails);
					} else {
						this[method](itemWithDetails);
					}
				});
		} else if (method === 'expandItem') {
			this[method](item);
		} else {
			this[method](item);
		}
	}

	private isParentChildSync (item) {
		if (this.isAllLibraryFlagOn()) {
			return item.parent_child_sync;
		}
		return item.item.parent_child_sync;
	}

	private isAllLibraryFlagOn (): boolean {
		return this.featureFlag.isAvailable('ALL_LIBRARY');
	}
}
