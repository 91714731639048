import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, ActionToIconClassMap, IconClass } from './action-to-icon-class-map';
import { TranslationKeyMap } from './translation-key-map';

@Component({
	selector: 'ngx-toolbar-button',
	template: require('./toolbar-button.component.html'),
	styles: [require('./toolbar-button.component.scss')]
})
export class NgxToolbarButtonComponent implements OnInit {
	@Input() public action: Action;
	@Input() public disabled: boolean = false;
	@Output() public clicked: EventEmitter<any> = new EventEmitter();
	public iconClass: IconClass;

	constructor (private translate: TranslateService) {}

	public ngOnInit (): void {
		if (!this.action) {
			throw new Error('Property binding `action` is required!');
		} else if (!ActionToIconClassMap[this.action]) {
			throw new Error('Property binding `action` is invalid!');
		}

		this.iconClass = ActionToIconClassMap[this.action];
	}

	public getTooltipText (): string {
		return this.translate.instant(TranslationKeyMap[this.action]);
	}
}
