import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from 'ngx/go-modules/src/components/auth/login';
import { AuthTemplateModule } from 'ngx/go-modules/src/components/auth-template/auth-template.module';
import { SsoProviderButtonModule } from '../../sso-provider-button/sso-provider-button.module';
import { PasswordVisibilityToggleModule } from 'ngx/go-modules/src/directives/password-visibility-toggle/password-visibility-toggle.module';

@NgModule({
	declarations: [
		LoginComponent
	],
	imports: [
		CommonModule,
		AuthTemplateModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		TranslateModule,
		SsoProviderButtonModule,
		PasswordVisibilityToggleModule
	],
	entryComponents: [LoginComponent],
	exports: [
		LoginComponent
	]
})
export class LoginModule {}
