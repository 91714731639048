import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS, LTI_ENVIRONMENT_MODES } from 'ngx/go-modules/src/services/environment-vars/environments';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';

export enum ACTIVITY_SOURCES {
	NEW = 'new',
	LIBRARY = 'library',
	PREVIOUS = 'previous',
	CREATE = 'create'
}

@Component({
	selector: 'activity-source-dialog',
	template: require('./activity-source-dialog.component.html'),
	styles: [require('./activity-source-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivitySourceDialogComponent implements OnInit {
	public environmentVarsService: EnvironmentVarsService;
	public isDefaultLti: boolean = false;
	public sources = ACTIVITY_SOURCES;

	constructor (
		public dialogRef: GoDialogRef,
		private featureFlag: NgxFeatureFlagService,
		private authService: NgxAuthService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public ngOnInit () {
		const environment: any = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) || {};
		this.isDefaultLti = environment.name === ENVIRONMENTS.LTI && environment.mode === LTI_ENVIRONMENT_MODES.DEFAULT;
	}

	public close (source?: ACTIVITY_SOURCES) {
		this.dialogRef.close(source);
	}

	public showCreateTemplate (): boolean {
		if (this.userService.currentUser.is_free_trial_user) {
			return false;
		}
		if (this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI)) {
			return this.authService.isAdmin();
		}
		return this.selectedService.getOrg().is_admin;
	}
}
