import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';

@Component({
	selector: 'download-pwa-dialog',
	template: require('./download-pwa-dialog.component.html'),
	styles: [require('./download-pwa-dialog.component.scss')]
})
export class DownloadPWADialogComponent {

	constructor (
		private dialogRef: MatDialogRef<DownloadPWADialogComponent>,
		@Inject(uaDetectToken) public UADetect: UADetectClass
	) {}

	public close () {
		this.dialogRef.close();
	}
}
