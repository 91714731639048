import { GoPage } from 'go-modules/go-page/go-page.service';
import { States } from 'go-modules/enums/states.enum';
import type { StateParams, StateService } from '@uirouter/angularjs';
import { AuthService } from 'go-modules/services/auth/auth.service';
import { LoginEvent } from 'ngx/go-modules/src/components/auth/login';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import * as angular from 'angular';
import { SanitizerService } from 'go-modules/services/xss/sanitizer.service';
import { SessionManagerService } from 'go-modules/session-manager/session-manager.service';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';

export class LoginViewController implements ng.IOnInit {
	public loading: boolean = false;
	public formError: string = null;

	/* @ngInject */
	constructor (
		private authService: AuthService,
		private $state: StateService,
		private $stateParams: StateParams,
		private goPage: GoPage,
		private $translate: ng.translate.ITranslateService,
		private ngxAuthService: NgxAuthService,
		private $location: angular.ILocationService,
		private $window: angular.IWindowService,
		private sanitizerService: SanitizerService,
		private sessionManager: SessionManagerService,
		private ngxDowngradeModalService: DowngradeModalService
	) {}

	public $onInit () {
		if ((this.$stateParams.course || this.$stateParams.invite) && this.sessionManager.isActive()) {
			this.toSignUp();
		}

		this.$translate.onReady()
			.then(() => {
				this.goPage.setTitle(this.$translate.instant('document-title_login'));

				const accountLockTime = this.sessionManager.getAccountLockedTime();
				if (accountLockTime) {
					this.showAccountLockedModal(accountLockTime);
				}
			});
	}

	/**
	 * Handles form submit.
	 *
	 * @return {void}
	 */
	public submit (data: LoginEvent): ng.IPromise<void> {
		this.formError = null;
		this.loading = true;
		let loginResponse = null;
		let options = null;

		return this.authService.login(data.username, data.password)
			.then((response) => {
				loginResponse = response.data;
				options = {
					inviteId: this.$stateParams.invite || this.$stateParams.course,
					redirect: this.$stateParams.redirect ? this.getRedirectUrl(this.$stateParams.redirect) : undefined
				};
				return this.authService.setUpUser(loginResponse.user, options);
			})
			.then((state) => {
				if (state) {
					this.$state.go(state.name, state.params, state.options);
				}
			})
			.catch((error) => {

				this.loading = false;

				if (error instanceof Error) {
					if(error.message === 'login-accept-invite-fail-message' && this.sessionManager.isActive()) {
						this.toSignUp();
					}

					this.formError = error.message;
					return;
				}

				switch (error.status) {
					case 423:
						const accountLockTime = error.data.remainingAccountLockSeconds;
						const remainingAccountLockTimeMinutes = this.sessionManager.convertToMinutes(accountLockTime);
						this.showAccountLockedModal(remainingAccountLockTimeMinutes);
						break;
					case 404:
						this.formError = 'login-accept-invite-fail-message';
						break;
					case 409:
						this.formError = 'old-site_pre-existing-lms-account-message';
						break;
					case 410:
						this.formError = 'common-validation_sso-exists-on-login';
						break;
					case 400:
						this.formError = 'common-validation_user-password-incorrect';
						break;
					default:
						this.formError = 'unknown-error-description';
				}

				this.formError = this.$translate.instant(this.formError);
			});
	}

	/**
	 * Navigate to forgot password page.
	 *
	 * @returns {void}
	 */
	public forgotPassword (): void {
		this.$state.go(States.AUTH_FORGOT, {}, { reload: true });
	}

	/**
	 * Navigates to user sign up page.
	 *
	 * @returns {void}
	 */
	public toSignUp (): void {
		if (this.$stateParams.course || this.$stateParams.invite) {
			// We have a join link, bypass account type choice because it's
			// a presenter invite link
			this.$state.go(States.AUTH_COURSE_CONFIRM, {}, { location: false });
		} else {
			this.$state.go(States.AUTH_SIGNUP, {}, {location: false});
		}
	}

	public loginWithSso (provider): ng.IPromise<void> {
		const { course, invite, redirect } = this.$location.search();

		return this.ngxAuthService.getAuthUrl(provider, { course, invite, redirect })
			.then(({ url }: { url: string }) => {
				this.$window.location.href = url;
			});
	}

	private showAccountLockedModal (remainingTime): void {
		const modalData = {
			message: this.$translate.instant('modal-account-locked-message', {number: remainingTime}),
			forgotPasswordUrl: '/dashboard/auth/forgot'
		};
		this.ngxDowngradeModalService.openAccountLockoutDialog(modalData);
	}

	private getRedirectUrl (redirectUrl: string) {
		return this.sanitizerService.isValidRedirectUrl(redirectUrl) ? redirectUrl : '/dashboard';
	}
}
