import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { CollectionItemViewerComponent } from './collection-item-viewer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMediaThumbnailModule } from 'ngx/go-modules/src/components/media-thumbnail/media-thumbnail.module';
import { ShareLibraryItemDialogModule } from 'ngx/go-modules/src/components/dialogs/share-library-item-dialog/share-library-item-dialog.module';
import { InvalidEmailsErrorDialogModule } from 'ngx/go-modules/src/components/dialogs/invalid-emails-error-dialog/invalid-emails-error-dialog.module';
import { CollectionDestinationModule } from 'ngx/go-modules/src/components/library/collection-destination/collection-destination.module';
import { PreviewMarkerSetDialogModule } from 'ngx/go-modules/src/components/dialogs/preview-marker-set-dialog/preview-marker-set-dialog.module';
import { MarkerSetDialogModule } from 'ngx/go-modules/src/components/dialogs/marker-set-dialog/marker-set-dialog.module';
import {
	OptionalDatePickerDialogModule
} from 'ngx/go-modules/src/components/dialogs/optional-date-picker-dialog/optional-date-picker-dialog.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		NgxMediaThumbnailModule,
		FormsModule,
		ReactiveFormsModule,
		ShareLibraryItemDialogModule,
		InvalidEmailsErrorDialogModule,
		CollectionDestinationModule,
		PreviewMarkerSetDialogModule,
		MarkerSetDialogModule,
		OptionalDatePickerDialogModule
	],
	declarations: [
		CollectionItemViewerComponent
	],
	entryComponents: [
		CollectionItemViewerComponent
	],
	exports: [
		CollectionItemViewerComponent
	]
})
export class CollectionItemViewerModule {}
