import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import dayjs from 'dayjs';
import { License } from 'ngx/go-modules/src/interfaces/licenses';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { LazyPaymentLoader, lazyPaymentLoaderToken } from 'go-modules/payment-panel/lazy-payment-loader.service';
import { LICENSE_TRANSACTION_TYPE, PAYMENT_TYPES, PURCHASE_TYPES } from 'go-modules/payment-panel/payment-panel.controller';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { LicenseService } from 'ngx/go-modules/src/services/license/license.service';

export interface LicenseRenewalSnackbarData {
	license: License;
}

@Component({
	selector: 'license-renewal-snack-bar',
	template: require('./license-renewal-snack-bar.component.html'),
	styles: [require('./license-renewal-snack-bar.component.scss')]
})
export class LicenseRenewalSnackBarComponent {
	public numDays = null;

	constructor (
		private eventService: EventService,
		private snackbarRef: MatSnackBarRef<LicenseRenewalSnackBarComponent>,
		private licenseService: LicenseService,
		@Inject(MAT_SNACK_BAR_DATA) public data: LicenseRenewalSnackbarData,
		@Inject(userServiceToken) public userService: UserService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(lazyPaymentLoaderToken) private paymentLoader: LazyPaymentLoader
	) {
		this.getDays();
	}

	public static open (snackBar: MatSnackBar, data: LicenseRenewalSnackbarData) {
		return snackBar.openFromComponent(
			LicenseRenewalSnackBarComponent,
			{
				panelClass: 'snack-bar-panel',
				horizontalPosition: 'end',
				data,
				duration: 15000
			}
		);
	}

	public isFreeTrial (): boolean {
		return this.data.license.salesforce_license.is_free_trial;
	}

	public getDays () {
		this.numDays = dayjs(this.data.license.ends_at).diff(dayjs(), 'days');
	}

	public getMessageTranslation (): string {
		if (this.numDays > 1) {
			return this.isFreeTrial() ? 'license-renewal-snackbar_message-free-trial' : 'license-renewal-snackbar_message';
		} else if (this.numDays === 1) {
			return this.isFreeTrial() ? 'license-renewal-snackbar_message-expire-tomorrow-free-trial' : 'license-renewal-snackbar_message-expire-tomorrow';
		} else if (this.numDays === 0) {
			return this.isFreeTrial() ? 'license-renewal-snackbar_message-expire-today-free-trial' : 'license-renewal-snackbar_message-expire-today';
		} else {
			return this.isFreeTrial() ? 'license-renewal-snackbar_message-expired-free-trial' : 'license-renewal-snackbar_message-expired';
		}

	}

	public close () {
		this.snackbarRef.dismiss();
	}

	public openPaymentPanel () {
		const license = this.data.license;
		const invertedLicense = license.salesforce_license;
		invertedLicense.license = Object.assign({}, license, {salesforce_license: undefined});

		this.licenseService.fetchOrCreateUserAccount(
			invertedLicense.license_id,
			this.selectedService.getOrg().group_id
		).subscribe((account)=> {
			this.paymentLoader.openPayForm(
				this.userService.currentUser,
				account,
				() => this.refreshCourse(),
				invertedLicense,
				PAYMENT_TYPES.CARD,
				PURCHASE_TYPES.LICENSE,
				LICENSE_TRANSACTION_TYPE.INITIAL
			);
		});
	}

	private refreshCourse () {
		this.eventService.broadcast(EVENT_NAMES.LICENSE_RENEWED);
		this.close();
	}
}
