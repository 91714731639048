import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import { activityReport } from '../activity-report';
import { SidePanelModule } from '../side-panel';
import { sessionManager } from '../session-manager';
import { csvReportModule } from 'go-modules/csv-report';

import { ActivityReportPanelService } from './activity-report-panel.service';

import './style.less';

export const activityReportPanelModule = angular.module('activity-report-panel', [
	activityReport.name,
	angularTranslate,
	SidePanelModule,
	sessionManager,
	csvReportModule
])
	.service(ActivityReportPanelService.NG1_INJECTION_NAME, ActivityReportPanelService);
