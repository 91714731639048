import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ShowPasswordButtonComponent } from './show-password-button.component';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [
		ShowPasswordButtonComponent
	],
	imports: [
		CommonModule,
		MatIconModule,
		GoTooltipModule,
		TranslateModule
	],
	entryComponents: [ShowPasswordButtonComponent],
	exports: [
		ShowPasswordButtonComponent
	]
})
export class ShowPasswordButtonModule {}
