import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxCommentReactionsComponent } from './comment-reactions.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { A11yModule } from '@angular/cdk/a11y';
@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		PickerModule,
		A11yModule
	],
	declarations: [
		NgxCommentReactionsComponent
	],
	entryComponents: [
		NgxCommentReactionsComponent
	],
	exports: [
		NgxCommentReactionsComponent
	]
})
export class CommentReactionsModule {}
