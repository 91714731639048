import { AfterViewInit, Component, ElementRef, Inject, NgZone, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { UserService as NgxUserService } from 'ngx/go-modules/src/services/user/user.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import * as dayjs from 'dayjs';

export enum SurveyMode {
	RATING = 'RATING',
	FEEDBACK = 'FEEDBACK'
}

@Component({
	selector: 'customer-survey-snack-bar',
	template: require('./customer-survey-snack-bar.component.html'),
	styles: [require('./customer-survey-snack-bar.component.scss')]
})
export class CustomerSurveySnackBarComponent implements AfterViewInit {
	public static readonly localStorageKey = 'customer-survey';
	@ViewChild('firstFocusableElement') public firstFocusableElement!: ElementRef;
	public form = new FormGroup({
		rating: new FormControl(null),
		feedback: new FormControl('')
	});
	public hoveredRating: number = null;
	public mode: SurveyMode;
	public SurveyModes = SurveyMode;
	public inviteField: string|null = null;

	constructor (
		@Inject(MAT_SNACK_BAR_DATA) public data,
		private ngxUserService: NgxUserService,
		private snackbarRef: MatSnackBarRef<CustomerSurveySnackBarComponent>,
		private ngxGoToastService: NgxGoToastService,
		private snackbar: MatSnackBar,
		private ngZone: NgZone,
		@Inject(userServiceToken) private userService: UserService
	) {
		this.mode = this.data.mode;

		if (this.mode === SurveyMode.RATING) {
			this.form.get('rating').setValidators([Validators.required]);
		} else {
			this.form.get('feedback').setValidators([Validators.required]);
			this.inviteField = 'feedback';
		}
	}

	public static open (snackBar: MatSnackBar, mode = SurveyMode.RATING) {
		return snackBar.openFromComponent(
			CustomerSurveySnackBarComponent,
			{
				panelClass: 'snack-bar-panel',
				horizontalPosition: 'end',
				data: {
					mode
				}
			}
		);
	}

	public static canShow (userId) {
		const newKey = localStorage.getItem(`${userId}-` + CustomerSurveySnackBarComponent.localStorageKey);

		// Always show if no cached customer survey
		if (!newKey) {
			return true;
		}

		const nextShowDate = dayjs(newKey);
		const currentDate = dayjs();
		return currentDate.isAfter(nextShowDate);
	}

	public ngAfterViewInit (): void {
		this.snackbarRef.afterOpened().subscribe(() => {
			this.firstFocusableElement.nativeElement.focus();
		});
	}

	public cancel () {
		// prevent an occasional issue where it doesnt close immediately
		this.ngZone.run(() => {
			this.snackbarRef.dismiss();
		});
	}

	public close (snoozed?: boolean) {
		const nextShowDate = snoozed ? dayjs().add(24, 'hour').toString() : dayjs().add(90, 'day').toString();
		localStorage.setItem(`${this.userService.currentUser.user_id}-` + CustomerSurveySnackBarComponent.localStorageKey, nextShowDate);

		// prevent an occasional issue where it doesnt close immediately
		this.ngZone.run(() => {
			this.snackbarRef.dismiss();
		});
	}

	public submit () {
		if (this.form.valid) {
			this.ngZone.run(() => {
				this.snackbarRef.dismiss();
			});
			this.ngxUserService.sendFeedback(
				this.form.controls.rating.value,
				this.form.controls.feedback.value,
				this.inviteField
			).subscribe({
				next: () => {
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.SUCCESS,
						message: 'customer-feedback-thanks'
					});

					if (this.mode === SurveyMode.RATING) {
						localStorage
							.setItem(`${this.userService.currentUser.user_id}-` + CustomerSurveySnackBarComponent.localStorageKey, dayjs().add(90, 'day').toString());
					}
				},
				error: () => {
					// re open
					CustomerSurveySnackBarComponent.open(this.snackbar, this.mode);
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.ERROR,
						message: 'customer-feedback-error'
					});
				}
			});
		}
	}
}
