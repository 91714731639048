import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	DoCheck,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { LazyPaymentLoader, lazyPaymentLoaderToken } from 'go-modules/payment-panel/lazy-payment-loader.service';
import { PURCHASE_TYPES } from 'go-modules/payment-panel/payment-panel.controller';
import {
	SelectedInterface,
	SelectedService,
	selectedServiceToken
} from 'go-modules/services/selected/selected.service';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	filter,
	finalize,
	map,
	Observable,
	Subject,
	Subscription,
	takeUntil
} from 'rxjs';
import { BaseDataSource } from 'ngx/go-modules/src/classes/base-data-source';
import { ConsumeSeatResponse, NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { CoursePaywallOnPayEvent } from 'ngx/go-modules/src/angularjs-wrappers/course-paywall.directive';
import {
	GroupSettingsPanelService,
	groupSettingsPanelServiceToken
} from 'go-modules/group-settings-panel/group-settings-panel.service';
import { groupToken } from 'go-modules/models/group-dep/group.factory';
import { BillingOptions } from 'go-modules/services/group/group.service';
import { NgxCourseService } from 'ngx/go-modules/src/services/course/course.service';
import { StateService } from '@uirouter/angularjs';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { States } from 'go-modules/enums/states.enum';


export enum ZeroStates {
	DEACTIVATED_ROLE = 1,
	NO_ELIGIBLE_BILLING = 2,
	MAX_SEAT_REACHED = 3,
	COURSE_PAYMENT_WALL = 4
}

export interface ZeroStateChangedEvent {
	hasZeroState: boolean;
	isLoading: boolean;
	errorCode: number | null;
}

@Component({
	selector: 'data-source-zero-state',
	template: require('./data-source-zero-state.component.html'),
	styles: [require('./data-source-zero-state.component.scss')]
})
export class DataSourceZeroStateComponent implements OnInit, OnChanges, OnDestroy, DoCheck {
	@Output() public zeroStateChanged = new EventEmitter<ZeroStateChangedEvent>();
	@Input() public dataSource: BaseDataSource<any, any>;

	public zeroStates = ZeroStates;
	public errorCode: HttpStatusCode = null;
	public isLoading: boolean = false;
	public group;
	public user;
	public hasConsumingSeatError = false;
	public eligibleProducts = [];
	public eligibleLicenses = [];
	public isLoaded: Observable<boolean>;
	public loadingSubscription: Subscription;
	public errorSubscription: Subscription;
	public hasZeroState$: Observable<boolean>;

	private hasZeroState$$ = new BehaviorSubject(false);
	private destroyed$$ = new Subject();
	private isGetDataLoaded$$ = new BehaviorSubject<boolean>(false);
	private isDataSourceLoaded$$ = new BehaviorSubject<boolean>(false);
	private isConsumeSeatLoaded$$ = new BehaviorSubject<boolean>(true);

	public get hasErrorState () {
		return !isNaN(this.errorCode ?? undefined);
	}

	constructor (
		@Inject(groupToken) public groupModel,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(lazyPaymentLoaderToken) private paymentLoader: LazyPaymentLoader,
		@Inject(groupSettingsPanelServiceToken) private groupSettingsPanelService: GroupSettingsPanelService,
		@Inject('Window') private window: Window,
		@Inject($stateToken) private $state: StateService,
		private groupService: NgxGroupService,
		private cdr: ChangeDetectorRef,
		private courseService: NgxCourseService
	) {
		this.hasZeroState$ = this.hasZeroState$$.asObservable();
	}

	public get isGroupIsACourse () {
		return this.group instanceof this.groupModel.GroupModel && this.group.isCourse();
	}

	public ngDoCheck (): void {
		this.hasZeroState$$.next(this.hasErrorState || !!this.getZeroStateStatus());
	}

	public ngOnInit (): void {
		this.group = this.selectedService.getGroup();
		this.user = this.userService.currentUser;

		this.consumeSeat();
		this.getEligibleBillings();

		this.selectedService.selectedSubject
			.asObservable()
			.pipe(
				takeUntil(this.destroyed$$),
				filter((selected) => selected.group),
				filter((selected) => this.group?.getId() !== selected.group.getId()))
			.subscribe((selected: SelectedInterface) => {
				this.group = selected.group;
				this.getEligibleBillings();
			});

		this.isLoaded =	combineLatest([
			this.isGetDataLoaded$$.asObservable(),
			this.isDataSourceLoaded$$.asObservable(),
			this.isConsumeSeatLoaded$$.asObservable()
		]).pipe(map(([dataLoaded, dataSourceLoaded, consumeSeatLoaded]) => {
			return dataLoaded && dataSourceLoaded && consumeSeatLoaded;
		}));

		combineLatest([this.isLoaded, this.hasZeroState$$.asObservable()])
			.pipe(
				takeUntil(this.destroyed$$),
				distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1]),
				debounceTime(500)
			)
			.subscribe(([isLoaded, hasZeroState]) => {
				this.zeroStateChanged.emit({isLoading: !isLoaded, hasZeroState, errorCode: this.errorCode });
			});
	}

	public ngOnDestroy () {
		this.errorSubscription?.unsubscribe();
		this.loadingSubscription?.unsubscribe();
		this.destroyed$$.next(true);
		this.destroyed$$.complete();
	}

	public ngOnChanges (changes: SimpleChanges): void {
		if (changes.dataSource && this.dataSource) {
			this.errorSubscription?.unsubscribe();
			this.loadingSubscription?.unsubscribe();
			this.loadingSubscription = this.dataSource.loading$.subscribe((isLoading) => {
				if (isLoading) this.errorCode = null;
				this.isDataSourceLoaded$$.next(!isLoading);
			});

			this.errorSubscription = this.dataSource.onError$
				.subscribe((error: HttpErrorResponse) => {
					this.errorCode = error.status;
					this.cdr.detectChanges();
				});
		}
	}

	public getZeroStateStatus () {
		if(!this.isGroupIsACourse) {
			return;
		}

		if (this.group.isPresenterDeactivated()) {
			return ZeroStates.DEACTIVATED_ROLE;
		} else if(this.hasNoEligibleBilling()) {
			return ZeroStates.NO_ELIGIBLE_BILLING;
		} else if(this.shouldShowPaymentWall()) {
			return ZeroStates.COURSE_PAYMENT_WALL;
		} else if(this.hasConsumingSeatError) {
			return ZeroStates.MAX_SEAT_REACHED;
		}
	}

	public getEligibleBillings () {
		if(!this.isGroupIsACourse || this.group.hasPresenterRole()) {
			this.isGetDataLoaded$$.next(true);
			return;
		}

		this.isGetDataLoaded$$.next(false);
		return this.groupService.getEligibleBillingObservable(this.group.group_id)
			.pipe(finalize(() => this.isGetDataLoaded$$.next(true)))
			.subscribe((data: BillingOptions) => {
				this.eligibleProducts = data.products;
				this.eligibleLicenses = data.licenses.filter((value) => {
					return value.id !== this.group.license?.id;
				});
			});
	}

	public onPay ($event: CoursePaywallOnPayEvent) {
		return this.paymentLoader.openPayForm(
			this.user,
			this.group,
			() => this.window.location.reload(),
			null,
			$event.type,
			PURCHASE_TYPES.COURSE,
			null
		);
	}

	public onCredit () {
		this.groupService.consumeCredit(this.group.group_id)
			.then(() => this.window.location.reload())
			.catch((error) => this.errorCode = error.status);

	}

	public openGroupSettings () {
		this.groupSettingsPanelService.open(this.group, this.user, null, null)
			.result.then((result) => {
				if(result) {
					return this.courseService.getCourseLicense(this.group.group_id)
						.subscribe((license) => {
							this.group.license = license;
							this.selectedService.setLicense(this.group.license);
							this.ngDoCheck();
							this.cdr.detectChanges();
						});
				}
			});
	}

	/**
	 * Whether or not to show the group payment wall
	 *
	 * @returns {boolean}
	 */
	private shouldShowPaymentWall (): boolean {
		if (this.$state.is(States.DASHBOARD_FOLDERS)) return false;

		return this.group.isCourse() && this.group.requiresPayment() && // group requires payment
			!this.group.hasPaid(); // has not paid
	}

	private hasNoEligibleBilling (): boolean {
		return this.group.isCourse() &&
			this.group.license === null &&
			this.eligibleProducts != null &&
			this.eligibleProducts.length === 0;
	}

	private consumeSeat () {
		if(!this.isGroupIsACourse) {
			this.isConsumeSeatLoaded$$.next(true);
			return;
		}

		const group = this.group;

		if (!(group.hasPresenterRole() && !group.requiresPayment() && !group.hasPaid())) {
			this.isConsumeSeatLoaded$$.next(true);
			return;
		}

		this.isConsumeSeatLoaded$$.next(false);
		return this.groupService
			.consumeSeat(this.userService.currentUser.getId(), group.getId())
			.pipe(
				finalize(() => this.isConsumeSeatLoaded$$.next(true))
			)
			.subscribe({
				next: (response: ConsumeSeatResponse) => {
					this.dataSource?.reload();
					this.group.billing_entity_id = response.billing_entity_id;
					// set selected service group beid so we don't consume again
					this.selectedService.setGroup(this.group);
				},
				error: (error) => {
					if (error.status === 402) {
						this.hasConsumingSeatError = true;
					} else {
						this.errorCode = error.status;
					}
				}
			});
	}
}
