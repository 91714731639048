import {
	Component, Input
} from '@angular/core';
import { BadgeColors } from 'ngx/go-modules/src/components/badge/badge.constants';

@Component({
	selector: 'thumbnail',
	template: require('./thumbnail.component.html'),
	styles: [require('./thumbnail.component.scss')]
})
export class ThumbnailComponent {
	@Input() public src: string;
	@Input() public duration: number;
	@Input() public fallbackIcon: string;
	@Input() public isPractice: boolean;

	public isLoaded = false;
	public crossorigin: string = 'anonymous';
	public BadgeColors = BadgeColors;

	public shouldShowFallbackIcon (): boolean
	{
		return !this.src && !!this.fallbackIcon;
	}

	public determineCrossOriginValue (): string|null {
		return this.src?.startsWith('https://img.youtube.com') ? null : 'anonymous';
	}
}
