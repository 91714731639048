import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BREAKPOINTS } from 'ngx/go-modules/src/common/interfaces/breakpoints';
import { BehaviorSubject, Subject, pairwise, startWith, takeUntil } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import type { StateService } from '@uirouter/angularjs';
import { States } from 'go-modules/enums/states.enum';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { MatSidenav } from '@angular/material/sidenav';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { PubnubService, pubnubToken } from 'go-modules/go-pubnub/pubnub.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerSurveySnackBarComponent } from 'ngx/go-modules/src/components/snack-bars/customer-survey/customer-survey-snack-bar.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { BreadCrumbStateService } from 'ngx/go-modules/src/services/breadcrumb-state/breadcrumb-state.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import * as dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';
import { GoBannerService, goBannerServiceToken } from 'go-modules/go-banner/go-banner.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { Masquerade, masqueradeToken } from 'go-modules/masquerade/masquerade.service';
import { GoTourService, goTourServiceToken } from 'go-modules/go-tour/go-tour.service';
import { DraftTourSnackBarComponent } from 'ngx/go-modules/src/components/snack-bars/draft-tour/draft-tour-snack-bar.component';

export const DRAFT_TOUR_KEY = 'draft-tour';
export const DRAFT_TOUR_CONSTRAINTS = {
	isOnboarding: false,
	tourStartDate: dayjs('2024-08-01 09:00:00').toDate()
};

@Component({
	selector: 'dashboard',
	template: require('./dashboard.component.html'),
	styles: [require('./dashboard.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('slideInOut', [
			state('collapsed',
				style({ width: '{{lowWidth}}' }),
				{ params: { lowWidth: '77px' } }
			),
			state('expanded', style({
				width: '216px'
			})),
			transition('collapsed => expanded', animate('250ms ease-in-out')),
			transition('expanded => collapsed', animate('250ms ease-in-out'))
		]),
		trigger('slideInOutContent', [
			state('collapsed', style({
				marginLeft: '77px'
			})),
			state('expanded', style({
				marginLeft: '216px'
			})),
			transition('collapsed => expanded', animate('250ms ease-in-out')),
			transition('expanded => collapsed', animate('250ms ease-in-out'))
		])
	]
})
export class DashboardComponent implements OnInit, OnDestroy {
	@ViewChild('mainSideNav')
	public mainSideNav: MatSidenav;
	public collapsed: boolean = false;
	public collapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.collapsed);
	public isMobile: boolean = false;
	public isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public opened: boolean = true;
	public states = States;
	public environmentVarsService: EnvironmentVarsService;
	public isLTI: boolean = false;
	private componentDestroyed$$ = new Subject();
	private luck = 0;

	constructor (
		@Inject($stateToken) public $state: StateService,
		private breakpointObserver: BreakpointObserver,
		private snackbar: MatSnackBar,
		private cookieService: CookieService,
		private featureFlagService: NgxFeatureFlagService,
		private breadCrumbStateService: BreadCrumbStateService,
		private featureFlag: NgxFeatureFlagService,
		private translate: TranslateService,
		private ngZone: NgZone,
		@Inject(pubnubToken) private pubnub: PubnubService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(goBannerServiceToken) private goBannerService: GoBannerService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(masqueradeToken) private masquerade: Masquerade,
		@Inject(goTourServiceToken) private goTour: GoTourService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	public ngOnInit () {
		this.isLTI = this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
		this.breadCrumbStateService.navState$
			.pipe(
				takeUntil(this.componentDestroyed$$),
				startWith(undefined),
				pairwise()
			).subscribe((navStates) => {
				const license = this.selectedService.getLicense();

				if (navStates[0] === States.DASHBOARD_SESSION_VIEW &&
					this.featureFlagService.isAvailable('CUSTOMER_SURVEY') &&
					CustomerSurveySnackBarComponent.canShow(this.userService.currentUser.user_id) &&
					!this.cookieService.get('e2e-test-run') &&
					!this.masquerade.isMasked() &&
					!this.environmentVarsService.get(EnvironmentVarsService.VAR.WHITELABEL) &&
					(!license || !license.salesforce_license.is_free_trial)
				) {
					const randomNumber = Math.random() * 10;
					const percentage = 1; // 10%
					const probability = percentage + this.luck;

					if (randomNumber > probability) {
						// increment luck to increase probability
						this.luck += 0.5;
						return;
					}

					CustomerSurveySnackBarComponent.open(this.snackbar);
				}

				if (!this.cookieService.get('e2e-test-run') &&
					(navStates[1] === States.DASHBOARD_FOLDER_VIEW || navStates[1] === States.DASHBOARD_ACTIVITY_VIEW)
				) {
					this.checkToShowDraftTourSnackbar();
				}
			});

		this.breakpointObserver
			.observe([`(max-width: ${BREAKPOINTS.SCREEN_MD})`])
			.subscribe((result: BreakpointState) => {
				if (result.matches) {
					this.opened = false;
					this.collapsed = false;
					this.collapsed$.next(false);
					this.isMobile = true;
					this.isMobile$.next(true);
				} else {
					this.opened = true;
					this.isMobile = false;
					this.isMobile$.next(false);
				}
			});

		if (this.isLTI) {
			this.pubnub.updateChannels();
		}
	}

	public showSideNav () {
		return !(
			this.$state.is(this.states.DASHBOARD_SESSION_VIEW) ||
			this.$state.is(this.states.DASHBOARD_WIZARD) ||
			this.$state.is(this.states.DASHBOARD_QUICKSTART_GUIDE)
		);
	}

	public toggleCollapse () {
		this.collapsed = !this.collapsed;
		this.collapsed$.next(this.collapsed);
	}

	public checkToShowDraftTourSnackbar () {
		this.goTour.canTourBeViewed(
			DRAFT_TOUR_KEY,
			DRAFT_TOUR_CONSTRAINTS
		).then(() => {
			this.ngZone.run(() => {
				DraftTourSnackBarComponent.open(this.snackbar).afterDismissed().subscribe(() => {
					this.goTour.markTourViewed(DRAFT_TOUR_KEY);
				});
			});
		}).catch(() => { });
	}
}
