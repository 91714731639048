import { Component, Inject, OnInit } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Activity } from 'ngx/go-modules/src/interfaces/activity';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { map, Observable, switchMap } from 'rxjs';
import { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';

@Component({
	selector: 'copy-previous-assignment-dialog',
	template: require('./copy-previous-assignment-dialog.component.html'),
	styles: [require('./copy-previous-assignment-dialog.component.scss')]
})
export class CopyPreviousAssignmentDialogComponent implements OnInit {
	public form = new FormGroup({
		folder: new FormControl(null, [Validators.required]),
		activity: new FormControl(null, [Validators.required])
	});
	public courses$: Observable<CourseFolder[]> | null = null;
	public assignments$: Observable<Activity[]> | null = null;

	constructor (
		public dialogRef: GoDialogRef,
		private groupService: NgxGroupService,
		private activityService: NgxActivityService,
		@Inject(GO_MODAL_DATA) public data: {
			currentActivity: Activity;
		},
		@Inject(userServiceToken) private userService: UserService
	) {}

	public ngOnInit (): void {
		this.loadCourses();
		this.setupAssignmentStream();
	}

	public cancel () {
		this.dialogRef.close(null);
	}

	public confirm () {
		if (this.form.valid) {
			this.dialogRef.close(this.form.value.activity);
		}
	}

	private loadCourses (): void {
		this.courses$ = this.groupService.getByUser(this.userService.currentUser.user_id, false, true)
			.pipe(map((courses) => this.orderCourses(courses)));
	}

	private setupAssignmentStream (): void {
		this.assignments$ = this.form
			.get('folder')
			?.valueChanges.pipe(
				switchMap((groupId) =>
					this.activityService
						.getByGroup(groupId)
						.pipe(
							map((assignments) => this.filterAssignments(assignments)),
							map((assignments) => this.orderAssignments(assignments))
						)
				)
			);
	}

	private orderCourses (courses: CourseFolder[]): CourseFolder[] {
		return courses.sort((a, b) => {
			const dateComparison = new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
			return dateComparison !== 0 ? dateComparison : b.name.localeCompare(a.name);
		});
	}

	private orderAssignments (assignments: Activity[]): Activity[] {
		return assignments.sort((a, b) => {
			const dateComparison = new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
			return dateComparison !== 0 ? dateComparison : b.name.localeCompare(a.name);
		});
	}

	private filterAssignments (assignments: Activity[]): Activity[] {
		return assignments.filter((assignment: Activity) => {
			return (
				assignment.template &&
				assignment.name !== '__ACCT_DEFAULT__' &&
				(!this.data.currentActivity ||
				this.data.currentActivity.activity_id !== assignment.activity_id)
			);
		});
	}
}
