import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { States } from 'go-modules/enums/states.enum';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { BreadCrumbStateService } from 'ngx/go-modules/src/services/breadcrumb-state/breadcrumb-state.service';
import { StateService } from '@uirouter/angularjs';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS, LTI_ENVIRONMENT_MODES } from 'ngx/go-modules/src/services/environment-vars/environments';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

@Component({
	selector: 'folder-breadcrumbs',
	template: require('./folder-breadcrumbs.component.html'),
	styles: [require('./folder-breadcrumbs.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderBreadCrumbsComponent {
	public orderedStates = [
		States.DASHBOARD_FOLDERS,
		States.DASHBOARD_FOLDER_VIEW,
		States.DASHBOARD_ACTIVITY_VIEW,
		States.DASHBOARD_SESSION_VIEW
	];
	public environmentVarsService: EnvironmentVarsService;
	public isLti = false;
	public isCourseLevelLti = false;

	constructor (
		@Inject($stateToken) public $state: StateService,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(userServiceToken) public userService: UserService,
		public breadcrumbStateService: BreadCrumbStateService,
		private eventService: EventService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();

		const environment: any = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) || {};
		this.isLti = this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
		this.isCourseLevelLti = environment.mode === LTI_ENVIRONMENT_MODES.COURSE_LEVEL;
	}

	public navigateToActivityView () {
		if (this.$state.is(States.DASHBOARD_SESSION_VIEW)) {
			return this.eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_REQUEST_EXIT, {
				exitData: null
			});
		}

		this.$state.go(this.orderedStates[2], {activity_id: this.selectedService.getActivity().activity_id});
	}

	public navigateToFolderView () {
		if (this.$state.is(States.DASHBOARD_SESSION_VIEW)) {
			this.eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_REQUEST_EXIT, {
				exitData: null,
				callback: () => {
					this.$state.go(this.orderedStates[1], {folder_id: this.selectedService.getGroup().group_id});
				}
			});
			return;
		}

		this.$state.go(this.orderedStates[1], {folder_id: this.selectedService.getGroup().group_id});
	}

	public navigateToFoldersView () {
		if (this.$state.is(States.DASHBOARD_SESSION_VIEW)) {
			this.eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_REQUEST_EXIT, {
				exitData: null,
				callback: () => {
					this.$state.go(this.orderedStates[0], {id: this.selectedService.getOrg().group_id});
				}
			});
			return;
		}

		this.$state.go(this.orderedStates[0], {id: this.selectedService.getOrg().group_id});
	}

	public openMenu (event: MouseEvent, menu: MenuComponent) {
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}
		event.stopPropagation();
		event.preventDefault();
		menu.openMenu(event, event.target as HTMLElement);
	}

	public isRootUser (): boolean {
		return this.userService.currentUser?.is_root_user;
	}

	public canOpenOrgMenu (navState: States, contextMenu: boolean = false): boolean {
		return (contextMenu || this.orderedStates.indexOf(navState) === 0) &&
			(this.isRootUser() || this.selectedService.getOrg().hasAdminRole(true));
	}

	public canOpenFolderMenu (navState: States, contextMenu: boolean = false): boolean {
		return (contextMenu || this.orderedStates.indexOf(navState) === 1)
			&& this.canOpenMenu()
			&& !this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
	}

	public canOpenActivityMenu (navState: States, contextMenu: boolean = false): boolean {
		return (contextMenu || this.orderedStates.indexOf(navState) === 2) &&
			this.canOpenMenu();
	}

	public canOpenMenu (): boolean {
		return this.selectedService.getGroup()?.hasInstructorRole(true) || this.isRootUser();
	}

	public shouldShowDraftBadge (): boolean {
		return this.selectedService.getSession()?.isPractice() && this.selectedService.getActivity()?.allowsPractice();
	}
}
