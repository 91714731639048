import { noop } from 'angular';
import { ActivityEditorController } from '../activity-editor.controller';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { ActivityInstructionModel } from '../../models/activity';
import { MediaThumbnailOptions } from 'go-modules/media-thumbnail/media-thumbnail.controller';
import type { ModalOptions as ActivityInstructionsModalOptions } from '../../modals/activity-instructions-editor';
import {
	ActivityRecordingInstructionsEditorModal,
	ActivityFeedbackInstructionsEditorModal
} from '../../modals/activity-instructions-editor';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { InstructionsPreviewDialogComponent } from 'ngx/go-modules/src/components/dialogs/instructions-preview/instructions-preview-dialog.component';

export interface Bindings {
	activityEditorController: ActivityEditorController;
	instructions: any;
	options: ActivityInstructionsModalOptions;
	onEdited: ({instructions: ActivityInstructionModel}) => void;
	onRemoved: ({instructions: ActivityInstructionModel}) => void;
}

export class InstructionsController implements Bindings {

	// Bindings
	public activityEditorController: ActivityEditorController;
	public instructions: any;
	public options: ActivityInstructionsModalOptions;
	public onEdited: ({instructions: any}) => void;
	public onRemoved: ({instructions: any}) => void;

	public titleTranslationKey: string;
	public thumbnailOptions: MediaThumbnailOptions;
	public eventSubscription: any;

	/* @ngInject */
	constructor (
		private activityRecordingInstructionsEditorModal: ActivityRecordingInstructionsEditorModal,
		private activityFeedbackInstructionsEditorModal: ActivityFeedbackInstructionsEditorModal,
		private mediaPreviewModal: any,
		private $sanitize,
		private eventService: EventService,
		private $translate: ng.translate.ITranslateService,
		private ngxDowngradeModalService: DowngradeModalService,
		private ngxGoModalService: GoModalService
	) {}

	public $onInit (): void {
		if (!this.instructions) {
			throw new Error('Property binding `instructions` is required!');
		}

		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.MEDIA_SYNC))
			.subscribe((ev: GoEvent) => {
				if (this.instructions.media &&
					parseInt(this.instructions.media.media_id, 10) === parseInt(ev.data.media_id, 10)) {
					Object.assign(this.instructions.media, ev.data);
				}
			});

		if (this.instructions.type === ActivityInstructionModel.TYPE.RECORDING) {
			this.titleTranslationKey = 'activity-info_recording-instructions';
		} else {
			this.titleTranslationKey = 'activity-info_feedback-instructions';
		}

		this.thumbnailOptions = {
			actions: false,
			hideTitle: true,
			hideDuration: true,
			groupId: this.activityEditorController.activity.group_id
		};
	}

	public $onDestroy (): void {
		this.eventSubscription?.unsubscribe();
	}

	public async handleEdit (): Promise<void> {
		try {
			this.instructions = await this.openInstructionsEditorModal().result;
			this.onEdited({instructions: this.instructions});
		} catch (error) {
			// Do nothing when the modal is dismissed (unless it is an actual error)
			if (error instanceof Error) {
				throw error;
			}
		}
	}

	public handleRemove (): Promise<void> {
		return this.ngxDowngradeModalService.openConfirmDialog({
			title: this.$translate.instant('modal-remove-instructions-from-activity_remove', {type: this.instructions.type}),
			message: this.$translate.instant('modal-remove-instructions-from-activity_sure-remove', {type: this.instructions.type}),
			confirmText: this.$translate.instant('common_remove')
		}).then(() => {
			this.onRemoved({instructions: this.instructions});
		}).catch(noop);
	}

	public handlePreview (): void {
		this.ngxGoModalService.open(InstructionsPreviewDialogComponent, false, {
			data: {
				instruction: {
					text: this.instructions.text,
					media: this.instructions.media,
					type: this.instructions.type
				}
			}
		});
	}

	public openMediaPreviewModal (): IModalInstanceService {
		const modalData = {
			media: this.instructions.media,
			allowDownload: true,
			groupId: this.activityEditorController.activity.group_id
		};

		return this.mediaPreviewModal.open({modalData});
	}

	public returnAsTrusted (): string {
		if (this.instructions.text) {
			return this.$sanitize(this.instructions.text);
		}
	}

	protected openInstructionsEditorModal (): IModalInstanceService {
		const modal = this.instructions.type === ActivityInstructionModel.TYPE.RECORDING ?
			this.activityRecordingInstructionsEditorModal :
			this.activityFeedbackInstructionsEditorModal;

		return modal.open({
			instructions: this.instructions,
			uploadLimit: this.activityEditorController.uploadLimit,
			groupId: this.activityEditorController.activity.group_id,
			groupName: this.activityEditorController.group.name,
			activityName: this.activityEditorController.activity.name
		});
	}
}
