import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DefaultActivitySettingsComponent } from './default-activity-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkerSetPanelDirective } from 'ngx/go-modules/src/angularjs-wrappers/marker-set-panel.directive';
import { AiMarkerSetPanelDirective } from 'ngx/go-modules/src/angularjs-wrappers/ai-marker-set-panel.directive';
import { DefaultAIMarkersDialogModule } from 'ngx/go-modules/src/components/dialogs/default-ai-markers-dialog/default-ai-markers-dialog.module';

@NgModule({
	imports: [
		CommonModule,
		GoMaterialModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule,
		DefaultAIMarkersDialogModule
	],
	declarations: [
		DefaultActivitySettingsComponent,
		MarkerSetPanelDirective,
		AiMarkerSetPanelDirective
	],
	exports: [
		DefaultActivitySettingsComponent
	]
})
export class DefaultActivitySettingsModule {}
