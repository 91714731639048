import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import type { License } from 'ngx/go-modules/src/interfaces/licenses';
import dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';
import { BadgeColors } from 'ngx/go-modules/src/components/badge/badge.constants';

export const EXPIRING_LICENSE_DAYS_THRESHOLD = 60;

@Component({
	selector: 'ai-trial-badge',
	template: require('./ai-trial-badge.component.html'),
	styles: [require('./ai-trial-badge.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiTrialBadgeComponent implements OnInit, OnDestroy {
	public BadgeColors = BadgeColors;
	public license$ = new BehaviorSubject<License>(null);
	public showAiTrialBadge$ = new BehaviorSubject(false);
	private destroyed$ = new Subject<void>();

	constructor (
		private translate: TranslateService,
		@Inject(selectedServiceToken) private selectedService: SelectedService
	) {}

	public ngOnInit () {
		this.selectedService.selectedSubject
			.pipe(takeUntil(this.destroyed$))
			.subscribe((selected) => {
				// Students should not see the license plan breadcrumb
				if (!selected.group || !selected.group.hasInstructorRole(true)) {
					return this.setLicense(null);
				}

				if (selected.license) {
					this.setLicense(selected.license as License);
				} else if (selected.group.hasOwnProperty('license')) {
					this.setLicense(selected.group.license);
				}
			});
	}

	public ngOnDestroy () {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	public getMessageTranslation () {
		const licenseEnd = dayjs(this.license$.value.ends_at);
		if (licenseEnd.isBefore(dayjs().add(EXPIRING_LICENSE_DAYS_THRESHOLD, 'days'))) {
			const daysRemaining = Math.max(licenseEnd.diff(dayjs(), 'days'), 0);
			return this.translate.instant('ai-trial-days-remaining', { days: daysRemaining });
		}
		return this.translate.instant('folder-free-trial-badge-label');
	}

	private setLicense (license: License) {
		if (license && Object.keys(license).length) {
			this.license$.next(license);
			this.showAiTrialBadge$.next(!!license.trial);
		} else {
			this.license$.next(null);
			this.showAiTrialBadge$.next(false);
		}
	}
}
