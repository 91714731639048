import { EventEmitter, Input, Output, Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'ai-marker-set-panel'
})
export class AiMarkerSetPanelDirective extends UpgradeComponent {
	@Input() public aiPrompts;
	@Input() public canPreview;
	@Input() public title;
	@Output() public onEdit: EventEmitter<any>;
	@Output() public onRemove: EventEmitter<any>;

	constructor (
		elementRef: ElementRef,
		injector: Injector
	) {
		super('aiMarkerSetPanel', elementRef, injector);
		const $scope = injector.get('$scope');
		/**
		 * We have to fire the evalAsync otherwise it won't fire the ng1 digest cycle
		 * https://stackoverflow.com/q/58986644/1248889
		 */
		$scope.$evalAsync();
	}
}
