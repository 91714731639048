import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxToolbarButtonComponent } from './toolbar-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	imports: [
		CommonModule,
		MatTooltipModule
	],
	declarations: [
		NgxToolbarButtonComponent
	],
	entryComponents: [
		NgxToolbarButtonComponent
	],
	exports: [
		NgxToolbarButtonComponent
	]
})
export class NgxToolbarButtonModule {}
