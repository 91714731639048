import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { AiTrialCourseSelectorDialogComponent } from './ai-trial-course-selector-dialog.component';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		FocusOnFirstInvalidFieldModule
	],
	declarations: [
		AiTrialCourseSelectorDialogComponent
	],
	exports: [
		AiTrialCourseSelectorDialogComponent
	],
	entryComponents: [
		AiTrialCourseSelectorDialogComponent
	]
})
export class AiTrialCourseSelectorDialogModule {}
