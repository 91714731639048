import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivityReportPanelService, activityReportPanelToken } from 'go-modules/activity-report-panel/activity-report-panel.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';
import { BehaviorSubject, filter, switchMap } from 'rxjs';
import { ActivityRubricReportService, activityRubricReportServiceToken } from 'go-modules/csv-report/activity-rubric-report/activity-rubric-report.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS, LTI_ENVIRONMENT_MODES } from 'ngx/go-modules/src/services/environment-vars/environments';
import { noop } from 'angular';
import { FullstoryService, fullstoryToken } from 'go-modules/services/fullstory/fullstory.service';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import { LibraryService } from 'ngx/go-modules/src/services/library/library.service';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { Activity } from 'ngx/go-modules/src/interfaces/activity';
import { ConfirmDialogComponent, ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as angular from 'angular';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { CONTENT_TYPES, LIBRARY_TYPES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { DESTINATION_MODES } from 'ngx/go-modules/src/components/library/collection-destination/collection-destination.constants';
import { CollectionDestinationComponent, CollectionDestinationData } from 'ngx/go-modules/src/components/library/collection-destination/collection-destination.component';
import { MasterActivityContactDetails } from 'ngx/go-modules/src/interfaces/master-activity-contact-details';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';

@Component({
	selector: 'activity-menu',
	template: require('./activity-menu.component.html'),
	styles: [require('./activity-menu.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityMenuComponent implements OnInit {
	@Output() public onArchived = new EventEmitter();
	@Input() public activity: any;

	public isCreating$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public limitActivityOption: boolean;
	public environmentVarsService: EnvironmentVarsService;
	private org;

	constructor (
		public libraryService: LibraryService,
		public translateService: TranslateService,
		public ngxGoToastService: NgxGoToastService,
		private cd: ChangeDetectorRef,
		private activityListDataSource: ActivityListDataSource,
		private authService: NgxAuthService,
		private ngxActivityService: NgxActivityService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private modal: GoModalService,
		@Inject(uaDetectToken) public UADetect: UADetectClass,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(userServiceToken) public userService: UserService,
		@Inject(activityReportPanelToken) public activityReportPanel: ActivityReportPanelService,
		@Inject(activityRubricReportServiceToken) private activityRubricReportService: ActivityRubricReportService,
		@Inject(fullstoryToken) private fullstoryService: FullstoryService
	) {}

	public ngOnInit () {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
		// Depending on the environment and mode, determine which features are available
		const environment: any = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) || {};
		// This flag is used to limit the activity editor functionality
		this.limitActivityOption = environment.name === ENVIRONMENTS.LTI &&
			environment.mode === LTI_ENVIRONMENT_MODES.DEFAULT;

		this.org = this.selectedService.getOrg();
	}

	public editActivity () {
		if (this.isParentChildSyncEnabled()) {
			this.ngxActivityService.getMasterActivityDetails(this.activity.activity_id)
				.subscribe((details: MasterActivityContactDetails) => {
					if (this.hasAdminRoleOrHigher()) {
						this.dialog.open(MessageDialogComponent, {
							data: {
								title: this.translate.instant('modal-edit-activity-disabled_org-admin_title'),
								message: this.translate.instant('modal-edit-activity-disabled_org-admin_message', {
									collectionName: details.collection_name
								})
							}
						});
					} else {
						this.dialog.open(MessageDialogComponent, {
							data: {
								title: this.translate.instant('modal-edit-activity-disabled_title'),
								message: this.translate.instant('modal-edit-activity-disabled_message', {
									name: details.name,
									email: details.email
								})
							}
						});
					}
				});
		} else if (!this.ngxActivityService.canEditActivity(this.selectedService.getActivity())) {
			this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('modal-modifying-assignment-disabled_title'),
					message: this.translate.instant('modal-modifying-assignment-disabled_message')
				}
			});
		} else {
			return this.openActivityEditor(this.activity);
		}
	}

	public canDuplicateActivity () {
		if (this.isParentChildSyncEnabled()) {
			return false;
		}
		return !this.limitActivityOption;
	}

	public duplicateActivity () {
		return this.openActivityEditor(this.activity.duplicate());
	}

	public canAddToLibrary () {
		if (this.isParentChildSyncEnabled()) {
			return false;
		}
		return !this.org.org_settings.block_copied_assignment_editing;
	}

	public addToLibrary () {
		this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_ADD_ASSIGNMENT_FROM_DASHBOARD, {});
		const activity = this.activity;

		this.libraryService.getCollections()
			.subscribe((collections: any) => {
				const destinationData: CollectionDestinationData = {
					collections,
					selectedCollection: collections.find((coll) => coll.type === LIBRARY_TYPES.MINE),
					contentType: CONTENT_TYPES.ACTIVITIES,
					mode: DESTINATION_MODES.ADDTOLIBRARY,
					allowSkip: false,
					showNameField: true,
					name: activity.name
				};
				this.modal.open(CollectionDestinationComponent, false, {
					data: destinationData
				}).afterClosed().subscribe((res) => {
					if (res.dismissed) {
						return;
					}
					const {collection, folder, name} = res;
					const duplicate = this.activity.duplicate();
					duplicate.group_id = null;
					duplicate.add_to_collection = collection.id;
					duplicate.add_to_folder = folder ? folder.id : null;
					duplicate.sort_index = 999999999;
					if (name !== this.activity.name) {
						duplicate.name = name;
					}

					duplicate.save().then(() => {
						this.dialog.open(MessageDialogComponent, {
							data: {
								title: this.translate.instant('modal-adding-assignment-to-library_title'),
								message: this.translate.instant('modal-adding-assignment-to-library_body', {
									assignment: duplicate.name,
									collection: collection.name
								})
							}
						});
					}).catch((error) => {
						let errorMessage = {
							title: this.translate.instant('modal-adding-assignment-to-library-error_title'),
							message: this.translate.instant('modal-adding-assignment-to-library-error_body')
						};

						if (error.data.errors.hasOwnProperty('add_to_collection')) {
							errorMessage = {
								title: this.translate.instant('modal-adding-assignment-to-library-error_title_collection'),
								message: this.translate.instant('modal-adding-assignment-to-library-error_body_collection')
							};
						} else if(error.data.errors.hasOwnProperty('add_to_folder')) {
							errorMessage = {
								title: this.translate.instant('modal-adding-assignment-to-library-error_title_folder'),
								message: this.translate.instant('modal-adding-assignment-to-library-error_body_folder')
							};
						}

						this.dialog.open(MessageDialogComponent, {
							data: errorMessage
						});
					});
				});
			});
	}

	public openActivityReport () {
		this.activityReportPanel.open({
			activityId: this.activity.activity_id,
			groupId: this.selectedService.getGroup().group_id
		});
	}

	public shouldShowInstructorRubricReportButton () {
		return !this.UADetect.isMobile() && !!this.activity?.rubric_template_id;
	}

	public shouldShowStudentRubricReportButton () {
		return !this.UADetect.isMobile() && !!this.activity?.peer_rubric_id;
	}

	public shouldShowFeedbackHold () {
		return this.activity && this.activity.isViewable('is_feedback_published') &&
			this.selectedService.getActivity() !== null;
	}

	public downloadActivityRubricReport (reportType: string) {
		this.activityRubricReportService.execute(this.activity, reportType)
			.then(noop)
			.catch(() => {
				this.dialog.open(MessageDialogComponent, {
					data: {
						title: this.translate.instant('rubric-report_download-failed-title'),
						message: this.translate.instant('rubric-report_download-failed-message')
					}
				});
			});
	}

	public archiveActivity () {
		const group = this.selectedService.getGroup();

		if (this.org.org_settings.block_copied_assignment_editing
			&& !!this.activity.parent_id
			&& !this.userService.currentUser.is_root_user) {
			return this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('modal-modifying-assignment-disabled_title'),
					message: this.translate.instant('modal-modifying-assignment-disabled_message')
				}
			});
		}

		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translate.instant('archive-activity'),
				message: this.translate.instant('archive-message', {name: this.activity.name}),
				confirmText: this.translate.instant('archive-activity')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().pipe(
			filter((confirm: boolean) => confirm),
			switchMap(() => this.ngxActivityService.archive(this.activity.activity_id))
		).subscribe({
			next: () => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'activity-archive_success',
					undo: () => this.restoreActivity(this.activity)
				});
				group.removeActivity(this.activity);
				this.activityListDataSource.removeActivity(this.activity);
				this.onArchived.emit();
			},
			error: () => this.ngxGoToastService.createToast({
				type: GoToastStatusType.ERROR,
				message: 'course-menu_archive-error'
			})
		});
	}

	private restoreActivity (activity: Activity) {
		this.ngxActivityService.restore(activity.activity_id)
			.subscribe({
				next: () => {
					// cant re-add it to data source since it is already in removed$
					// so just reload it instead
					this.activityListDataSource.reload();
				}
			});
	}

	private openActivityEditor (activity) {
		const ownerName = activity.owner_name;
		let isEditMode = false;
		if (!!activity.activity_id) {
			isEditMode = true;
		}

		this.ngxActivityService.openActivityEditor(activity, this.limitActivityOption)
			.then(() => {
				activity.owner_name = ownerName;
				if (isEditMode) {
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.SUCCESS,
						message: 'folder-view_activity_updated'
					});
					// copy activity to change object ref and force table to reflect changes
					this.activityListDataSource.editActivity(angular.copy(activity));
				} else {
					this.activityListDataSource.addActivity(activity);
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.SUCCESS,
						message: 'folder-view_activity_duplicated'
					});
				}
				this.cd.markForCheck();

			}).catch(noop);
	}

	private isParentChildSyncEnabled () {
		return this.activity && this.activity.is_parent_sync_enabled && this.activity.parent_child_sync;
	}

	private hasAdminRoleOrHigher () {
		// lti use cookie since is_admin not set on orgs
		if (this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI)) {
			return this.authService.isAdmin();
		}
		return this.userService.currentUser.is_root_user || this.selectedService.getOrg().hasAdminRole();
	}
}
