import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BaseDataSource } from 'ngx/go-modules/src/classes/base-data-source';
import {
	BehaviorSubject,
	EMPTY,
	Observable,
	Subject,
	combineLatest,
	merge
} from 'rxjs';
import {
	catchError,
	debounceTime,
	map,
	shareReplay,
	switchMap,
	tap,
	scan,
	skip
} from 'rxjs/operators';
import { ArchiveFilter } from 'ngx/go-modules/src/enums/archive-filter';
import { SortOrder } from 'ngx/go-modules/src/enums/sort-order-main';
import { ArchiveService } from './archive.service';
import { ArchivedItem } from 'ngx/go-modules/src/interfaces/archived/archived.interface';
import { accumulateArr } from 'ngx/go-modules/src/rxjs/accumulate/accumulate';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { PaginationMeta } from 'ngx/go-modules/src/interfaces';
import { PaginatedArchiveList } from 'ngx/go-modules/src/interfaces/archived/paginated-archive-list';

export const SCROLL_DEBOUNCE_TIME = 50;
export const DEFAULT_SORT_ORDER = SortOrder.NEWEST;
export const DEFAULT_FILTER = ArchiveFilter.VIDEO;
export const ARCHIVE_FILTER_KEY = 'archive-filter';
export const ARCHIVE_SORT_KEY = 'archive-sort';

interface FilterSort {
	filter: ArchiveFilter;
	sortBy: SortOrder;
}

enum TriggerAction {
	FILTER = 'filter',
	LOAD_MORE = 'loadMore',
	RELOAD = 'reload'
}

@Injectable({
	providedIn: 'root'
})
export class ArchivedListDataSource extends BaseDataSource<ArchivedItem, ArchiveFilter> implements OnDestroy {
	private filterBy$$ = new BehaviorSubject<ArchiveFilter>(ArchiveFilter.VIDEO);
	private sortBy$$ = new BehaviorSubject<SortOrder>(SortOrder.NEWEST);
	private add$$ = new Subject<ArchivedItem>();
	private remove$$ = new Subject<ArchivedItem>();
	private serviceDestroyed$ = new Subject();

	private currentPageMeta$ = new BehaviorSubject<PaginationMeta | null>(null);
	private filterSort$: Observable<FilterSort>;
	private scrollBuffer = 200;
	private loadMore$ = new Subject<void>();
	private onScrollLimit$$ = new Subject<void>();

	public onScrollLimit$ = this.onScrollLimit$$.pipe(
		debounceTime(SCROLL_DEBOUNCE_TIME),
		tap(() => this.loadMoreRecords())
	);

	public params$: Observable<[FilterSort, boolean]>;
	public isLoadingMoreItems$ = new BehaviorSubject<boolean>(false);

	constructor (
		@Inject(userServiceToken) private userService: UserService,
		private archiveService: ArchiveService
	) {
		super();
		this.setupFilters();
		this.results$ = this.getArchiveItems();
	}

	public onScroll (event: any) {
		const viewportHeight = event.target.offsetHeight;
		const viewportScrollHeight = event.target.scrollHeight;
		const scrollLocation = event.target.scrollTop;

		// If the user has scrolled within (scrollBuffer)px of the bottom, add more data
		const limit = viewportScrollHeight - viewportHeight - this.scrollBuffer;

		if (scrollLocation > limit) {
			this.onScrollLimit$$.next();
		}
	}

	public get currentSort () {
		return this.sortBy$$.getValue();
	}

	public get currentFilter () {
		return this.filterBy$$.getValue();
	}

	public setupFilters (): void {
		const selectedFilter = localStorage.getItem(`${this.userService.currentUser.user_id}-` + ARCHIVE_FILTER_KEY);
		if (selectedFilter && Object.values(ArchiveFilter).includes(selectedFilter as ArchiveFilter)) {
			this.filterBy(selectedFilter as ArchiveFilter);
		} else {
			if (this.userService.currentUser.isInstructor) {
				this.filterBy(ArchiveFilter.FOLDER);
			}
		}

		const selectedSort = localStorage.getItem(`${this.userService.currentUser.user_id}-${ARCHIVE_SORT_KEY}`);
		if (selectedSort && Object.values(SortOrder).includes(selectedSort as SortOrder)) {
			this.sortBy(selectedSort as SortOrder);
		}

		this.filterSort$ = combineLatest([
			this.filterBy$$,
			this.sortBy$$
		]).pipe(
			map(([filter, sortBy]) => ({ filter, sortBy })),
			tap(() => {
				this.currentPageMeta$.next(null);
				this.setLoading(true);
			})
		);
	}

	public ngOnDestroy (): void {
		this.serviceDestroyed$.next(true);
		this.serviceDestroyed$.complete();
	}

	public connect (): Observable<readonly ArchivedItem[]> {
		return this.results$;
	}

	public disconnect (): void {}

	public add (item) {
		this.add$$.next(item);
	}

	public remove (item: ArchivedItem) {
		this.remove$$.next(item);
	}

	public sortBy (sortBy: SortOrder) {
		localStorage.setItem(`${this.userService.currentUser.user_id}-${ARCHIVE_SORT_KEY}`, sortBy);
		this.sortBy$$.next(sortBy);
	}

	public filterBy (filter: ArchiveFilter) {
		localStorage.setItem(`${this.userService.currentUser.user_id}-` + ARCHIVE_FILTER_KEY, filter);
		this.filterBy$$.next(filter);
	}

	private loadMoreRecords () {
		const meta = this.currentPageMeta$.getValue();
		if (
			meta
			&& meta.current_page < meta.last_page
			&& !this.loading$$.getValue()
			// to prevent cancelling network requests if scrolling while still loading more items
			&& !this.isLoadingMoreItems$.getValue()
		) {
			this.isLoadingMoreItems$.next(true);
			this.loadMore$.next();
		}
	}

	private errorHandler (err: any) {
		this.onError$$.next(err);
		this.setLoading(false);
		this.isLoadingMoreItems$.next(false);
		return EMPTY;
	}

	private getArchiveItems (): Observable<ArchivedItem[]> {
		const triggers$: Observable<TriggerAction> = merge(
			this.filterSort$.pipe(map(() => TriggerAction.FILTER)),
			this.loadMore$.pipe(map(() => TriggerAction.LOAD_MORE)),
			this.reload$$.pipe(skip(1), map(() => TriggerAction.RELOAD))
		);

		return this.params$ = triggers$.pipe(
			switchMap((trigger: TriggerAction) => {
				const currentMeta = this.currentPageMeta$.getValue();
				const isLoadMore = trigger === TriggerAction.LOAD_MORE;
				const page = isLoadMore ? currentMeta.current_page + 1 : 1;

				return this.archiveService.getArchivedItems(this.filterBy$$.getValue(), this.sortBy$$.getValue(), page)
					.pipe(catchError(this.errorHandler.bind(this)));
			}),
			tap((response: PaginatedArchiveList) => this.currentPageMeta$.next(response.meta)),
			map((response: PaginatedArchiveList) => response.data),
			scan((acc: ArchivedItem[], items: ArchivedItem[]) => {
				if (this.currentPageMeta$.getValue()?.current_page === 1) return items;
				const newItems = items.filter(
					(item) => !acc.some((existingItem) => existingItem.resource_id === item.resource_id)
				);
				return [...acc, ...newItems];
			}, []),
			switchMap((archiveList: ArchivedItem[]) => {
				const added$ = this.add$$.pipe(accumulateArr());
				const removed$ = this.remove$$.pipe(accumulateArr());
				return combineLatest({added: added$, removed: removed$}).pipe(
					map(({added, removed}) =>
						archiveList.concat(added)
							.filter((item) =>
								!removed.some((removedItem) =>
									removedItem.resource_id === item.resource_id))
					)
				);
			}),
			tap(() => {
				this.setLoading(false);
				this.isLoadingMoreItems$.next(false);
			}),
			shareReplay({ bufferSize: 1, refCount: true }),
			catchError(this.errorHandler.bind(this))
		);
	}
}
