import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '@ngx-translate/core';
import {
	ActivityReportPanelService,
	activityReportPanelToken
} from 'go-modules/activity-report-panel/activity-report-panel.service';
import { GroupCreatorService, groupCreatorServiceToken } from 'go-modules/group-creator/group-creator.service';
import {
	GroupSettingsPanelService,
	groupSettingsPanelServiceToken
} from 'go-modules/group-settings-panel/group-settings-panel.service';
import { goModal, goModalToken } from 'go-modules/modals/go-modal.factory';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import {
	ConfirmDialogComponent,
	ConfirmDialogData
} from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxCourseService } from 'ngx/go-modules/src/services/course/course.service';
import { FolderListDataSource } from 'ngx/go-modules/src/services/folder-list-datasource/folder-list-datasource';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { filter, switchMap } from 'rxjs';
import type { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';
import { AccountService } from 'ngx/go-modules/src/services/account/account.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import {
	MoveFolderDialogComponent
} from 'ngx/go-modules/src/components/dialogs/move-folder-dialog/move-folder-dialog.component';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import dayjs from 'dayjs';
import { groupToken } from 'go-modules/models/group-dep/group.factory';


@Component({
	selector: 'folder-menu',
	template: require('./folder-menu.component.html'),
	styles: [require('./folder-menu.component.scss')],
	providers: [TranslatePipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderMenuComponent {
	@Output() public onArchived = new EventEmitter();
	@Input() public folder: any;

	constructor (
		public ngxCourseService: NgxCourseService,
		private ngxGoToastService: NgxGoToastService,
		private dialog: MatDialog,
		private translatePipe: TranslatePipe,
		private folderListDataSource: FolderListDataSource,
		private accountService: AccountService,
		private modal: GoModalService,

		@Inject(groupToken) private group,
		@Inject(userServiceToken) public userService: UserService,
		@Inject(goModalToken) private goModalService: ReturnType<typeof goModal>,
		@Inject(groupSettingsPanelServiceToken) private groupSettingsPanel: GroupSettingsPanelService,
		@Inject(activityReportPanelToken) private activityReportPanel: ActivityReportPanelService,

		@Inject(groupCreatorServiceToken) private GroupCreator: GroupCreatorService,
		@Inject(selectedServiceToken) public selectedService: SelectedService
	) {}

	public inviteUsers () {
		this.goModalService.open({
			modal: 'inviteUsers',
			modalData: {
				group: this.folder
			}
		});
	}

	public manageUsers () {
		this.openSettings('users');
	}

	public editFolder () {
		this.openSettings('info');
	}

	public openSettings (defaultTab: 'info' | 'users') {
		this.groupSettingsPanel.open(this.folder, this.userService.currentUser, defaultTab, {}).result.then(
			(group?) => {
				// group is not-null if changed
				if (group != null) {
					this.folderListDataSource.editFolder(group);
				}
			}
		);
	}

	public viewFolderReport () {
		this.activityReportPanel.open({
			activityId: null,
			reportLevel: 'course',
			groupId: this.folder.group_id
		});
	}

	public duplicateFolder () {
		const folderOrg = this.selectedService.getOrg();
		if (folderOrg?.group_id !== this.folder.org_id) {
			throw new Error('Attempt to duplicate folder on unselected org');
		}
		const selectedInstructorId = this.folderListDataSource.getInstructorId();
		const userId = selectedInstructorId ?? this.userService.currentUser.user_id;
		const account$ = this.accountService.getOrCreateUserAccount(userId, folderOrg.group_id);

		// Append "(copy)"" to folder name
		const folderToEdit = {} as CourseFolder;
		Object.assign(folderToEdit, this.folder);
		folderToEdit.name = this.translatePipe.transform('duplicate-folder-name', {courseName: this.folder.name});
		folderToEdit.originalName = this.folder.name;
		folderToEdit.start_date = dayjs().toDate();
		if (this.folder.end_date) {
			const diffInMonths = dayjs(this.folder.end_date).diff(this.folder.start_date, 'month');
			folderToEdit.end_date = dayjs(folderToEdit.start_date).add(diffInMonths, 'month').toDate();
		}

		account$.subscribe((account) => {
			folderOrg.my_account = this.group.model(account);
			this.GroupCreator.createCourseFolder(
				this.group.model(account),
				this.userService.currentUser,
				userId,
				folderToEdit
			).then((folder: CourseFolder) => this.folderListDataSource.addFolder(folder));
		});
	}

	public archiveFolder () {
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translatePipe.transform('archive-folder'),
				message: this.translatePipe.transform('archive-message', {name: this.folder.name}),
				confirmText: this.translatePipe.transform('archive-folder')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().pipe(
			filter((confirm: boolean) => confirm),
			switchMap(() => this.ngxCourseService.archiveCourse(this.folder.group_id))
		).subscribe({
			next: () => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'course-menu_archive-folder-success',
					undo: () => this.restoreFolder(this.folder)
				});
				this.folderListDataSource.removeFolder(this.folder);
				this.onArchived.emit();
			},
			error: () => this.ngxGoToastService.createToast({
				type: GoToastStatusType.ERROR,
				message: 'course-menu_archive-error'
			})
		});
	}

	public moveFolder () {
		this.modal.open(MoveFolderDialogComponent, true, {
			data: {
				folderId: this.folder.group_id
			}
		}).afterClosed().pipe(
			filter((movedSuccessfully: boolean) => movedSuccessfully)
		).subscribe(() => {
			this.folderListDataSource.removeFolder(this.folder);
		});
	}

	private restoreFolder (folder: CourseFolder) {
		this.ngxCourseService.restoreCourse(folder.group_id)
			.subscribe({
				next: () => {
					// cant re-add it to data source since it is already in removed$
					// so just reload it instead
					this.folderListDataSource.reload();
				}
			});
	}
}
