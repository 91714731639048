import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import type { CountryData } from 'ngx/go-modules/src/interfaces/country-data';
import { NgxCountryService } from 'ngx/go-modules/src/services/country/country.service';
import { distinctUntilChanged, Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'country-picker',
	template: require('./country-picker.component.html'),
	styles: [require('./country-picker.component.scss')]
})
export class CountryPickerComponent implements OnDestroy {
	@Output() public countryChange: EventEmitter<string> = new EventEmitter<string>();

	public defaultCountryCode = 'US';
	public selectedCountry = new FormControl();
	public countries$: Observable<CountryData[]> = this.countryService.getCountries().pipe(
		tap((countries) => {
			const defaultCountry = countries.find((country) =>
				country.code === this.defaultCountryCode
			);
			if (defaultCountry) {
				this.selectedCountry.setValue(defaultCountry);
			}
		})
	);
	private countryChanges$ = this.selectedCountry.valueChanges.pipe(
		distinctUntilChanged(),
		tap((country: CountryData | null) => {
			if (country) {
				this.countryChange.emit(country.code);
			}
		})
	);
	private destroy$ = new Subject<void>();

	constructor (
		private countryService: NgxCountryService
	) {
		this.countryChanges$.pipe(
			takeUntil(this.destroy$)
		).subscribe();
	}

	public ngOnDestroy (): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
