import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { Masquerade, masqueradeToken } from 'go-modules/masquerade/masquerade.service';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { BehaviorSubject } from 'rxjs';
import { UserService as NgxUserService } from 'ngx/go-modules/src/services/user/user.service';
import { goModal, goModalToken } from 'go-modules/modals/go-modal.factory';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'ngx/go-modules/src/interfaces/user';

@Component({
	selector: 'user-search-dialog',
	template: require('./user-search-dialog.component.html'),
	styles: [require('./user-search-dialog.component.scss')]
})
export class UserSearchDialogComponent implements OnInit {
	public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public users$: BehaviorSubject<User[]> = new BehaviorSubject([]);
	public searchForm: FormGroup;
	public searchQuery: FormControl = new FormControl('', [Validators.required, Validators.minLength(3)]);

	constructor (
		public dialogRef: GoDialogRef,
		public ngxUserService: NgxUserService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private elementRef: ElementRef,
		@Inject(GO_MODAL_DATA) public data: {
			initialQuery: string;
		},
		@Inject(goModalToken) private goModalService: ReturnType<typeof goModal>,
		@Inject(masqueradeToken) private masquerade: Masquerade,
		@Inject(userServiceToken) private userService: UserService
	) {
		this.searchForm = this.fb.group({
			searchQuery: this.searchQuery
		});
	}

	public ngOnInit () {
		this.searchForm.controls.searchQuery.setValue(this.data.initialQuery);
		this.getUsersBy(this.data.initialQuery);
	}

	public getUsersBy (query: string) {
		if (this.searchForm.invalid) {
			return;
		}
		this.users$.next([]);
		this.loading$.next(true);
		this.cdr.detectChanges();
		this.ngxUserService.search(query).subscribe((res) => {
			this.users$.next(res);
			this.loading$.next(false);
			this.cdr.detectChanges();
		});
	}

	public close () {
		this.dialogRef.close();
	}

	public clearSearch () {
		this.searchForm.reset();
		this.cdr.detectChanges();
		this.elementRef.nativeElement.querySelector('input.search-input').focus();
	};

	public openEditUser (user) {
		this.goModalService.open({
			modal: 'editUser',
			modalData: {
				user,
				currentUser: this.userService.currentUser,
				edit: false
			}
		}).result.then((resource) => {
			if (resource) {
				this.dialogRef.close(resource);
			}
		}).catch(() => {});
	};

	public maskAs (user) {
		return this.masquerade.as(user).catch(() => {
			this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('mask-user-error_title'),
					message: this.translate.instant('mask-user-error_message')
				}
			});
		});
	}
}
