import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

@Pipe({
	name: 'timeago'
})
export class TimeAgoPipe implements PipeTransform {
	public transform (input: any): string {
		if (!input) {
			return;
		}

		const date = dayjs(input);
		if (!date.isValid()) {
			return 'Invalid Date';
		}

		return date.fromNow();
	}
}
