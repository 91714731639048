import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordRequirementsDirective } from './password-requirements.directive';
import {
	PasswordRequirementsComponent
} from 'ngx/go-modules/src/directives/password-requirements/components/password-requirements.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [
		PasswordRequirementsDirective,
		PasswordRequirementsComponent
	],
	imports: [
		CommonModule,
		OverlayModule,
		TranslateModule
	],
	exports: [
		PasswordRequirementsDirective
	],
	entryComponents: [PasswordRequirementsComponent]
})
export class PasswordRequirementsModule {}
