import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardModule } from 'ngx/dashboard/src/dashboard.module';
import { GoModulesModule } from 'ngx/go-modules/src/go-modules.module';
import { GoTranslateLoader } from 'ngx/go-modules/src/services/go-translation/go-translate-loader';
import { InterceptorsModule } from 'ngx/go-modules/src/services/interceptors';

@NgModule({
	imports: [
		BrowserModule,
		HttpClientModule,
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: GoTranslateLoader.factory,
				deps: [HttpClient]
			}
		}),
		BrowserAnimationsModule,
		DashboardModule,
		GoModulesModule,
		InterceptorsModule
	]
})
export class AppModule {
	constructor () {}

	public ngDoBootstrap () {}
}
