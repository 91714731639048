import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordVisibilityToggleDirective } from './password-visibility-toggle.directive';
import { ShowPasswordButtonModule } from './show-password-button/show-password-button.module';

@NgModule({
	declarations: [
		PasswordVisibilityToggleDirective
	],
	imports: [
		CommonModule,
		ShowPasswordButtonModule
	],
	exports: [
		PasswordVisibilityToggleDirective,
		ShowPasswordButtonModule
	]
})
export class PasswordVisibilityToggleModule {}
