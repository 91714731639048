import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ArchivedItem, ArchivedItemType } from 'ngx/go-modules/src/interfaces/archived/archived.interface';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';
import { ArchivedListDataSource } from 'ngx/go-modules/src/services/archive';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'archive-table-view',
	template: require('./archive-table-view.component.html'),
	styles: [require('./archive-table-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxArchiveTableViewComponent implements OnInit, OnDestroy {
	@ViewChild(MenuComponent) public menuComponent: MenuComponent;
	@ViewChild('tableContainer', {read: ElementRef}) public tableContainer: ElementRef;
	public displayColumns = ['name', 'owner', 'archivedDate', 'actions'];
	public selectedArchive: ArchivedItem;
	public RESOURCE_TYPE = ArchivedItemType;
	private readonly componentDestroyed$ = new Subject<void>();

	constructor (
		public archiveListDataSource: ArchivedListDataSource
	) {}

	public ngOnInit () {
		this.archiveListDataSource.onScrollLimit$.pipe(takeUntil(this.componentDestroyed$)).subscribe();
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$.next();
		this.componentDestroyed$.complete();
	}

	public openMenu (event: MouseEvent, archive) {
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}
		event.preventDefault();
		this.selectedArchive = archive;
		this.menuComponent.openMenu(event, this.tableContainer.nativeElement);
	}

	public getNameList (archive: ArchivedItem) {
		return archive.presenters.map((item) => `${item.first_name} ${item.last_name}`);
	}
}
