import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionInterceptor } from 'go-modules/session-manager/session-manager.interceptor';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { SessionManagerService, sessionManagerToken } from 'go-modules/session-manager/session-manager.service';
import { EventService } from '../../event/event.service';
import { $uiRouterGlobalsToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/ui-router-globals.upgrade';
import { UIRouterGlobals } from '@uirouter/angularjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor (
		@Inject(sessionManagerToken) private sessionManager: SessionManagerService,
		@Inject($uiRouterGlobalsToken) private $uiRouterGlobals: UIRouterGlobals,
		private eventService: EventService
	) {}

	public intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Configure header if goreact request
		if (req.url.indexOf(clientSettings.GoReactV2API) === 0) {
			req = req.clone({
				withCredentials: true
			});
		}

		return next.handle(req).pipe(
			catchError((e) => {
				return throwError(() => SessionInterceptor.responseError(
					e,
					this.sessionManager,
					this.eventService,
					this.$uiRouterGlobals
				));
			})
		);
	}
}
