import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { MediaCardComponent } from './media-card.component';
import { NgxMediaThumbnailModule } from '../media-thumbnail/media-thumbnail.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		NgxMediaThumbnailModule
	],
	exports: [
		MediaCardComponent
	],
	declarations: [
		MediaCardComponent
	],
	entryComponents: [
		MediaCardComponent
	]
})
export class MediaCardModule {}
