import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AccountLockoutDialogComponent } from './account-lockout-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	entryComponents: [
		AccountLockoutDialogComponent
	],
	declarations: [
		AccountLockoutDialogComponent
	]
})
export class AccountLockoutDialogModule {}
