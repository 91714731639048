import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { States } from 'go-modules/enums/states.enum';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import type { ActivityFactory } from 'go-modules/models/activity/activity.factory';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import { FIRST_COMMENT_TOUR_CLASSES } from 'ngx/go-modules/src/services/go-pulse-tour/constants/first-comment-tour';

@Component({
	selector: 'ngx-activity-card',
	template: require('./activity-card.component.html'),
	styles: [require('./activity-card.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxActivityCardComponent implements OnInit {
	public FirstCommentTourClasses = FIRST_COMMENT_TOUR_CLASSES;

	@ViewChild(MenuComponent)
	public menuComponent: MenuComponent;
	@ViewChild('cardContainer', {read: ElementRef})
	public cardContainer: ElementRef;

	@Input() public activity: any;

	public group: any;
	public selectedActivity: any;

	constructor (
		@Inject($stateToken) private $state,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) public userService: UserService,
		@Inject(activityToken) private activityModel: ActivityFactory,
		@Inject(uaDetectToken) public UADetect: UADetectClass
	) {}

	public ngOnInit () {
		this.group = this.selectedService.getGroup();
	}

	public goToActivity (activity) {
		this.selectedService.setActivity(this.activityModel.model(activity));
		this.$state.go(States.DASHBOARD_ACTIVITY_VIEW, {
			activity_id: activity.activity_id
		});
	}

	public openMenu (event: MouseEvent, activity) {
		event.stopPropagation();
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}
		if (!this.canOpenMenu()) {
			return;
		}
		event.preventDefault();
		this.selectedActivity = this.activityModel.model(activity);
		this.menuComponent.openMenu(event, this.cardContainer.nativeElement);
 	}

	public canOpenMenu (): boolean {
		return this.group.hasInstructorRole(true) || this.userService.currentUser.is_root_user;
	}

	public unviewedState (activity): boolean {
		return activity.unviewed_sessions > 0;
	}

	public isParentChildSyncEnabled (activity): boolean {
		return activity.parent_child_sync && activity.is_parent_sync_enabled;
	}

	public shouldShowDueDate () {
		if (this.group.hasInstructorRole(true) && this.UADetect.isMobile()) {
			return false;
		}

		return true;
	}
}
