import * as angular from 'angular';
import { goTourModule } from 'go-modules/go-tour';
import { common } from 'go-modules/models/common';
import { ActivityEditorDefaultMarkerSetTour } from './activity-editor-default-marker-set-tour.service';

export const ActivityEditorDefaultMarkerSetTourModule = angular.module('activity-editor-default-marker-set-tour', [
	goTourModule,
	common
])
	.service('activityEditorDefaultMarkerSetTour', ActivityEditorDefaultMarkerSetTour)
	.name;
