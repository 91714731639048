import template from './modal.html';
import { GoModalBootstrap, GoModalSettings } from '../go-modal-bootstrap.factory';
import { LibraryCollectionViewerModalController as controller } from './modal.controller';
import { IModalStackService } from 'angular-ui-bootstrap';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export interface CollectionModalSettings extends GoModalSettings {
	modalData?: {
		filterType: any;
		mode: string;
		previewable?: boolean;
		collectionManagement?: boolean;
		create?: boolean;
		openToOrg?: number;
	};
}

export class LibraryCollectionViewerModal {
	public static readonly NG1_INJECTION_NAME = 'libraryCollectionViewerModal';
	private static libraryModalStack: any[] = [];

	/* @ngInject */
	constructor (private goModalBootstrap: GoModalBootstrap, private $uibModalStack: IModalStackService) {}

	public open (options: CollectionModalSettings): ng.ui.bootstrap.IModalInstanceService {
		options.template = template;
		options.controller = controller;
		options.windowClass = options.name = options.backdropClass = (options.windowClass || 'library-collection-viewer');
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles

		// If multiple instances, apply animation to first instance of the modal
		if (LibraryCollectionViewerModal.libraryModalStack.length > 0) {
			const prevModal = LibraryCollectionViewerModal.libraryModalStack[
				LibraryCollectionViewerModal.libraryModalStack.length - 1
			];
			prevModal.modalDomEl.addClass('slide-out');
			options.animation = false;
		}

		const modalInstance = this.goModalBootstrap.open(options);
		modalInstance.opened.then(() => {
			const modal = this.$uibModalStack.getTop().value;
			LibraryCollectionViewerModal.libraryModalStack.push(modal);

			// apply animation to the second instance of the modal
			if (LibraryCollectionViewerModal.libraryModalStack.length > 1) {
				modal.modalDomEl.addClass('slide-in');
			}
		});

		// beforeClosed added to angular-ui-bootstrap library to allowing hooking in before modal is closed
		modalInstance.beforeClosed.then(() => {
			LibraryCollectionViewerModal.libraryModalStack.pop();
			const prevModal = LibraryCollectionViewerModal.libraryModalStack[
				LibraryCollectionViewerModal.libraryModalStack.length - 1
			];
			prevModal?.modalDomEl?.removeClass('slide-out');
		});
		return modalInstance;
	}
}

export const libraryCollectionViewerModalToken = upgradeNg1Dependency(LibraryCollectionViewerModal);
