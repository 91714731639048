import { Component, Inject } from '@angular/core';
import { CourseTrialService } from 'ngx/go-modules/src/services/course-trial/course-trial.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'featured-message',
	template: require('./featured-message.component.html'),
	styles: [require('./featured-message.component.scss')]
})
export class FeaturedMessageComponent {

	constructor (
		private courseTrialService: CourseTrialService,
		@Inject(selectedServiceToken) private selectedService: SelectedService
	) {}

	public openAiTrialCourseSelectorDialog () {
		this.courseTrialService.createTeaseWallCourseTrialPromptAction({
			org_id: this.selectedService.getOrg()?.group_id
		})
			.pipe(
				tap((result)=> {
					const startedTrialOnCurrentlySelectedFolder =
						result.selectedCourse.folderId === this.selectedService.getGroup()?.group_id;

					if (startedTrialOnCurrentlySelectedFolder) {
						this.selectedService.setLicense(result.license);
					}
				})
			)
			.subscribe();
	}
}
