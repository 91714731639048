import * as angular from 'angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { goModal, goModalToken } from 'go-modules/modals/go-modal.factory';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { GroupSettingsPanelService, groupSettingsPanelServiceToken } from 'go-modules/group-settings-panel/group-settings-panel.service';
import { FolderListDataSource } from 'ngx/go-modules/src/services/folder-list-datasource/folder-list-datasource';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS, LTI_ENVIRONMENT_MODES } from 'ngx/go-modules/src/services/environment-vars/environments';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { noop } from 'angular';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';

@Component({
	selector: 'activity-context-menu',
	template: require('./activity-context-menu.component.html'),
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class ActivityContextMenuComponent implements OnInit {
	@Input() public canStartActivity;
	@Output() public onStartActivity = new EventEmitter();
	@Output() public viewInstructions = new EventEmitter();

	public environmentVarsService: EnvironmentVarsService;
	public limitActivityEditor: boolean;

	constructor (
		public ngxGoToastService: NgxGoToastService,
		private folderListDataSource: FolderListDataSource,
		private ngxActivityService: NgxActivityService,
		private activityListDataSource: ActivityListDataSource,
		private cd: ChangeDetectorRef,
		private dialog: MatDialog,
		private translate: TranslateService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(goModalToken) private goModalService: ReturnType<typeof goModal>,
		@Inject(groupSettingsPanelServiceToken) private groupSettingsPanel: GroupSettingsPanelService
	) {}

	public ngOnInit () {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
		// Depending on the environment and mode, determine which features are available
		const environment: any = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) || {};
		// This flag is used to limit the activity editor functionality
		this.limitActivityEditor = environment.name === ENVIRONMENTS.LTI &&
			environment.mode === LTI_ENVIRONMENT_MODES.DEFAULT;
	}

	public openInviteModal () {
		return this.goModalService.open({
			modal: 'inviteUsers',
			modalData: {
				group: this.selectedService.getGroup()
			}
		});
	}

	public editActivity () {
		const activity = this.selectedService.getActivity();
		if (!this.ngxActivityService.canEditActivity(activity)) {
			this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('modal-modifying-assignment-disabled_title'),
					message: this.translate.instant('modal-modifying-assignment-disabled_message')
				}
			});
		} else {
			const ownerName = activity.owner_name;
			this.ngxActivityService.openActivityEditor(activity, this.limitActivityEditor)
				.then(() => {
					activity.owner_name = ownerName;
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.SUCCESS,
						message: 'folder-view_activity_updated'
					});
					// copy activity to change object ref and force table to reflect changes
					this.activityListDataSource.editActivity(angular.copy(activity));

					this.cd.markForCheck();
				}).catch(noop);
		}
	}

	public manageUsers () {
		const folder = this.selectedService.getGroup();
		this.groupSettingsPanel.open(folder, this.userService.currentUser, 'users', {}).result.then(
			(group?) => {
				// group is not-null if changed
				if (group) {
					this.folderListDataSource.editFolder(group);
				}
			}
		);
	}

	public hasMenuAccess (): boolean {
		return this.selectedService.getGroup().hasInstructorRole(true) || this.userService.currentUser.is_root_user;
	}

	public isLti () {
		return this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
	}

	public canViewInstruction (): boolean {
		return this.selectedService.getActivity().hasRecordingInstructions();
	}

}
