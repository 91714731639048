import { PulseTourStep, PulseTourConfig } from 'ngx/go-modules/src/services/go-pulse-tour/go-pulse-tour.service';

export const FIRST_COMMENT_TOUR_CLASSES = {
	folderCard: 'first-comment-tour-folder-card',
	activityCard: 'first-comment-tour-activity-card',
	sessionCard: 'first-comment-tour-session-card',
	folderTableComplete: 'first-comment-tour-folder-table-row-complete',
	folderTablePulse: 'first-comment-tour-folder-table-row-pulse',
	activityTableComplete: 'first-comment-tour-activity-table-row-complete',
	activityTablePulse: 'first-comment-tour-activity-table-row-pulse',
	sessionTableComplete: 'first-comment-tour-session-table-row-complete',
	sessionTablePulse: 'first-comment-tour-session-table-row-pulse'
} as const;

const TOUR_KEY = 'first-comment-tour';
const TOUR_STEPS: PulseTourStep[] = [
	{
		completeSelectors: [
			`.${FIRST_COMMENT_TOUR_CLASSES.folderTableComplete}:first-child`,
			`.${FIRST_COMMENT_TOUR_CLASSES.folderCard}:first-child`
		],
		pulse: {
			selectors: [
				`.${FIRST_COMMENT_TOUR_CLASSES.folderTablePulse}:first-child`,
				`.${FIRST_COMMENT_TOUR_CLASSES.folderCard}:first-child`
			]
		}
	},
	{
		completeSelectors: [
			`.${FIRST_COMMENT_TOUR_CLASSES.activityTableComplete}:first-child`,
			`.${FIRST_COMMENT_TOUR_CLASSES.activityCard}:first-child`
		],
		pulse: {
			selectors: [
				`.${FIRST_COMMENT_TOUR_CLASSES.activityTablePulse}:first-child`,
				`.${FIRST_COMMENT_TOUR_CLASSES.activityCard}:first-child`
			]
		}
	},
	{
		completeSelectors: [
			`.${FIRST_COMMENT_TOUR_CLASSES.sessionTableComplete}:first-child`,
			`.${FIRST_COMMENT_TOUR_CLASSES.sessionCard}:first-child`
		],
		pulse: {
			selectors: [
				`.${FIRST_COMMENT_TOUR_CLASSES.sessionTablePulse}:first-child`,
				`.${FIRST_COMMENT_TOUR_CLASSES.sessionCard}:first-child`
			]
		}
	},
	{
		completeSelectors: ['[data-tour="add-comment-btn"]'],
		pulse: {
			selectors: ['[data-tour="add-comment-btn"]']
		}
	}
];

export const FIRST_COMMENT_TOUR_CONFIG: PulseTourConfig = {
	viewTrackKey: TOUR_KEY,
	steps: TOUR_STEPS,
	constraints: {
		tourStartDate: new Date('2025-02-12'),
		isOnboarding: true
	}
};
