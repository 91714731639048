import { NgModule } from '@angular/core';
import { RichInstructionsComponent } from './rich-instructions.component';
import { CommonModule } from '@angular/common';
import { MediaCardModule } from '../media-card/media-card.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		MediaCardModule
	],
	declarations: [
		RichInstructionsComponent
	],
	entryComponents: [
		RichInstructionsComponent
	],
	exports: [
		RichInstructionsComponent
	]
})
export class RichInstructionsModule {}
