import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { FallbackRoute } from './fallback-route.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

export interface AppSessionManagerConfig {
	user: any;
}

export class AppSessionManagerService {
	public static PROVIDER_NAME: string = 'goreact';
	public appSessionManagerConfig: AppSessionManagerConfig;
	public modalOpen = false;

	/* @ngInject */
	constructor (
		public goModal,
		public $window,
		public fallbackRouteService: FallbackRoute
	) { }

	/**
	 * Handles configure.
	 *
	 * @returns {void}
	 */
	public configure (appSessionManagerConfig: AppSessionManagerConfig, eventService: EventService): void
	{
		if (this.appSessionManagerConfig) return;

		// Set user for fallback key
		this.fallbackRouteService.setUser(appSessionManagerConfig.user);

		// handle session timeout
		eventService.listen(EVENT_NAMES.AUTH_ENDED).subscribe(() => {
			const modalData = {user: appSessionManagerConfig.user} as {
				user: any;
				external: boolean;
			};

			if (!this.modalOpen) {
				this.modalOpen = true;
				this.goModal.open({
					modal: 'sessionTimeout',
					modalData
				}).result.then(() => {
					// Always completely reload, so people don't stay on old versions of the app since
					// we deploy everyday
					this.modalOpen = false;
					this.$window.location.reload();
				});
			}
		});

		this.appSessionManagerConfig = appSessionManagerConfig;
	}

	public reset () {
		this.appSessionManagerConfig = null;
	}
}
