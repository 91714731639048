import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
	StatusOverlayComponent
} from 'ngx/go-modules/src/components/video-scene/status-overlay/status-overlay.component';
import { NetworkIndicatorModule } from 'ngx/go-modules/src/components/network-indicator/network-indicator.module';
import { VolumeIndicatorComponent } from 'ngx/go-modules/src/components/video-scene/volume-indicator/volume-indicator.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		NetworkIndicatorModule
	],
	exports: [
		StatusOverlayComponent,
		VolumeIndicatorComponent
	],
	declarations: [
		StatusOverlayComponent,
		VolumeIndicatorComponent
	],
	entryComponents: [
		StatusOverlayComponent,
		VolumeIndicatorComponent
	]
})
export class VideoSceneModule {}
