import { noop } from 'angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { States } from 'go-modules/enums/states.enum';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { SessionListDataSource } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { VIEWS } from 'ngx/go-modules/src/enums/views';
import { SortOrder, SortOrderTranslations } from 'ngx/go-modules/src/enums/sort-order-main';
import { ScoreFilter, ScoreFilterTranslations } from 'ngx/go-modules/src/enums/score-filter';
import { MinePeerFilter, MinePeerFilterTranslations } from 'ngx/go-modules/src/enums/mine-peer-filter';
import { BehaviorSubject, combineLatest, EMPTY, filter, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { SessionCreationWizardService, sessionCreationWizardServiceToken } from 'go-modules/session-creation-wizard/wizard.service';
import { $qToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/q.upgrade';
import { ViewStateDataSource } from 'ngx/go-modules/src/services/view-state-datasource/view-state.datasource';
import { sessionToken, Session as SessionModel } from 'go-modules/models/session/session.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import {
	ActivityInstructionsDialogComponent
} from 'ngx/go-modules/src/components/dialogs/activity-Instructions-dialog/activity-instructions-dialog.component';
import { FeedbackMode, MediaDisplayMode } from 'go-modules/feedback-session/feedback-session.provider';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';
import { FolderListDataSource } from 'ngx/go-modules/src/services/folder-list-datasource/folder-list-datasource';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import { Masquerade, masqueradeToken } from 'go-modules/masquerade/masquerade.service';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import { ConfirmDialogComponent, ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { SessionStorageService, sessionStorageToken } from 'go-modules/session-manager/session-storage.service';
import { equipmentCheckModalToken } from 'go-modules/modals/equipment-check/modal.factory';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';
import { mediaToken } from 'go-modules/models/media/media.factory';
import { MediaSource } from 'go-modules/models/media';
import { ActivityInstructionsModal, activityInstructionsModalToken } from 'go-modules/modals/activity-instructions/modal.factory';
import { MODE as INSTRUCTIONS_MODE } from 'go-modules/modals/activity-instructions/modes';
import { ZeroStateChangedEvent } from '../data-source-zero-state/data-source-zero-state.component';
import { LicenseExpirationHandler } from 'ngx/go-modules/src/services/license-expiration-handler/license-expiration-handler.service';
import type { License } from 'ngx/go-modules/src/interfaces/licenses';
import { NgxSessionService } from 'ngx/go-modules/src/services/session/session.service';
import { goModal, goModalToken } from 'go-modules/modals/go-modal.factory';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';
import { targetElems } from 'ngx/go-modules/src/enums/context-menu-target-elems';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { $stateParamsToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state-params.upgrade';
import { StateParams } from '@uirouter/angularjs';
import { PromptsService } from 'ngx/go-modules/src/services/prompts/prompts.service';
import { StartTestDialogComponent } from '../dialogs/start-test-dialog/start-test-dialog.component';
import { NgxFeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { NgxLicenseService } from '../../services/license';
import { NgxLicenseUpgradeService } from '../../services/license/license-upgrade/license-upgrade.service';
import * as dayjs from 'dayjs';

@Component({
	selector: 'ngx-activity-view',
	template: require('./activity-view.component.html'),
	styles: [require('./activity-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxActivityViewComponent implements OnInit, OnDestroy {
	@ViewChild(MenuComponent) public menuComponent: MenuComponent;
	@ViewChild('activityContainer', {read: ElementRef}) public activityContainer: ElementRef;

	public views = VIEWS;
	public currentView$: Observable<VIEWS>;
	public sortByOptions: SortOrder[] = Object.values(SortOrder).filter((option) => option !== SortOrder.DEFAULT);
	public sortOrderTranslations = SortOrderTranslations;
	public scoreOptions: ScoreFilter[] = Object.values(ScoreFilter);
	public scoreFilterTranslations = ScoreFilterTranslations;
	public minePeerOptions: MinePeerFilter[] = Object.values(MinePeerFilter);
	public minePeerTranslations = MinePeerFilterTranslations;
	public hasSessions$: Observable<boolean>;
	public shouldShowFooterButtons$: Observable<boolean>;
	public shouldShowTestBailedView$: Observable<boolean>;
	public form: FormGroup;
	public componentDestroyed$$ = new Subject();
	public dataSourceZeroState: ZeroStateChangedEvent = {isLoading: true, hasZeroState: false, errorCode: null };

	public activityLoaded$ = new BehaviorSubject<boolean>(false);
	public canStartActivity$$ = new BehaviorSubject<boolean>(false);
	public getSessionCreationDisabledMessage$$ = new BehaviorSubject<string>('');
	public environmentVarsService: EnvironmentVarsService;

	constructor (
		public sessionListDataSource: SessionListDataSource,
		public viewStateDataSource: ViewStateDataSource,
		public ngxSessionService: NgxSessionService,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject($stateToken) private $state,
		@Inject($stateParamsToken) public $stateParams: StateParams,
		@Inject($qToken) private $q,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(sessionToken) private Session: ReturnType<typeof SessionModel>,
		@Inject(sessionCreationWizardServiceToken) private sessionCreationWizardService: SessionCreationWizardService,
		@Inject(activityToken) private  activityModel,
		@Inject(masqueradeToken) private masquerade: Masquerade,
		private activityListDataSource: ActivityListDataSource,
		private folderListDataSource: FolderListDataSource,
		private modal: GoModalService,
		private ngxActivityService: NgxActivityService,
		@Inject(uaDetectToken) public UADetect: UADetectClass,
		@Inject(sessionStorageToken) private sessionStorage: SessionStorageService,
		@Inject(equipmentCheckModalToken) private equipmentCheckModal,
		@Inject(mediaToken) private MediaModel,
		@Inject(activityInstructionsModalToken) private activityInstructionsModal: ActivityInstructionsModal,
		private licenseExpirationHandler: LicenseExpirationHandler,
		@Inject(goModalToken) private goModalService: ReturnType<typeof goModal>,
		private dialog: MatDialog,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef,
		private promptsService: PromptsService,
		private featureFlag: NgxFeatureFlagService,
		private licenseService: NgxLicenseService,
		private ngxLicenseUpgradeService: NgxLicenseUpgradeService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public isLti (): boolean {
		return this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
	}

	public ngOnInit (): void {
		if (this.selectedService.getActivity() === null) {
			this.activityListDataSource.connect().subscribe((activities) => {
				const activity = activities.find((act) => {
					return Number(act.activity_id) === Number(this.$stateParams.activity_id);
				});

				if (activity) {
					this.selectedService.setActivity(activity);
					this.setUp();
					this.activityLoaded$.next(true);
				// fallback to folder view in case we don't have the activity
				} else {
					this.$state.go(States.DASHBOARD_FOLDER_VIEW, {
						folder_id: this.selectedService.getGroup().group_id
					});
				}
			});
		} else {
			this.activityLoaded$.next(true);
			this.setUp();
		}
	}

	public setUp () {
		this.currentView$ = this.viewStateDataSource.viewState$;
		this.hasSessions$ = this.sessionListDataSource.connect()
			.pipe(map((session) => session.length > 0));
		this.shouldShowTestBailedView$ = this.sessionListDataSource.connect().pipe(
			map((sessions) =>
				this.selectedService.getActivity().has_single_recording_attempt &&
				this.selectedService.getGroup().hasPresenterRole() &&
				sessions.some(
					(session: ReturnType<typeof SessionModel>) => (
						!session.archived_at &&
						!session.started_at &&
						session.isOwner(this.userService.currentUser) &&
						session.hasViewedRecordingInstructions()
					)
				)
			)
		);
		this.shouldShowFooterButtons$ = combineLatest({
			hasSessions: this.hasSessions$,
			isFiltered: this.sessionListDataSource.isFiltered$,
			shouldShowTestBailedView: this.shouldShowTestBailedView$
		}).pipe(
			map(({hasSessions, isFiltered, shouldShowTestBailedView}) =>
				(hasSessions || isFiltered) && !shouldShowTestBailedView
			)
		);

		this.form = new FormGroup({
			sortBy: new FormControl(this.sessionListDataSource.getCurrentSort()),
			scoreFilter: new FormControl(this.sessionListDataSource.getCurrentScoreFilter()),
			minePeerFilter: new FormControl(this.sessionListDataSource.getCurrentMinePeerFilter())
		});
		this.form.get('sortBy').valueChanges
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe((selected) => this.sessionListDataSource.setSortBy(selected));
		this.form.get('scoreFilter').valueChanges
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe((selected) => this.sessionListDataSource.setScoreFilter(selected));
		this.form.get('minePeerFilter').valueChanges
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe((selected) => this.sessionListDataSource.setMinePeerFilter(selected));

		// TODO DEV-15860
		if (!this.selectedService.getActivity().viewed_by_me &&
			!this.masquerade.isMasked() &&
			this.hasPresenterRole()) {
			this.activityModel
				.markAsViewed({activity_id: this.selectedService.getActivity().activity_id})
				.$promise.then(() => {
					this.activityListDataSource.reloadStats();
					this.folderListDataSource.reloadStats();
				});
		}

		this.getLicenseAndValidate();
		this.promptsService.showTermsOrTourWhenRequired();
	}

	public ngOnDestroy () {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
		this.canStartActivity$$.complete();
		this.getSessionCreationDisabledMessage$$.complete();
	}

	public hasReviewerRoleOrHigher (): boolean  {
		return this.selectedService.getGroup().hasReviewerRole(true);
	}

	public hasPresenterRole (): boolean  {
		return this.selectedService.getGroup().hasPresenterRole();
	}

	public hasInstructorRole (): boolean {
		return this.selectedService.getGroup().hasInstructorRole(true);
	}

	public canInvite (): boolean {
		return !this.isLti() && (this.hasInstructorRole() || this.userService.currentUser.is_root_user);
	}

	public goToSession (session) {
		this.selectedService.setSession(this.Session.model(session));
		this.$state.go(States.DASHBOARD_SESSION_VIEW, {
			session_id: session.session_id
		});
	}

	public openInviteModal () {
		return this.goModalService.open({
			modal: 'inviteUsers',
			modalData: {
				group: this.selectedService.getGroup()
			}
		});
	}

	public preStartActivity () {
		const activity = this.selectedService.getActivity();

		if (activity.isCommentOnlySingleAttempt()) {
			this.modal.open(StartTestDialogComponent, false, {
				data: {
					currentActivity: activity
				}
			}).afterClosed().subscribe((confirm) => {
				if (confirm) {
					this.startActivity();
				}
			});
		} else {
			this.startActivity();
		}
	}

	public startActivity () {
		// clear out any previously selected session to prevent issues with recording
		// sessionCreationWizardService promise doesnt resolve until after recording instructions
		// are viewed, which happens after we go to the record screen
		// and we don't set the new session until after that
		this.selectedService.setSession(null);
		const activity = this.selectedService.getActivity();
		const group = this.selectedService.getGroup();

		return this.sessionCreationWizardService.start({
			activity,
			group,
			user: this.userService.currentUser,
			openRecordView: (session) => {
				this.$state.go(States.DASHBOARD_SESSION_VIEW, {
					session_id: session.session_id,
					feedbackMode: FeedbackMode.VIEW,
					viewMode: MediaDisplayMode.RECORD
				});
				return this.$q.resolve();
			}
		}).then((result) => {
			result.viewed = true;
			const session = this.Session.model(result);
			this.selectedService.setSession(session);
			this.sessionListDataSource.addSession(session);

			if (this.hasPresenterRole()) {
				this.activityListDataSource.reloadStats();
				this.folderListDataSource.reloadStats();
				// dont allow second submission for single attempt
				if (this.selectedService.getActivity().has_single_recording_attempt) {
					this.getLicenseAndValidate();
				}
			}

			if (activity.isCommentOnly() || activity.isConversationBoard()) {
				this.goToSession(session);
			}
			return result;
		}).catch(noop);
	}

	public viewInstructions () {
		this.modal.open(ActivityInstructionsDialogComponent);
	}

	public shouldShowScoreFilter (): boolean {
		return this.hasReviewerRoleOrHigher() &&
			this.selectedService.getActivity().total_score > 0;
	}

	/**
	 * We show the mine peer filter for peer_review, but not for public_feedback. Why?
	 * These two settings control the permissioning on what peers see
	 * peer_review === false && public_feedback === false: Private
	 * peer_review === true && public_feeback === false: Closed Peer Review
	 * peer_review === true && public_feedback === true: Open Peer Review for Standard/Stimulus (has_response_media)
	 * peer_review === false && public_feedback === true: Open Peer Review for Comment Only (!has_response_media)
	 * In comment-only peer-review, other sessions are filtered out and the feedback-viewer is responsible for combining
	 * every's comments in the activity together.
	 *
	 * Additionally we show it on peer_rubric_critique_enabled when a peer rubic is added, and
	 * require has_response_media to prevent conversation board from seeing the filter.
	 */
	public shouldShowMinePeerFilter (): boolean {
		const activity = this.selectedService.getActivity();

		return this.hasPresenterRole() &&
			(activity.peer_review || activity.peer_rubric_critique_enabled) &&
			activity.has_response_media;
	}

	public shouldShowFeedbackHold () {
		const activity = this.selectedService.getActivity();
		return this.hasInstructorRole() && activity && activity.isViewable('is_feedback_published');
	}

	public openSession (session) {
		const activity = this.selectedService.getActivity();
		if (!session.isRecorded() && activity.isConversation() &&
			this.ngxSessionService.doesGroupSessionHaveMaxPresenterCount(session)) {
			return this.showSessionFullModal();
		} else if (activity.isSingleAttempt() && !activity.isCommentOnly() && !session.media) {
			return this.showFailedSingleAttemptModal();
		}

		this.enterSession(session);
	}

	private showFailedSingleAttemptModal () {
		this.dialog.open(MessageDialogComponent, {
			data: {
				title: this.translate.instant('single-attempt-activity_no-recording'),
				message: this.translate.instant('feedback-session-failed-single-attempt')
			}
		});
	}

	public enterSession (session) {
		const activity = this.selectedService.getActivity();

		// live multi cam (group recording && live review)
		// prompt the user if they would like to be a presenter or observer
		if (activity.isMulticamLiveReview() && !session.isPosted()) {
			// If the group is already full, skip asking the user what they want and navigate to session
			if (!session.hasMaxPresenterCount(activity)) {
				this.confirmParticipation(session);
			}
			else {
				this.goToSession(session);
			}
		}
		// live review
		else if (activity.isLiveSessionEnabled() &&
			!activity.isConversation() &&
			session.isAwaitingStart() &&
			this.mayStart(session)
		) {
			this.confirmParticipation(session, true);
		}
		// group recording
		else if (activity.isConversation() &&
			!this.ngxSessionService.doesGroupSessionHaveMaxPresenterCount(session) &&
			!session.isRecorded()
		) {
			this.checkEquipment(session);
		}
		else {
			this.goToSession(session);
		}
	}

	public zeroStateChanged (event: ZeroStateChangedEvent) {
		this.dataSourceZeroState = event;
		this.cdr.detectChanges();
	}

	public openMenu (event: MouseEvent) {
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}

		if (this.canStartActivity$$ && !this.selectedService.getActivity().isConversationBoard()) {
			const target = event.target as any;
			if (targetElems.includes(target.className)) {
				this.menuComponent.openMenu(event, this.activityContainer.nativeElement);
			}
		}
	}

	private confirmParticipation (session, isLiveReview = false) {
		const user = this.userService.currentUser;
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translate.instant('confirm-join-title'),
				message: this.translate.instant('confirm-join-message'),
				confirmText: this.translate.instant('confirm-join-button-as-presenter'),
				cancelText: this.translate.instant('confirm-join-button-as-observer')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().subscribe((confirm) => {
			if (confirm) {
				if (isLiveReview) {
					this.startLiveReview(session);
				} else {
					this.checkEquipment(session);
				}
			}
			// confirm dialog can return null on close
			// explicitly check if false is returned
			else if (confirm === false){
				session.removePresenter(user);
				this.sessionListDataSource.editSession(session);
				this.goToSession(session);
			}
		});
	}

	private startLiveReview (session) {
		const activity = this.selectedService.getActivity();
		const user = this.userService.currentUser;
		let startPromise = Promise.resolve(null);

		// If media has not been created yet, pre-create the media and assign it to the session.
		// This should only get run when the session was created as a part of a batch creation process.
		// When the Live Review setting is enabled on an assignment,
		// the Instructor can click a button that creates a batch of sessions
		if (!session.media) {
			startPromise = new Promise(async (resolve, _reject) => {
				// MediaModel.createFor() contains async code
				const media = await this.MediaModel.createFor(MediaSource.OPENTOK, session.group_id);
				session.setMedia(media);
				session.save();
				resolve(null);
			});
		}

		startPromise.then(() => {
			if (activity.hasRecordingInstructions() || this.isHeadPhonesInstructionsRequired(session)) {
				const group = this.selectedService.getGroup();
				const embiggen = activity.hasRecordingInstructions() &&
					!!activity.recording_instructions.media &&
					!group.license_trial_exists;
				const modalInstance = this.activityInstructionsModal.open({
					modalData: {
						activity,
						mode: INSTRUCTIONS_MODE.RECORDING,
						showHeadPhones: this.isHeadPhonesInstructionsRequired(session),
						embiggen
					}
				});
				modalInstance.opened
					.then(() => {
						if (!session.hasViewedRecordingInstructions()) {
							session.setViewedRecordingInstructions();
						}
					});

				modalInstance.result.then(() => {
					session.addPresenter(user);
					this.sessionListDataSource.editSession(session);
					this.selectedService.setSession(session);
					this.$state.go(States.DASHBOARD_SESSION_VIEW, {
						session_id: session.session_id,
						feedbackMode: FeedbackMode.VIEW,
						viewMode: MediaDisplayMode.RECORD
					});
				});
			} else {
				session.addPresenter(user);
				this.sessionListDataSource.editSession(session);
				this.selectedService.setSession(session);
				this.$state.go(States.DASHBOARD_SESSION_VIEW, {
					session_id: session.session_id,
					feedbackMode: FeedbackMode.VIEW,
					viewMode: MediaDisplayMode.RECORD
				});
			}
		});
	}

	private isHeadPhonesInstructionsRequired (session) {
		const media = session.source_media || this.selectedService.getActivity().source_media?.media;
		return !!media && media.hasAudio();
	}

	private mayStart (session) {
		const activity = this.selectedService.getActivity();
		const group = this.selectedService.getGroup();
		const user = this.userService.currentUser;
		let hasPermission = session.mayPublish(user, group, activity);

		if (session.source_media?.isPending()) {
			hasPermission = false;
		}

		return hasPermission;
	}

	private checkEquipment (session) {
		const activity = this.selectedService.getActivity();
		const user = this.userService.currentUser;
		const isEquipmentCheckRequired = activity.isEquipmentCheckRequired(this.sessionStorage);

		if (isEquipmentCheckRequired) {
			try {
				const equipmentCheck: any = this.equipmentCheckModal.open({ activity, session });
				equipmentCheck.result
					.then(() => {
						session.addPresenter(user);
						this.sessionListDataSource.editSession(session);
						this.goToSession(session);
					});
			} catch (e) {
				this.dialog.open(MessageDialogComponent, {
					data: {
						title: this.translate.instant('unknown-error-title'),
						message: this.translate.instant('group-view_zero-state-unexpected-error')
					}
				});
			}
		} else {
			session.addPresenter(user);
			this.sessionListDataSource.editSession(session);
			this.goToSession(session);
		}
	}

	private showSessionFullModal (): void {
		this.dialog.open(MessageDialogComponent, {
			data: {
				title: this.translate.instant('session-list-item_controller-max-presenter-title'),
				message: this.translate.instant('session-list-item_controller-max-presenter-message')
			}
		});
	}

	private validateCanStartActivity () {
		this.canStartActivity$$.next(this.ngxActivityService.canStartActivity());
		this.getSessionCreationDisabledMessage$$.next(this.ngxActivityService.getSessionCreationDisabledMessage());

		const license = this.selectedService.getLicense();
		if (license) {
			// TODO STAB-489 Consolidate ngx/ng1 License interfaces since we're already using them interchangeably
			this.licenseExpirationHandler.displayLicenseExpirationWarningToastIfNeeded(license as License);
		}
	}

	private getLicenseAndValidate () {
		this.selectedService.selectedSubject
			.asObservable()
			.pipe(
				takeUntil(this.componentDestroyed$$),
				filter((selected: any) => selected.group)
			)
			.subscribe((selected) => {
				if (this.isLti() &&
					this.hasInstructorRole() &&
					this.featureFlag.isAvailable('AI_TRIAL') &&
					selected.group.license_trial_exists &&
					selected.license?.salesforce_license.is_free_trial &&
					(!selected.license.is_active || dayjs().isAfter(dayjs.utc(selected.license.ends_at)))) {
					this.handleExpiredLicenseTrial(selected.license).subscribe();
				}
				this.validateCanStartActivity();
			});
	}

	private handleExpiredLicenseTrial (license: License) {
		return this.ngxLicenseUpgradeService.upgradeOrPurchase(license.trial.previous_license, true, true, true)
			.pipe(
				switchMap(({requestUpgrade, shouldRestart}) => {
					if (shouldRestart) {
						return this.handleExpiredLicenseTrial(license);
					} else if (requestUpgrade) {
						// Non license-admin requested an upgrade
						return this.ngxLicenseUpgradeService
							.handleLicenseUpgradeRequest(license.trial.previous_license.id);
					} else {
						// License admin successfully upgraded
						return EMPTY;
					}
				}),
				takeUntil(this.componentDestroyed$$)
			);
	}
}
