import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { OrgTypeOption, ORG_TYPE_LABELS, ORG_TYPE_OPTIONS } from 'go-modules/org-settings/org-settings.constants';
import type { OrgCreateData } from 'ngx/dashboard/src/interfaces/sign-up/org-create-data';
import type { SignupOrg } from 'ngx/dashboard/src/interfaces/sign-up/signup-orgs-response';
import type { CountryData } from 'ngx/go-modules/src/interfaces/country-data';
import { NgxCountryService } from 'ngx/go-modules/src/services/country/country.service';
import { NoWhitespaceValidator } from 'ngx/go-modules/src/validators/no-whitespace';
import { ConfirmDialogComponent } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import type { ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
	selector: 'org-create',
	template: require('./org-create.component.html'),
	styles: [require('./org-create.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
		useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
	}, TranslatePipe]
})
export class OrgCreateComponent implements OnChanges {
	@Input()
	public email: string;

	@Input()
	public unallowedDomain: boolean;

	@Input()
	public orgs: SignupOrg[] = [];

	@Input()
	public set defaultName (value: string) {
		this.form.controls.name.setValue(value);
	}

	@Output()
	public onComplete = new EventEmitter<OrgCreateData>();

	@Output()
	public onBack = new EventEmitter<void>();

	public form = new FormGroup({
		type: new FormControl(null, [Validators.required]),
		name: new FormControl(null, [
			Validators.required,
			NoWhitespaceValidator,
			this.orgNameUniqueValidator(),
			Validators.maxLength(60)
		]),
		country: new FormControl(null, [Validators.required]),
		orgJoinViaEmailDomain: new FormControl(true)
	});

	public selectedTypeLabel: string = '';
	public countries: CountryData[] = [];
	public domain: string = '';
	public orgJoinViaEmailDomainConfirmDialogFlag: boolean = false;

	constructor (
		private countryService: NgxCountryService,
		private dialog: MatDialog,
		private translatePipe: TranslatePipe
	) {}

	public ngOnInit (): void {
		this.form.controls.country.setValidators(Validators.required);
		this.countryService.getCountries()
			.subscribe((countries) => this.countries = countries);
	}

	public ngOnChanges (changes: SimpleChanges) {
		if (changes.email) {
			this.domain = `@${changes.email.currentValue?.split('@').pop()}`;
		}
	}

	public get orgTypes (): OrgTypeOption[] {
		return Object.values(ORG_TYPE_OPTIONS)
			.filter((value) => value.type !== ORG_TYPE_LABELS.OTHER);
	}

	public orgNameUniqueValidator (): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const value = control.value;

			if (!value) {
				return null;
			}

			const exists = this.orgs.find((org) => {
				return org.name.toLowerCase() === value.toLowerCase();
			});

			if (exists) {

				return { orgNameUniqueValidator: 'account-setup-error-org-name-exists' };
			}

			return null;
		};
	}

	public submit () {
		if (this.form.valid) {
			const allowedDomains = [];
			const { value: checked } = this.form.get('orgJoinViaEmailDomain');

			if (checked && !this.unallowedDomain) {
				allowedDomains.push(this.domain.split('@').pop());
			}

			this.onComplete.emit({
				name: this.form.controls.name.value,
				orgSettings: {
					org_type: this.form.controls.type.value,
					country_code: this.form.controls.country.value
				},
				allowedDomains,
				invites: []
			});
		}
	}

	public changeLabel (event) {
		this.selectedTypeLabel = this.orgTypes.find((value) => value.type === event.value).optionLabel;
	}

	public toggleOrgJoinViaEmailDomain () {
		const { value: checked } = this.form.get('orgJoinViaEmailDomain');

		if (!this.orgJoinViaEmailDomainConfirmDialogFlag) {
			const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
				data: {
					title: this.translatePipe.transform('account-setup-org-join-via-email-domain-confirm-dialog-title'),
					message: this.translatePipe.transform('account-setup-org-join-via-email-domain-confirm-dialog-message'),
					confirmText: this.translatePipe.transform('common_continue')
				} as ConfirmDialogData
			});

			dialogRef.afterClosed()
				.subscribe((confirm) => {
					if (confirm) {
						this.form.controls.orgJoinViaEmailDomain.setValue(!checked);
					}

					this.orgJoinViaEmailDomainConfirmDialogFlag = true;
				});
		} else {
			this.form.controls.orgJoinViaEmailDomain.setValue(!checked);
		}
	}

	public get shouldShowOrgJoinViaEmailDomainCheckbox (): boolean {
		return !this.unallowedDomain;
	}
}
