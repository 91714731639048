import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { UserSearchDialogComponent } from './user-search-dialog.component';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeAgoPipeModule } from 'ngx/go-modules/src/pipes/timeago/timeago.pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		A11yModule,
		MatTooltipModule,
		TimeAgoPipeModule
	],
	declarations: [
		UserSearchDialogComponent
	],
	exports: [
		UserSearchDialogComponent
	],
	entryComponents: [
		UserSearchDialogComponent
	]
})
export class UserSearchDialogModule {}
