import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxNoGroupsComponent } from 'ngx/go-modules/src/components/no-groups/no-groups.component';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';

@NgModule({
	declarations: [
		NgxNoGroupsComponent
	],
	entryComponents: [
		NgxNoGroupsComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		NgxZeroStateModule
	],
	exports: [
		NgxNoGroupsComponent
	]
})
export class NgxNoGroupsModule {}
