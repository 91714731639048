import { IScope, noop } from 'angular';

import { IFormController } from 'angular';
import { ActivityInstructionModel } from 'go-modules/models/activity';
import type { ModalOptions } from './options';
import { TranslationKeys } from './translation-keys';
import { MediaThumbnailOptions } from 'go-modules/media-thumbnail/media-thumbnail.controller';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { CONTENT_TYPES, MODES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { LibraryCollectionViewerModal } from '../library-collection-viewer/modal.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { InstructionsPreviewDialogComponent } from 'ngx/go-modules/src/components/dialogs/instructions-preview/instructions-preview-dialog.component';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';

export class ActivityInstructionsEditorModalController {

	// These methods are injected by the angular ui bootstrap modal service
	public $dismiss: (reason?: any) => boolean;
	public $close: (result?: ActivityInstructionModel) => boolean;

	// This is injected when the component template's form is compiled
	public formController: IFormController;

	// These are injected into this controller by the modal service
	public instructionsType: string;
	public modalOptions: ModalOptions;
	public translationKeys: TranslationKeys;

	// Form data
	public text: string;
	public media: any;

	public tooltipString: string = '';
	public showTooltip: boolean = false;

	public readonly CHARACTER_LIMIT = 6000;

	public thumbnailOptions: MediaThumbnailOptions;
	public ariaLabelTitleKey: string;
	public error = { contentLength: true };
	private eventSubscription: any;

	/* @ngInject */
	constructor (
		private $scope: IScope,
		private confirmModal: any,
		private mediaPreviewModal,
		private libraryCollectionViewerModal: LibraryCollectionViewerModal,
		private MediaModel,
		private eventService: EventService,
		private $translate: ng.translate.ITranslateService,
		private ngxDowngradeModalService: DowngradeModalService,
		private ngxGoModalService: GoModalService
	) {}

	public $onInit (): void {
		this.ariaLabelTitleKey = this.instructionsType === ActivityInstructionModel.TYPE.RECORDING ?
			'activity-info_recording-instructions' :
			'activity-info_feedback-instructions';

		this.thumbnailOptions = {
			actions: false,
			hideTitle: true,
			groupId: this.modalOptions.groupId,
			hideDuration: true
		};
		const instructions = this.modalOptions.instructions;
		this.text = instructions ? instructions.text : null;
		this.media = instructions ? instructions.media : null;

		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.MEDIA_SYNC))
			.subscribe((ev: GoEvent) => {
				if (this.media && parseInt(this.media.media_id, 10) === parseInt(ev.data.media_id, 10)) {
					Object.assign(this.media, ev.data);
				}
			});
	}

	public $onDestroy (): void {
		this.eventSubscription?.unsubscribe();
	}

	public openLibrary () {
		return this.libraryCollectionViewerModal.open({
			modalData:{
				filterType: [CONTENT_TYPES.MEDIA, CONTENT_TYPES.DOCUMENTS],
				mode: MODES.SELECT,
				previewable: false,
				collectionManagement: true
			}
		}).result.then((res: any)  => {
			const media = this.MediaModel.model(res);
			this.media = media;
			this.formController.$setDirty();
		}).catch(noop);
	}

	public openRemoveMediaInstructionsModal (): Promise<any> {
		return this.ngxDowngradeModalService.openConfirmDialog({
			title: this.$translate.instant('modal-remove-instructions-from-activity_remove', {type: this.instructionsType}),
			message: this.$translate.instant('modal-remove-instructions-from-activity_sure-remove', {type: this.instructionsType}),
			confirmText: this.$translate.instant('common_remove')
		}).then(() => {
			this.media = null;
			this.formController.$setDirty();
			this.$scope.$apply();
		}).catch(noop);
	}

	public onFormSubmit (): void {
		if (this.textWithoutHTMLTags().length > this.CHARACTER_LIMIT) {
			return;
		}

		let instructions: ActivityInstructionModel = this.modalOptions.instructions;

		if (!instructions) {
			instructions = ActivityInstructionModel.create();
		}

		const text = this.text?.includes('class="placeholder') ? '' : this.text;

		instructions.media = this.media;
		instructions.media_id = this.media ? this.media.media_id : null;
		instructions.text = text;
		instructions.type = this.instructionsType;

		if (!instructions.text && !instructions.media) {
			instructions = null;
		}

		this.$close(instructions);
	}

	public openConfirmDiscardChangesModal (): Promise<any> {
		return this.confirmModal.open({
			size: 'sm',
			modalData: {
				message: 'activity-settings_controller-message-2',
				title: 'activity-settings_controller-unsaved-changes',
				yes: 'common_discard-changes',
				no: 'common_cancel'
			}
		}).result.then(() => {
			this.$dismiss();
		}).catch(noop);
	}

	/**
	 * Open the preview media modal
	 */
	 public openMediaPreviewModal (edit = true): IModalInstanceService {
		const modalData = {
			edit,
			media: this.media,
			groupId: this.modalOptions.groupId
		};

		return this.mediaPreviewModal.open({modalData});
	}

	/**
	 * Opens instructions preview modal
	 */
	public openInstructionsPreviewModal (): GoDialogRef {
		let text = this.text;
		if (text) {
			text = this.text.includes('class="placeholder') ? '' : this.text;
		}

		const data = {
			instruction: {
				text,
				media: this.media,
				type: this.instructionsType
			}
		};

		return this.ngxGoModalService.open(InstructionsPreviewDialogComponent, false, {
			data
		});
	}

	public shouldShowPreview (): boolean {
		let text = this.text;
		if (text) {
			text = this.text.includes('class="placeholder') ? '' : text;
		}

		return !!(text || this.media);
	}

	public textWithoutHTMLTags (): string {
		return this.text ? this.text.replace(/(<([^>]+)>)/gi, '') : '';
	}

	public updateText (updatedText): void {
		this.text = updatedText;
		if (this.text.length > 0 && !this.text.includes('class="placeholder')) {
			this.formController.$setDirty();
		} else {
			if (this.modalOptions.instructions) { // edit instruction, not new
				this.formController.$setDirty();
			} else {
				this.formController.$setPristine();
			}
		}
		this.$scope.$apply();
	}
}
