import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TranscriptViewerComponent } from './transcript-viewer.component';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormatTimeModule } from 'ngx/go-modules/src/pipes/format-time/format-time.module';
import { TranscriptSearchComponent } from './transcript-search/transcript-search.component';
import { FormsModule } from '@angular/forms';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { EditTranscriptionSpeakersDialogModule } from 'ngx/go-modules/src/components/dialogs/edit-transcription-speakers-dialog/edit-transcription-speakers-dialog.module';
import { TeaseWallModule } from 'ngx/go-modules/src/directives/tease-wall/tease-wall.module';
import { GoSelectModule } from 'ngx/go-modules/src/directives/go-select/go-select.module';
import { BadgeModule } from 'ngx/go-modules/src/components/badge/badge.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		NgxZeroStateModule,
		ScrollingModule,
		FormatTimeModule,
		FormsModule,
		GoMaterialModule,
		EditTranscriptionSpeakersDialogModule,
		TeaseWallModule,
		GoSelectModule,
		BadgeModule
	],
	exports: [
		TranscriptViewerComponent,
		TranscriptSearchComponent
	],
	declarations: [
		TranscriptViewerComponent,
		TranscriptSearchComponent
	],
	entryComponents: [
		TranscriptViewerComponent,
		TranscriptSearchComponent
	]
})
export class TranscriptViewerModule {}
