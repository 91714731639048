import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import type {
	LicensesResponse,
	LicenseUsageConfig,
	SalesforceLicense,
	SalesForceNotificationUI
} from 'ngx/go-modules/src/interfaces/licenses';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { UserService as NgxUserService } from 'ngx/go-modules/src/services/user/user.service';
import { Observable } from 'rxjs';
import { BehaviorSubject, EMPTY, of, Subject } from 'rxjs';
import { catchError, finalize, ignoreElements, map, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import type { UserService } from 'go-modules/models/user/user.service';
import { userServiceToken } from 'go-modules/models/user/user.service';
import { LazyPaymentLoader, lazyPaymentLoaderToken } from 'go-modules/payment-panel/lazy-payment-loader.service';
import { LICENSE_TRANSACTION_TYPE, PAYMENT_TYPES, PURCHASE_TYPES } from 'go-modules/payment-panel/payment-panel.controller';
import type { StateService } from '@uirouter/angularjs';
import { States } from 'go-modules/enums/states.enum';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import * as dayjs from 'dayjs';
import { ExpirationPolicies, SeatOveragePolicies } from 'ngx/go-modules/src/enums/salesforce-license';
import {
	LicenseExpirationHandler
} from 'ngx/go-modules/src/services/license-expiration-handler/license-expiration-handler.service';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { NgxLicenseUpgradeService } from 'ngx/go-modules/src/services/license/license-upgrade/license-upgrade.service';
import { LicenseService } from 'ngx/go-modules/src/services/license/license.service';

export const NETWORK_LOST_TRANSLATION_KEY = 'license-management_error-network-lost';
export const UNKNOWN_ERROR_TRANSLATION_KEY = 'license-management_error-unknown-error';

export const LICENSE_DOT_COLORS = {
	DOT_GREEN: 'dot-green',
	DOT_YELLOW: 'dot-yellow',
	DOT_RED: 'dot-red'
};

export const LICENSE_NOTIF_LEVEL_TYPE = {
	EXPIRATION: 'expiration',
	USAGE: 'usage'
};

export const LICENSE_EXPIRATION_DAY = {
	TODAY: 0,
	TOMORROW: 1
};

@Component({
	selector: 'licenses-table',
	template: require('./licenses-table.component.html'),
	styles: [require('./licenses-table.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicensesTableComponent implements OnChanges, OnDestroy {
	@Input() public orgId;
	@Input() public myAccount;
	@Input() public readOnly = false;
	@Input() public expiredOnly = false;

	public readonly SeatOveragePolicies = SeatOveragePolicies;
	public licenses$: Observable<LicensesResponse>;
	public expandedLicenses: { [key: number]: boolean } = {};
	public destroy$$ = new Subject<void>();
	public showCreateLicenseButton = false;
	public licenseUsageConfig: LicenseUsageConfig;
	public showPlanColumn$ = new BehaviorSubject(false);
	public licenseBadgesDisabled$ = new BehaviorSubject(false);

	public constructor (
		@Inject(userServiceToken) public userService: UserService,
		@Inject(lazyPaymentLoaderToken) public paymentLoader: LazyPaymentLoader,
		@Inject($stateToken) private $state: StateService,
		private licenseService: LicenseService,
		private groupService: NgxGroupService,
		private ngxUserService: NgxUserService,
		private translate: TranslateService,
		public featureFlag: NgxFeatureFlagService,
		private snackBar: MatSnackBar,
		public cd: ChangeDetectorRef,
		public licenseExpirationHandler: LicenseExpirationHandler,
		public injector: Injector,
		private ngxLicenseUpgradeService: NgxLicenseUpgradeService
	) {
		this.showPlanColumn$.next(this.featureFlag.isAvailable('LICENSE_UPGRADE_PURCHASE'));
	}

	private static errorHandler (err: HttpErrorResponse): string {
		if (err.status === 0) {
			return NETWORK_LOST_TRANSLATION_KEY;
		}

		return UNKNOWN_ERROR_TRANSLATION_KEY;
	}

	public ngOnChanges (changes: SimpleChanges): void {
		this.getLicenses(changes.orgId.currentValue);
		this.cd.detectChanges();
	}

	public ngOnDestroy (): void {
		this.destroy$$.next(void(0));
		this.destroy$$.complete();
	}

	public getLicenses (orgId: number|null) {
		let request;
		if (orgId == null) {
			request = this.expiredOnly ?
				this.ngxUserService.getExpiredLicenses() :
				this.ngxUserService.getCurrentLicenses();
		} else {
			request = !this.expiredOnly ?
				this.groupService.getCurrentLicenses(orgId) :
				this.groupService.getExpiredLicenses(orgId);
		}

		const sharedRequest$ = request.pipe(share());

		// Show an error if loading fails
		sharedRequest$.pipe(
			ignoreElements(),
			catchError((err: HttpErrorResponse) => of(err)),
			map(LicensesTableComponent.errorHandler),
			switchMap((errorTranslationKey: string) => this.translate.get(errorTranslationKey))
		).subscribe((translationString: string) => this.snackBar.open(translationString, null, { duration: 10000 }));

		// Only show active licenses
		this.licenses$ = sharedRequest$.pipe(
			catchError(() => EMPTY),
			tap((response: LicensesResponse) => {
				this.licenseUsageConfig = response.licenseUsageConfig;

				this.showCreateLicenseButton = !this.expiredOnly &&
					!this.readOnly &&
					response.licenses.every((salesforceLicense) => {
						return salesforceLicense.is_free_trial || salesforceLicense.requires_license_holders;
					});
			}),
			map((response: LicensesResponse) => {
				response.licenses.map((salesforceLicense: SalesforceLicense) => {
					salesforceLicense.dotIcon = this.getDotIconData(salesforceLicense);
				});

				return response;

			}),
			finalize(() => {
				this.expandedLicenses = {};
				this.cd.detectChanges();
			})
		);
	}

	public toggleLicenseHolders (license: SalesforceLicense) {
		this.expandedLicenses[license.id] = !this.expandedLicenses[license.id];
		this.cd.detectChanges();
	}

	public onLicensePlanBadgeClicked (license: SalesforceLicense) {
		if (this.shouldShowRenewButtonInBadge(license)) {
			return this.renewLicense(license);
		}

		this.licenseBadgesDisabled$.next(true);

		const result = this.ngxLicenseUpgradeService.upgradeOrPurchase(
			license,
			false, // don't refetch the updated/new license
			false // don't use org in selected service to fetch account
		);

		result.pipe(
			switchMap(({requestUpgrade}) => {
				if (requestUpgrade) {
					// Non license-admin requested an upgrade
					return this.ngxLicenseUpgradeService.handleLicenseUpgradeRequest(license.license_id);
				} else {
					// License admin successfully upgraded or renewed
					this.getLicenses(this.orgId);
					this.cd.detectChanges();
					return EMPTY;
				}
			}),
			takeUntil(this.destroy$$),
			finalize(() => this.licenseBadgesDisabled$.next(false))
		).subscribe();
	}

	public async createLicense (isPaymentMode: boolean) {
		const mode = isPaymentMode ? PAYMENT_TYPES.CARD : PAYMENT_TYPES.REQUEST_INVOICE;

		await this.paymentLoader.openPayForm(
			this.userService.currentUser,
			// This is the only okay time to pass in myAccount.
			// myAccount is just the first account you have access to.
			// Since licenses are in the context of an org, and this button
			// is outside org context, we have to just pick one.
			// The user may switch the org they intend the license for inside the payForm.
			this.myAccount,
			() => {
				this.getLicenses(this.orgId);
				this.cd.detectChanges();
			},
			null,
			mode,
			PURCHASE_TYPES.LICENSE,
			LICENSE_TRANSACTION_TYPE.INITIAL
		);
	}

	public purchaseSeats (salesforceLicense: SalesforceLicense) {
		return this.licenseService.fetchOrCreateUserAccount(
			salesforceLicense.license_id
		).subscribe((account) => {
			this.paymentLoader.openPayForm(
				this.userService.currentUser,
				account,
				() => {
					this.getLicenses(this.orgId);
					this.cd.detectChanges();
				},
				salesforceLicense,
				PAYMENT_TYPES.CARD,
				PURCHASE_TYPES.LICENSE,
				LICENSE_TRANSACTION_TYPE.SEATS
			);
		});
	}

	public renewLicense (license: SalesforceLicense) {
		return this.licenseService.fetchOrCreateUserAccount(
			license.license_id
		).subscribe((account) => {
			this.paymentLoader.openPayForm(
				this.userService.currentUser,
				account,
				() => {
					this.getLicenses(this.orgId);
					this.cd.detectChanges();
				},
				license,
				PAYMENT_TYPES.CARD,
				PURCHASE_TYPES.LICENSE,
				LICENSE_TRANSACTION_TYPE.INITIAL
			);
		});
	}

	public navigateToLicenseSeatsManagement (licenseId: number, licenseHolderEmail: string|null = null)
	{
		this.$state.go(States.LICENSE_SEATS_MANAGEMENT, {
			licenseId,
			licenseHolderEmail
		});
	}

	public getExpirationDays (sfLicense: SalesforceLicense): number {
		const endsAt = dayjs.utc(sfLicense.license.ends_at);
		return +endsAt.diff(dayjs(), 'days');
	}

	public showLicenseMenu (sfLicense: SalesforceLicense): boolean {
		return this.userService.currentUser.is_root_user || sfLicense.is_license_admin || sfLicense.is_org_admin;
	}

	public showPurchaseSeatsButton (salesforceLicense: SalesforceLicense): boolean {
		return salesforceLicense.self_pay &&
			salesforceLicense.is_license_admin &&
			!this.expiredOnly &&
			salesforceLicense.license.licenseProduct.num_seats === null;
	}

	public showLicenseEndDateDot (salesforceLicense: SalesforceLicense): boolean {
		return salesforceLicense.has_renewal === false &&
			!this.expiredOnly &&
			this.licenseExpirationHandler.isExpiringLicense(this.formatLicense(salesforceLicense));
	}

	public showRenewLicenseButton (salesforceLicense: SalesforceLicense): boolean {
		return (salesforceLicense.is_license_admin || salesforceLicense.is_org_admin) &&
			salesforceLicense.expiration_policy ===  ExpirationPolicies.RESTRICTED &&
			!salesforceLicense.has_renewal;
	}

	public showRenewLicenseMenuButton (salesforceLicense: SalesforceLicense): boolean {
		// We don't need this renew menu button for free trial licenses since the same can be done through the badge
		if (this.featureFlag.isAvailable('LICENSE_UPGRADE_PURCHASE') && salesforceLicense.is_free_trial) {
			return false;
		}

		return this.showRenewLicenseButton(salesforceLicense);
	}

	public hideLicensePlanBadgeActionButton (salesforceLicense: SalesforceLicense) {
		return (!this.licenseExpirationHandler.mayAdministerLicense(salesforceLicense.license)
			&& salesforceLicense.is_free_trial) || salesforceLicense.has_renewal;
	}

	public getDotIconData (sfLicense: SalesforceLicense) {
		const iconData = {
			expirationDot: {} as SalesForceNotificationUI,
			usageDot: {} as SalesForceNotificationUI
		};

		// Calculate for expiration
		iconData.expirationDot = this.getIconDataForExpiration(sfLicense);
		iconData.usageDot = this.getIconDataForUsage(sfLicense);

		return iconData;
	}

	public shouldShowRenewButtonInBadge (salesforceLicense: SalesforceLicense): boolean {
		return this.licenseExpirationHandler.isExpiringLicense(this.formatLicense(salesforceLicense)) &&
			this.showRenewLicenseMenuButton(salesforceLicense);
	}

	private getIconDataForExpiration (sfLicense: SalesforceLicense): SalesForceNotificationUI {
		let color: string = LICENSE_DOT_COLORS.DOT_RED;
		const toolTip = {
			key: '',
			value: 0,
			renewalText: ''
		};
		const expireInDays = this.getExpirationDays(sfLicense);

		if (sfLicense.expiration_policy ===  ExpirationPolicies.UNRESTRICTED) {
			if (this.isExpiredOrInactive(sfLicense)) {
				toolTip.key = 'license-management-seat-expiration-critical-tooltip-unrestricted';
			} else if (this.willExpireTomorrow(sfLicense)) {
				toolTip.key = 'license-management-seat-expiration-warning-tooltip-unrestricted';
			} else if (this.willExpireToday(sfLicense)) {
				toolTip.key = 'license-management-seat-expiration-warning-tooltip-unrestricted-today';
			} else {
				toolTip.key = 'license-management-seat-expiration-notice-tooltip-unrestricted';
				color = LICENSE_DOT_COLORS.DOT_YELLOW;
			}
		} else {
			if (this.licenseExpirationHandler.mayAdministerLicense(this.formatLicense(sfLicense))) {
				if (this.isExpiredOrInactive(sfLicense)) {
					toolTip.key = sfLicense.is_free_trial ?
						'license-management-seat-expiration-critical-tooltip-restricted-free-trial' :
						'license-management-seat-expiration-critical-tooltip-restricted';
				} else if (this.willExpireTomorrow(sfLicense)) {
					toolTip.key = sfLicense.is_free_trial ?
						'license-management-seat-expiration-warning-tooltip-restricted-free-trial' :
						'license-management-seat-expiration-warning-tooltip-restricted';
				} else if (this.willExpireToday(sfLicense)) {
					toolTip.key = sfLicense.is_free_trial ?
						'license-management-seat-expiration-warning-tooltip-restricted-today-free-trial' :
						'license-management-seat-expiration-warning-tooltip-restricted-today';
				} else {
					toolTip.key = sfLicense.is_free_trial ?
						'expiring-free-trial-license_days-remaining' :
						'license-management-seat-expiration-notice-tooltip';
					color = LICENSE_DOT_COLORS.DOT_YELLOW;
				}

				toolTip.renewalText = this.translate.instant('license-management_renew-license-optional-text');
			} else {
				if (this.isExpiredOrInactive(sfLicense)) {
					toolTip.key = 'license-management-seat-expiration-critical-tooltip-restricted-no-permission';
				} else if (this.willExpireTomorrow(sfLicense)) {
					toolTip.key = 'license-management-seat-expiration-warning-tooltip-restricted-no-permission';
				} else if (this.willExpireToday(sfLicense)) {
					toolTip.key = 'license-management-seat-expiration-warning-tooltip-restricted-no-permission-today';
				} else {
					toolTip.key = 'license-management-seat-expiration-notice-tooltip';
					color = LICENSE_DOT_COLORS.DOT_YELLOW;
				}
			}
		}

		toolTip.value = expireInDays;

		return {color, toolTip};
	}

	private formatLicense (sfLicense: SalesforceLicense) {
		const tempLicense = {} as any;
		Object.assign(tempLicense, sfLicense);

		const license = tempLicense.license;
		license.salesforce_license = tempLicense;
		delete license.salesforce_license.license;
		return license;
	}

	private isExpiredOrInactive (sfLicense: SalesforceLicense): boolean {
		const expireInDays = this.getExpirationDays(sfLicense);
		return expireInDays < LICENSE_EXPIRATION_DAY.TODAY || !sfLicense.license.is_active;
	}

	private willExpireTomorrow (sfLicense: SalesforceLicense): boolean {
		const expireInDays = this.getExpirationDays(sfLicense);
		return expireInDays === LICENSE_EXPIRATION_DAY.TOMORROW;
	}

	private willExpireToday (sfLicense: SalesforceLicense): boolean {
		return this.getExpirationDays(sfLicense) === LICENSE_EXPIRATION_DAY.TODAY;
	}

	private getIconDataForUsage (sfLicense: SalesforceLicense): SalesForceNotificationUI {
		let color: string;
		const toolTip = {
			key: 'license-management-seat-used-warning-tooltip',
			value: 0,
			seatCount: sfLicense.total_seats
		};
		const usagePercent = this.getSeatsUsagePercent(sfLicense);

		if (usagePercent < this.licenseUsageConfig?.warning_percent_usage) {
			color = LICENSE_DOT_COLORS.DOT_GREEN;
		} else if (usagePercent < 100) {
			color = LICENSE_DOT_COLORS.DOT_YELLOW;
		} else {
			color = LICENSE_DOT_COLORS.DOT_RED;
			toolTip.key = sfLicense.is_free_trial ?
				'license-management-seat-used-critical-tooltip-free-trial' :
				'license-management-seat-used-critical-tooltip';
		}

		toolTip.value = usagePercent;

		return {color, toolTip};
	}

	private getSeatsUsagePercent (sfLicense: SalesforceLicense): number {
		let usage = 0;
		if (sfLicense.total_seats > 0) {
			usage = (sfLicense.total_seats_consumed / sfLicense.total_seats) * 100;
		}

		return Math.floor(usage);
	}
}
