import { EquipmentCheckModalController } from './modal.controller';
import template from './modal.html';
import { MediaSource } from '../../models/media';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export const EQUIPMENT_CHECK_STORAGE_KEY_PREFIX: string = 'equipment-check-passed';

/* @ngInject */
export function equipmentCheckModal (
	goModalBootstrap,
	sessionStorage
) {

	function open (options: any = {}): ng.IPromise<any> {
		const storageKey = `${EQUIPMENT_CHECK_STORAGE_KEY_PREFIX}-${MediaSource.OPENTOK}`;
		const equipmentOnly = options.activity.is_conversation;

		// Build recorder options
		const recorderOptions: any = {
			equipmentOnly
		};

		options.template = template;
		options.controller = EquipmentCheckModalController;
		options.windowClass = options.name = 'equipment-check-modal';
		options.backdropClass = 'equipment-check-modal-backdrop';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles
		options.modalData = {
			recorderOptions,
			group_id: options.session.group_id,
			activity: options.activity
		};

		const modal = goModalBootstrap.open(options);

		modal.result
			.then(() => {
				sessionStorage.set(storageKey, true);
			});

		return modal;
	}

	return { open };
}

equipmentCheckModal.NG1_INJECTION_NAME = 'equipmentCheckModal' as const;
export const equipmentCheckModalToken = upgradeNg1Dependency(equipmentCheckModal);
