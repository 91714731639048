import { ActivityEditorController } from '../activity-editor.controller';

export interface Bindings {
	activityEditorController: ActivityEditorController;
	aiPrompts: any;
	activity: any;
	onPreview: () => void;
	onEdit: () => void;
	onRemove: () => void;
}

export class AiMarkerSetPanelController implements Bindings {

	// Bindings
	public activityEditorController: ActivityEditorController;
	public aiPrompts: any;
	public activity: any;
	public canPreview: boolean;
	public onPreview: () => void;
	public onEdit: () => void;
	public onRemove: () => void;

	public markers;

	/* @ngInject */
	constructor (
		private $scope: ng.IScope
	) {}

	public $onChanges (changes: ng.IOnChangesObject): void {
		if (changes.aiPrompts) {
			this.markers = this.aiPrompts.map((prompt) => {
				return prompt.tag;
			});
			this.safeDigest();
		}
	}

	private safeDigest () {
		// eslint-disable-next-line angular/no-private-call
		const phase = this.$scope.$root.$$phase || this.$scope.$$phase;
		if (!phase) {
			this.$scope.$digest();
		}
	}
}
