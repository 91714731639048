export enum AuthProviders {
	GOREACT = 'goreact',
	MACMILLAN = 'macmillan',
	GOOGLE = 'google',
	CLASSLINK = 'classlink'
}

export interface OpenIdConfiguration {
	id: number;
	display_name: string;
	logo_url: string;
	auth_provider?: AuthProvider
}

export interface AuthProvider {
	id: number;
	org_id?: number;
	auth_type: string;
	provider_name: string;
	is_web_app: boolean;
	open_id_configuration?: OpenIdConfiguration
}
