import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { CsvReportService, ReportConfig, ReportResponse } from '../csv-report.service';

export class OrgWideRubricReportService extends CsvReportService {
	public static readonly NG1_INJECTION_NAME = 'OrgWideRubricReportService' as const;

	/* @ngInject */
	constructor ($window: ng.IWindowService, private RubricTemplateModel: any) {
	  super($window);
	}

	public async requestCsvData (config: ReportConfig): Promise<ReportResponse> {
	  return await this.RubricTemplateModel.downloadReport(config).$promise;
	}

	public async execute (itemId: any, orgId: any, startDate?: Date, endDate?: Date): Promise<void> {
		const timezone = this.getUserTimezone();

		let start_date = null;
		let end_date = null;
		if (startDate) start_date = startDate.toISOString();
		if (endDate) end_date = endDate.toISOString();
		const { filename, rows } = await this.requestCsvData({
			id: itemId,
			org_id: orgId,
			timezone,
			start_date,
			end_date
		});
		const output = this.processReport(rows);
		const data = this.generateBlob(output);
		this.downloadCsv(data, filename);
	}
}
export const orgWideRubricReportToken = upgradeNg1Dependency(OrgWideRubricReportService);
