import {
	Directive,
	ElementRef,
	OnDestroy,
	Renderer2,
	ViewContainerRef,
	Injector,
	ComponentRef,
	AfterViewInit,
	ComponentFactoryResolver,
	Inject
} from '@angular/core';
import {
	Overlay,
	OverlayRef,
	OverlayConfig,
	ConnectedPosition
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { fromEvent, Subject } from 'rxjs';
import { PasswordRequirementsComponent } from './components/password-requirements.component';
import { takeUntil, tap } from 'rxjs/operators';

const SCREEN_SM_MIN = 768;

@Directive({
	selector: '[goPasswordRequirements]'
})
export class PasswordRequirementsDirective implements OnDestroy, AfterViewInit {
	private overlayRef: OverlayRef | null = null;
	private passwordInput: HTMLInputElement | null = null;
	private componentRef: ComponentRef<PasswordRequirementsComponent> | null = null;
	private characterCounterElement: HTMLElement | null = null;
	private inputWidth: number = null;

	private isSmallScreen: boolean = false;
	private inlineComponentRef: ComponentRef<PasswordRequirementsComponent> = null;
	private inlineContainer: HTMLElement | null = null;

	private destroy$: Subject<void> = new Subject<void>();

	constructor (
		private el: ElementRef,
		private renderer: Renderer2,
		private overlay: Overlay,
		private viewContainerRef: ViewContainerRef,
		private injector: Injector,
		private componentFactoryResolver: ComponentFactoryResolver,
		@Inject('Window') private window: Window
	) {}

	public ngAfterViewInit (): void {
		this.isSmallScreen = this.window.innerWidth < SCREEN_SM_MIN;

		this.passwordInput = this.el.nativeElement.querySelector('input[type="password"]');

		if (this.passwordInput) {
			this.createCharacterCounter();
			this.addEventListeners();
		}
	}

	public ngOnDestroy (): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.closeOverlay();
		this.removeInlineComponent();
	}

	private createCharacterCounter (): void {
		const matFormField = this.findAngularMaterialFormField(this.passwordInput);
		if (matFormField) {
			this.inputWidth = matFormField.getBoundingClientRect().width;
			this.addCustomCharacterCounter(matFormField);
		}
	}

	private addCustomCharacterCounter (formField: HTMLElement): void {
		const existingCounter = formField.querySelector('.password-char-counter');
		if (existingCounter) {
			this.characterCounterElement = existingCounter as HTMLElement;
			return;
		}

		this.characterCounterElement = this.renderer.createElement('div');
		this.renderer.addClass(this.characterCounterElement, 'password-char-counter');
		this.renderer.setStyle(this.characterCounterElement, 'font-size', '12px');
		this.renderer.setStyle(this.characterCounterElement, 'color', '#666'); // $color-dark-grey2

		const counterWrapper = this.renderer.createElement('div');
		this.renderer.setStyle(counterWrapper, 'width', '100%');
		this.renderer.setStyle(counterWrapper, 'display', 'flex');
		this.renderer.setStyle(counterWrapper, 'justify-content', 'flex-end');

		this.renderer.appendChild(counterWrapper, this.characterCounterElement);
		this.renderer.appendChild(formField, counterWrapper);

		this.updateCharacterCounter('');
	}

	private findAngularMaterialFormField (element: HTMLElement | null): HTMLElement | null {
		if (!element) return null;

		let parent = element.parentElement;
		while (parent) {
			if (parent.classList.contains('mat-form-field')) {
				return parent;
			}
			parent = parent.parentElement;
		}
		return null;
	}

	private updateCharacterCounter (value: string): void {
		if (this.characterCounterElement) {
			this.characterCounterElement.textContent = `${value.length} / 72`;
		}
	}

	private showInlineComponent (): void {
		if (this.inlineComponentRef) {
			return; // Already showing
		}

		const formField = this.findAngularMaterialFormField(this.passwordInput);
		if (!formField) return;

		this.inlineContainer = this.renderer.createElement('div');

		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
			PasswordRequirementsComponent
		);
		this.inlineComponentRef = this.viewContainerRef.createComponent(componentFactory);

		this.renderer.appendChild(this.inlineContainer, this.inlineComponentRef.location.nativeElement);
		// Insert container immediately after form field
		const parent = formField.parentElement;
		if (parent) {
			this.renderer.insertBefore(parent, this.inlineContainer, formField.nextSibling);
		}

		// Initialize component with current password
		if (this.passwordInput && this.inlineComponentRef) {
			this.inlineComponentRef.instance.validatePassword(this.passwordInput.value);
		}
	}

	private removeInlineComponent (): void {
		if (this.inlineContainer && this.inlineComponentRef) {
			this.inlineComponentRef.destroy();
			this.inlineComponentRef = null;

			if (this.inlineContainer.parentNode) {
				this.inlineContainer.parentNode.removeChild(this.inlineContainer);
			}
			this.inlineContainer = null;
		}
	}

	private addEventListeners (): void {
		if (this.passwordInput) {
			fromEvent(this.passwordInput, 'focus').pipe(
				takeUntil(this.destroy$),
				tap(() => {
					if (this.isSmallScreen) {
						this.showInlineComponent();
					} else {
						this.showOverlay();
					}
				})
			).subscribe();

			fromEvent(this.passwordInput, 'input').pipe(
				takeUntil(this.destroy$),
				tap((event: Event) => {
					const value = (event.target as HTMLInputElement).value;
					this.updateCharacterCounter(value);

					// update active components
					if (this.isSmallScreen && this.inlineComponentRef) {
						this.inlineComponentRef.instance.validatePassword(value);
					} else if (!this.isSmallScreen && this.componentRef) {
						this.componentRef.instance.validatePassword(value);
					}
				})
			).subscribe();

			// on blur, close desktop overlay
			fromEvent(this.passwordInput, 'blur').pipe(
				takeUntil(this.destroy$),
				tap(()=> {
					if (!this.isSmallScreen && this.overlayRef
						&& !this.overlayRef.overlayElement.contains(document.activeElement)
					) {
						this.closeOverlay();
					}
				})
			).subscribe();

			fromEvent(this.window, 'resize').pipe(
				takeUntil(this.destroy$),
				tap(()=> {
					const wasMobile = this.isSmallScreen;
					this.isSmallScreen = this.window.innerWidth < SCREEN_SM_MIN;

					// if device viewport changed, clean up and reinitialize
					if (wasMobile !== this.isSmallScreen) {
						this.closeOverlay();
						this.removeInlineComponent();
					}
				})
			).subscribe();
		}
	}

	private showOverlay (): void {
		if (this.overlayRef) {
			return; // Already showing
		}

		this.measureInputWidth();

		const config = this.getOverlayConfig();
		this.overlayRef = this.overlay.create(config);

		const portal = new ComponentPortal(PasswordRequirementsComponent, this.viewContainerRef, this.injector);
		this.componentRef = this.overlayRef.attach(portal);

		// init with empty password (change detection)
		if (this.passwordInput && this.componentRef) {
			this.componentRef.instance.validatePassword(this.passwordInput.value);
		}
	}

	private measureInputWidth (): void {
		if (!this.passwordInput) return;

		const matFormField = this.findAngularMaterialFormField(this.passwordInput);
		if (matFormField) {
			this.inputWidth = matFormField.getBoundingClientRect().width;
		}

		this.inputWidth = Math.max(300, this.inputWidth);
	}

	private getOverlayConfig (): OverlayConfig {
		const positions: ConnectedPosition[] = [
			// position below the input first (preferred)
			{
				originX: 'start',
				originY: 'bottom',
				overlayX: 'start',
				overlayY: 'top',
				offsetY: 50,
				offsetX: -10
			},
			// position above the input as fallback
			{
				originX: 'start',
				originY: 'top',
				overlayX: 'start',
				overlayY: 'bottom',
				offsetY: -30,
				offsetX: -10
			}
		];

		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(this.passwordInput)
			.withPositions(positions)
			.withFlexibleDimensions(false);

		let scrollStrategy;
		if (this.window.innerWidth < SCREEN_SM_MIN || this.window.innerHeight < SCREEN_SM_MIN) {
			// Small device: noop strategy to prevent auto-closing because iOS loves high jacking scroll events
			scrollStrategy = this.overlay.scrollStrategies.noop();
			positionStrategy.withPush(false); // whether the overlay can be pushed on-screen if none of the provided positions fit
		} else {
			// Desktop: reposition on scroll
			scrollStrategy = this.overlay.scrollStrategies.reposition();
			positionStrategy.withPush(true);
		}

		return {
			positionStrategy,
			hasBackdrop: false,
			disposeOnNavigation: true,
			scrollStrategy,
			minWidth: this.inputWidth,
			maxWidth: this.inputWidth
		};
	}

	private closeOverlay (): void {
		if (this.overlayRef) {
			this.overlayRef.dispose();
			this.overlayRef = null;
			this.componentRef = null;
		}
	}
}
