import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GRID_CONSTANTS } from 'ngx/go-modules/src/components/grid-virtual-scroll-viewport/grid.constants';
import { ArchivedListDataSource } from 'ngx/go-modules/src/services/archive';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'archive-grid-view',
	template: require('./archive-grid-view.component.html'),
	styles: [require('./archive-grid-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxArchiveGridViewComponent implements OnInit, OnDestroy {
	public minWidth: number = GRID_CONSTANTS.minWidth;
	public maxWidth: number = GRID_CONSTANTS.maxWidth;
	public minGap: number = GRID_CONSTANTS.minGap;
	private readonly componentDestroyed$ = new Subject<void>();

	constructor (public archiveListDataSource: ArchivedListDataSource) {}

	public ngOnInit () {
		this.archiveListDataSource.onScrollLimit$.pipe(takeUntil(this.componentDestroyed$)).subscribe();
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$.next();
		this.componentDestroyed$.complete();
	}
}
