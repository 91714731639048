import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { Masquerade, masqueradeToken } from 'go-modules/masquerade/masquerade.service';
import dayjs from 'dayjs';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import {
	ApiGetTourResponse,
	HttpTourService
} from 'ngx/go-modules/src/services/http-tour/http-tour.service';
import {
	GLOBAL_PULSE_TOUR_CLASSES,
	GLOBAL_PULSE_TOUR_STYLE_ID
} from 'ngx/go-modules/src/services/go-pulse-tour/constants/go-pulse-tour';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';

export interface PulseTourConfig {
	viewTrackKey: string;
	steps: PulseTourStep[];
	constraints: {
		/**
		 * The date the tour is "launched" on the site
		 * If `isOnboarding` is true,  all users created after this date will see the tour
		 * If `isOnboarding` is false, all users created before this date will see the tour
		 * DO NOT SET THE DATE FAR IN THE PAST AND SET `isOnboarding` to TRUE
		 */
		tourStartDate: Date;
		/**
		 * Set to true for tours that should be seen by new signups only
		 * Set to false to show new product features to existing users
		 */
		isOnboarding: boolean;
	};
}

export interface PulseTourStep {
	completeSelectors: string[];
	pulse?: {
		selectors: string[];
		stylePadding?: StylePadding;
	};
	newBadgeSelectors?: string[];
}

interface StylePadding {
	// pixels
	top?: number;
	right?: number;
	bottom?: number;
	left?: number;
}

type MatchingElement = Omit<MatchedElement, 'cleanup'>;

interface MatchedElement {
	element: HTMLElement;
	cleanup: () => void;
	selector: string;
}

interface ElementTracker {
	type: 'pulse' | 'badge' | 'complete';
	selectors: string[];
	matchedElements: MatchedElement[]; // matches at most one element for each selector
	stylePadding?: StylePadding; // applies to all matching pulse elements on current step
}

interface TourStepTracker {
	elementTrackers: ElementTracker[];
}

export interface ActiveTour {
	config: PulseTourConfig;
	currentStepIndex: number;
	stepTrackers: TourStepTracker[]; // actively tracks elements based on the current step index
}

@Injectable({
	providedIn: 'root'
})
export class GoPulseTourService implements OnDestroy {
	private readonly observerConfig: MutationObserverInit = {
		childList: true,
		subtree: true,
		attributes: false,
		characterData: false
	};
	private renderer: Renderer2;
	private activeTours: Map<string, ActiveTour> = new Map();
	private inFlightTours = new Set<string>();
	public observer: MutationObserver | null = null;

	constructor (
		rendererFactory: RendererFactory2,
		private httpTourService: HttpTourService,
		private translate: TranslateService,
		private featureFlagService: NgxFeatureFlagService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(masqueradeToken) private masquerade: Masquerade,
		@Inject('Window') private window: Window,
		@Inject(DOCUMENT) private document: Document
	) {
		this.renderer = rendererFactory.createRenderer(null, null);

		if (this.featureFlagService.isAvailable('PULSE_TOUR')) {
			this.injectStyles();
		}
	}

	public cleanupObserver (): void {
		if (this.observer) {
			this.observer.disconnect();
			this.observer = null;
		}
	}

	public ngOnDestroy (): void {
		this.cleanupObserver();
	}

	public startTour (config: PulseTourConfig): Observable<ApiGetTourResponse> {
		if (!this.featureFlagService.isAvailable('PULSE_TOUR')) {
			return throwError(() => new Error('Pulse tour feature flag is not enabled'));
		}

		if (this.inFlightTours.has(config.viewTrackKey)) {
			return throwError(() => new Error('Tour is in flight'));
		}

		const existingTour = this.activeTours.get(config.viewTrackKey);
		if (existingTour) {
			this.cleanupTour(config.viewTrackKey);
		}

		// Lock the tour until async checks are complete
		this.inFlightTours.add(config.viewTrackKey);

		return this.canTourBeViewed(config).pipe(
			finalize(() => this.inFlightTours.delete(config.viewTrackKey)),
			tap(() => {
				const stepTrackers: TourStepTracker[] = this.createTrackersForEachStep(config);
				const tour: ActiveTour = {
					config,
					currentStepIndex: -1,
					stepTrackers
				};
				this.activeTours.set(config.viewTrackKey, tour);
				requestAnimationFrame(() => this.nextStep(config.viewTrackKey));
			})
		);
	}

	public cleanupTour (tourKey: string): void {
		const tour = this.activeTours.get(tourKey);
		if (!tour) return;

		// clean up current step trackers
		if (tour.currentStepIndex >= 0 && tour.currentStepIndex < tour.stepTrackers.length) {
			const currentStep = tour.stepTrackers[tour.currentStepIndex];
			currentStep.elementTrackers.forEach((stepElement) => {
				stepElement.matchedElements.forEach(({ cleanup }) => cleanup());
				stepElement.matchedElements = [];
			});
		}

		this.activeTours.delete(tourKey);
		this.inFlightTours.delete(tourKey);

		if (this.activeTours.size === 0) {
			this.cleanupObserver();
		}
	}

	private createTrackersForEachStep (config: PulseTourConfig): TourStepTracker[] {
		return config.steps.map((step: PulseTourStep) => {
			const elementTrackers: ElementTracker[] = [];

			if (step.pulse?.selectors) {
				elementTrackers.push({
					type: 'pulse',
					selectors: step.pulse.selectors,
					matchedElements: [],
					stylePadding: step.pulse.stylePadding
				});
			}

			if (step.newBadgeSelectors) {
				elementTrackers.push({
					type: 'badge',
					selectors: step.newBadgeSelectors,
					matchedElements: []
				});
			}

			if (step.completeSelectors) {
				elementTrackers.push({
					type: 'complete',
					selectors: step.completeSelectors,
					matchedElements: []
				});
			}

			return { elementTrackers };
		});
	}

	private canTourBeViewed (config: PulseTourConfig): Observable<ApiGetTourResponse> {
		if (!this.validateSteps(config.steps)) {
			return throwError(() => new Error('Invalid tour step configuration'));
		}

		if (this.masquerade.isMasked()) {
			return throwError(() => new Error('Tour not shown to masked users'));
		}

		const userCreatedDate = dayjs.utc(this.userService.currentUser.created);
		const tourStartDate = dayjs.utc(config.constraints.tourStartDate);

		if (config.constraints.isOnboarding) {
			if (userCreatedDate.isBefore(tourStartDate)) {
				return throwError(() => new Error('User was created before onboarding start date'));
			}
		} else {
			if (userCreatedDate.isAfter(tourStartDate)) {
				return throwError(() => new Error('User was created after this tour feature was added'));
			}
		}

		return this.httpTourService.getTour(config.viewTrackKey).pipe(
			map((tourData) => {
				if (tourData?.id) {
					throw new Error('User has seen the tour');
				}
				return tourData;
			})
		);
	}

	private validateSteps (steps: PulseTourStep[]): boolean {
		return steps.every(
			(step) => step.completeSelectors.length && (step.pulse?.selectors.length || step.newBadgeSelectors.length)
		);
	}

	private nextStep (tourKey: string): void {
		const tour = this.activeTours.get(tourKey);
		if (!tour) return;

		// clean up current step trackers
		if (tour.currentStepIndex >= 0 && tour.currentStepIndex < tour.stepTrackers.length) {
			const currentStep = tour.stepTrackers[tour.currentStepIndex];
			currentStep.elementTrackers.forEach((elementTracker) => {
				elementTracker.matchedElements.forEach(({ cleanup }) => cleanup());
				elementTracker.matchedElements = [];
			});
		}

		tour.currentStepIndex++;

		if (tour.currentStepIndex >= tour.stepTrackers.length) {
			this.httpTourService.viewTour(tour.config.viewTrackKey).subscribe({
				complete: () => this.cleanupTour(tour.config.viewTrackKey)
			});
			return;
		}

		// immediately attempt to attach elements to the DOM
		this.attachTourElements(tour, this.document.body);

		if (!this.observer) {
			this.observer = new this.window.MutationObserver((mutations) => {
				if (!this.activeTours.size) return;
				this.processMutations(mutations);
			});
			this.observer.observe(this.document.body, this.observerConfig);
		}
	}

	private processMutations (mutations: MutationRecord[]): void {
		mutations.forEach((mutation) => {
			if (mutation.type === 'childList') {
				requestAnimationFrame(() => {
					mutation.removedNodes.forEach((node) => {
						if (node instanceof HTMLElement) {
							this.activeTours.forEach((tour) => this.detachTourElements(tour, node));
						}
					});
					mutation.addedNodes.forEach((node) => {
						if (node instanceof HTMLElement) {
							this.activeTours.forEach((tour) => this.attachTourElements(tour, node));
						}
					});
				});
			}
		});
	}

	private detachTourElements (tour: ActiveTour, node: HTMLElement): void {
		if (tour.currentStepIndex < 0 || tour.currentStepIndex >= tour.stepTrackers.length) {
			return;
		}

		const step = tour.stepTrackers[tour.currentStepIndex];
		step.elementTrackers.forEach((stepElement) => {
			stepElement.matchedElements = stepElement.matchedElements.filter(({ element, cleanup }) => {
				if (node === element || node.contains(element)) {
					cleanup();
					return false;
				}
				return true;
			});
		});
	}

	private attachTourElements (tour: ActiveTour, node: HTMLElement): void {
		if (tour.currentStepIndex < 0 || tour.currentStepIndex >= tour.stepTrackers.length) {
			return;
		}

		const step = tour.stepTrackers[tour.currentStepIndex];
		step.elementTrackers.forEach((elementTracker: ElementTracker) => {
			// clean up any elements in the current step that are no longer in the DOM
			elementTracker.matchedElements = elementTracker.matchedElements.filter(({ element, cleanup }) => {
				if (!this.document.contains(element)) {
					cleanup();
					return false;
				}
				return true;
			});
			const matchedSelectors = new Set(elementTracker.matchedElements.map((element) => element.selector));

			this.findMatchingElements(node, elementTracker.selectors).forEach(({ element, selector }) => {
				// skip if element is already matched
				if (!matchedSelectors.has(selector)) {
					const cleanup = this.createElementAndCleanupHandler(
						tour.config.viewTrackKey,
						element,
						elementTracker.type,
						{ padding: elementTracker.stylePadding }
					);
					elementTracker.matchedElements.push({
						element,
						cleanup,
						selector
					});
				}
			});
		});
	}

	private findMatchingElements (node: HTMLElement, selectors: string[]): MatchingElement[] {
		const matches: MatchingElement[] = [];

		// For each selector, find exactly one match. Do not use "querySelectorAll" since that messes up specificity
		for (const selector of selectors) {
			// node itself matches
			if (node.matches(selector)) {
				matches.push({ element: node, selector });
				continue;
			}

			// first matching element within node
			const found = node.querySelector(selector);
			if (found instanceof HTMLElement) {
				matches.push({ element: found, selector });
			}
		}

		return matches;
	}

	/**
	 * Perform a DOM operation and return a cleanup handler
	 */
	private createElementAndCleanupHandler (
		tourKey: string,
		element: HTMLElement,
		type: 'pulse' | 'badge' | 'complete',
		config: { padding?: StylePadding }
	): () => void {
		switch (type) {
			case 'pulse': {
				const pulseDiv = this.createPulseElement(element, config.padding);
				return () => {
					if (pulseDiv && pulseDiv.parentNode) {
						this.renderer.removeChild(pulseDiv.parentNode, pulseDiv);
					}
				};
			}

			case 'badge': {
				const badge = this.createBadgeElement(element);
				return () => {
					if (badge && badge.parentNode) {
						this.renderer.removeChild(badge.parentNode, badge);
					}
				};
			}

			case 'complete': {
				const handleClick = () => this.nextStep(tourKey);
				const handleKeydown = (e: KeyboardEvent) => {
					if (e.key === 'Enter') {
						setTimeout(() => this.nextStep(tourKey), 0);
					}
				};

				element.addEventListener('click', handleClick);
				element.addEventListener('keydown', handleKeydown);

				return () => {
					element.removeEventListener('click', handleClick);
					element.removeEventListener('keydown', handleKeydown);
				};
			}
		}
	}

	private createPulseElement (element: HTMLElement, padding?: StylePadding): HTMLElement {
		const existingPulse = element.querySelector(`.${GLOBAL_PULSE_TOUR_CLASSES.PULSE}`);
		if (existingPulse) {
			return existingPulse as HTMLElement;
		}

		const computedStyle = this.window.getComputedStyle(element);

		// setting the position of an arbitrary element to "relative" is not safe
		// it's better to break the tour rather than ruin the UI element its targeting
		if (computedStyle.position === 'static') {
			this.renderer.setStyle(element, 'position', 'relative');
		}

		this.renderer.setAttribute(
			element,
			'aria-label',
			this.translate.instant('go-pulse-tour-service_new-feature-aria-label')
		);
		this.renderer.setAttribute(element, 'aria-live', 'polite');

		const pulseElement = this.renderer.createElement('div');
		this.renderer.addClass(pulseElement, `${GLOBAL_PULSE_TOUR_CLASSES.PULSE}`);
		this.renderer.setStyle(pulseElement, 'position', 'absolute');
		this.renderer.setStyle(pulseElement, 'border-radius', computedStyle.borderRadius);

		const topPadding = padding?.top ?? 0;
		const leftPadding = padding?.left ?? 0;
		const bottomPadding = padding?.bottom ?? 0;
		const rightPadding = padding?.right ?? 0;

		this.renderer.setStyle(pulseElement, 'top', `${topPadding}px`);
		this.renderer.setStyle(pulseElement, 'left', `${leftPadding}px`);
		this.renderer.setStyle(pulseElement, 'width', `calc(100% - ${leftPadding + rightPadding}px)`);
		this.renderer.setStyle(pulseElement, 'height', `calc(100% - ${topPadding + bottomPadding}px)`);
		this.renderer.appendChild(element, pulseElement);
		// make visible in next frame after styles are applied
		requestAnimationFrame(() => {
			this.renderer.addClass(pulseElement, 'visible');
		});

		return pulseElement;
	}

	private createBadgeElement (element: HTMLElement): HTMLElement {
		const existingBadge = element.querySelector(`.${GLOBAL_PULSE_TOUR_CLASSES.BADGE}`);
		if (existingBadge) {
			return existingBadge as HTMLElement;
		}
		const textContent = this.translate.instant('common_new').toUpperCase();
		const ariaLabel = this.translate.instant('go-pulse-tour-service_new-feature-aria-label');
		const badge = this.renderer.createElement('span');
		this.renderer.addClass(badge, GLOBAL_PULSE_TOUR_CLASSES.BADGE);
		this.renderer.setProperty(badge, 'textContent', textContent);
		this.renderer.setAttribute(badge, 'aria-label', ariaLabel);
		this.renderer.appendChild(element, badge);
		return badge;
	}

	private injectStyles (): void {
		if (this.document.querySelector(`#${GLOBAL_PULSE_TOUR_STYLE_ID}`)) return;
		const styles = `
			  .${GLOBAL_PULSE_TOUR_CLASSES.PULSE} {
				pointer-events: none;
				z-index: 1;
				border: 2px solid #00C3B5;
				animation: ${GLOBAL_PULSE_TOUR_CLASSES.ANIMATION} 2s infinite steps(60);
				opacity: 0;
				transition: opacity 150ms ease-in-out;
				will-change: opacity;
			  }
			  .${GLOBAL_PULSE_TOUR_CLASSES.PULSE}.visible {
				opacity: 1;
			  }
			  @keyframes ${GLOBAL_PULSE_TOUR_CLASSES.ANIMATION} {
				0% {
				  box-shadow: 0 0 0 0px rgba(0, 240, 222, 0.4);
				}
				100% {
				  box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
				}
			  }
			  .${GLOBAL_PULSE_TOUR_CLASSES.BADGE} {
				display: inline-flex;
				align-items: center;
				background-color: var(--color-secondary-ultra-light);
				color: #333;
				padding: 2px 6px;
				border-radius: 3px;
				font-size: 12px;
				font-weight: bold;
				margin-left: 8px;
			  }
		`;
		const styleElement = this.document.createElement('style');
		styleElement.id = GLOBAL_PULSE_TOUR_STYLE_ID;
		styleElement.textContent = styles;
		this.document.head.appendChild(styleElement);
	}
}
