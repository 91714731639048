import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NetworkIndicatorComponent } from './network-indicator.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		MatTooltipModule
	],
	declarations: [
		NetworkIndicatorComponent
	],
	entryComponents: [
		NetworkIndicatorComponent
	],
	exports: [
		NetworkIndicatorComponent
	]
})
export class NetworkIndicatorModule {}
