import { Component, OnInit } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { HttpClient } from '@angular/common/http';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { HeyGenPromptCreatorComponent } from './hey-gen-prompt-creator/hey-gen-prompt-creator.component';

export interface HeyGenPrompt {
	promptName: string;
	aiRole: string;
	finalPrompt: string;
	startPrompt: string;
	mainPrompt: string;
	humanRole: string;
	context: string;
	index: number;
	activityIds?: number[];
}

@Component({
	selector: 'feature-flag-dialog',
	template: require('./hey-gen-prompt-editor.component.html'),
	styles: [require('./hey-gen-prompt-editor.component.scss')]
})
export class HeyGenPromptEditorComponent implements OnInit {

	public prompts: HeyGenPrompt[] = null;

	constructor (
		public dialogRef: GoDialogRef,
		public http: HttpClient,
		private modal: GoModalService
	) {}

	public ngOnInit (): void {
		this.http.get('/api/v2/heygen/prompts')
			.subscribe((response: HeyGenPrompt[]) => {
				response.forEach((prompt, i) => prompt.index = i);
				this.prompts = response;
			});
	}

	public remove (prompt) {
		const index = this.prompts.findIndex((p) => p.index === prompt.index);
		this.prompts.splice(index, 1);
		this.updatePrompts().subscribe();
	}

	public edit (prompt) {
		this.modal.open(HeyGenPromptCreatorComponent, false, {
			data: {
				prompt
			}
		}).afterClosed().subscribe((prompt: HeyGenPrompt) => {
			if (prompt) {
				const index = this.prompts.findIndex((p) => p.index === prompt.index);
				prompt.activityIds = prompt.activityIds || [];
				this.prompts[index] = prompt;
			}
		});
	}

	public create () {
		this.modal.open(HeyGenPromptCreatorComponent, false, {
			data: {}
		}).afterClosed().subscribe((prompt: HeyGenPrompt) => {
			prompt.index = this.prompts.length;
			prompt.activityIds = [];
			this.prompts.push(prompt);
		});
	}

	public cancel () {
		this.dialogRef.close();
	}

	public save () {
		this.updatePrompts().subscribe(() => {
			this.dialogRef.close();
		});
	}

	public updatePrompts () {
		return this.http.post('/api/v2/heygen/prompts', {
			prompts: this.prompts
		});
	}
}
