import angular, { noop } from 'angular';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { SessionEditorPanelService, sessionEditorPanelServiceToken } from 'go-modules/session-editor-panel/session-editor-panel.service';
import { SessionListDataSource } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import { sessionToken, Session as SessionModel } from 'go-modules/models/session/session.service';
import { NgxSessionService } from 'ngx/go-modules/src/services/session/session.service';
import { ConfirmDialogComponent, ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';
import { ArchivedListDataSource } from 'ngx/go-modules/src/services/archive';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { VideoShareDialogService } from 'ngx/go-modules/src/components/dialogs/video-share-dialog/video-share-dialog.service';

@Component({
	selector: 'session-menu',
	template: require('./session-menu.component.html'),
	styles: [require('./session-menu.component.scss')],
	providers: [DatePipe]
})
export class SessionMenuComponent {
	@Input() public session;

	constructor (
		public ngxSessionService: NgxSessionService,
		public sessionListDataSource: SessionListDataSource,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(sessionToken) private Session: ReturnType<typeof SessionModel>,
		@Inject(sessionEditorPanelServiceToken) private sessionEditorPanel: SessionEditorPanelService,
		private cdr: ChangeDetectorRef,
		private dialog: MatDialog,
		private translate: TranslateService,
		private activityListDataSource: ActivityListDataSource,
		private archiveListDatasource: ArchivedListDataSource,
		private ngxGoToastService: NgxGoToastService,
		private videoShareDialogService: VideoShareDialogService
	) {}

	public onEdit (activeTab = 0) {
		const sessionCopy = angular.copy(this.session);

		this.sessionEditorPanel.open({
			session: sessionCopy,
			user: this.userService.currentUser,
			group: this.selectedService.getGroup(),
			activity: this.selectedService.getActivity(),
			activeTab
		})
			.result
			.then((result) => {
				if (result) {
					const session = this.Session.model(result);
					this.sessionListDataSource.editSession(session);
					this.cdr.markForCheck();
				 }
			})
			.catch(noop);
	}

	public shareSession () {
		this.videoShareDialogService.open({
			orgSettings: this.selectedService.getOrg().org_settings,
			sessionId: this.session.session_id
		}).subscribe((res) => {
			if (res && (['invalidEmails', 'invalidUserNames', 'invalidEmailsAndUserNames'].includes(res.error) || res.openEditUserPanel)) {
				this.onEdit(1);
			}
		});
	}

	public confirmArchiveSession () {
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translate.instant('common_archive'),
				message: this.translate.instant('session-archive_message'),
				confirmText: this.translate.instant('common_archive')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().subscribe((confirm) => {
			if (confirm) {
				this.archiveSession();
			}
		});
	}

	public archiveSession () {
		this.ngxSessionService.archive(this.session).subscribe({
			next: () => {
				this.sessionListDataSource.removeSession(this.session);
				this.archiveListDatasource.add(this.session);

				const activity = this.selectedService.getActivity();
				// TODO DEV-15860
				if (!this.session.viewed && activity.unviewed_sessions !== null) {
					activity.unviewed_sessions--;
				}
				if (activity.usage !== null) {
					activity.usage--;
				}
				this.activityListDataSource.editActivity(activity);

				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'session-archive_success'
				});
			},
			error: () => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'modal-remove-session_cannot-delete-session'
				});
			}
		});
	}

	public shouldShowRetakeLink (): boolean {
		return this.selectedService.getActivity().has_single_recording_attempt
			&& !this.session.started_at
			&& !this.session.archived_at
			&& this.ngxSessionService.mayDelete(this.session);
	}
}
