import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { DashboardNavigationComponent } from './dashboard-navigation/dashboard-navigation.component';
import { AlphabeticalByKeyPipeModule } from 'ngx/go-modules/src/pipes/alphabetical/alphabetical-by-key-pipe.module';
import { DashboardComponent } from './dashboard.component';
import { PWAInstallPromptModule } from 'ngx/dashboard/src/components/pwa-install-prompt/pwa-install-prompt.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomParamsDialogModule } from 'ngx/go-modules/src/components/dialogs/custom-params-dialog/custom-params-dialog.module';
import { RateLimitInterceptor } from 'ngx/go-modules/src/services/interceptors/rate-limit-interceptor/rate-limit-interceptor.service';
import { CustomerSurveySnackBarModule } from 'ngx/go-modules/src/components/snack-bars/customer-survey/customer-survey-snack-bar.module';
import { AiTrialSurveySnackBarModule } from 'ngx/go-modules/src/components/snack-bars/ai-trial-survey/ai-trial-survey-snack-bar.module';
import { SurveySnackBarModule } from 'ngx/go-modules/src/components/snack-bars/survey/survey-snack-bar.module';
import { DraftTourSnackBarModule } from 'ngx/go-modules/src/components/snack-bars/draft-tour/draft-tour-snack-bar.module';
import { HeyGenPromptEditorComponentModule } from '../hey-gen-prompt-editor/hey-gen-prompt-editor.module';
import { FeatureFlagDialogComponentModule } from 'ngx/go-modules/src/components/dialogs/feature-flag-dialog/feature-flag-dialog.module';
import { FeaturedMessageModule } from 'ngx/go-modules/src/components/featured-message/featured-message.module';
import { DownloadPWADialogModule } from '../dialogs/download-pwa';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		BreadcrumbsModule,
		AlphabeticalByKeyPipeModule,
		PWAInstallPromptModule,
		HttpClientModule,
		CustomParamsDialogModule,
		CustomerSurveySnackBarModule,
		AiTrialSurveySnackBarModule,
		SurveySnackBarModule,
		DraftTourSnackBarModule,
		HeyGenPromptEditorComponentModule,
		FeatureFlagDialogComponentModule,
		FeaturedMessageModule,
		DownloadPWADialogModule
	],
	declarations: [
		DashboardComponent,
		DashboardHeaderComponent,
		DashboardNavigationComponent
	],
	entryComponents: [
		DashboardComponent,
		DashboardHeaderComponent,
		DashboardNavigationComponent
	],
	exports: [
		DashboardComponent,
		DashboardHeaderComponent,
		DashboardNavigationComponent
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: RateLimitInterceptor, multi: true }
	]
})
export class DashboardModule {}
