import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'ngx-show-password-button',
	template: require('./show-password-button.component.html'),
	styles: [require('./show-password-button.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowPasswordButtonComponent {
	@Input() public passwordHidden: boolean;
	@Output() public onClick: EventEmitter<void> = new EventEmitter();

	constructor (
		private translateService: TranslateService
	) {}

	public getTooltipText () {
		if (this.passwordHidden) {
			return this.translateService.instant('common_password-visibility-show');
		}

		return this.translateService.instant('common_password-visibility-hide');
	}
}
