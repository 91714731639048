import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FeaturedMessageComponent } from './featured-message.component';
import {
	AiTrialCourseSelectorDialogModule
} from 'ngx/go-modules/src/components/dialogs/ai-trial-course-selector-dialog/ai-trial-course-selector-dialog.module';
import { AiTrialSuccessDialogModule } from 'ngx/go-modules/src/components/dialogs/ai-trial-success-dialog/ai-trial-success-dialog.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		AiTrialSuccessDialogModule,
		AiTrialCourseSelectorDialogModule
	],
	declarations: [
		FeaturedMessageComponent
	],
	exports: [
		FeaturedMessageComponent
	],
	entryComponents: [
		FeaturedMessageComponent
	]
})
export class FeaturedMessageModule {}
