export enum ArchiveFilter {
	FOLDER = 'folder',
	ACTIVITY = 'activity',
	VIDEO = 'video'
}

export const ArchiveFilterTranslations: Record<ArchiveFilter, string> = {
	folder: 'common_course',
	activity: 'common_activity',
	video: 'common_video'
};
