import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { InstructionsPreviewDialogComponent } from './instructions-preview-dialog.component';
import { CommonModule } from '@angular/common';
import { RichInstructionsModule } from '../../rich-instructions/rich-instructions.module';
import { A11yModule } from '@angular/cdk/a11y';
@NgModule({
	imports: [
		TranslateModule,
		CommonModule,
		RichInstructionsModule,
		A11yModule
	],
	declarations: [
		InstructionsPreviewDialogComponent
	],
	exports: [
		InstructionsPreviewDialogComponent
	],
	entryComponents: [
		InstructionsPreviewDialogComponent
	]
})
export class InstructionsPreviewDialogModule {}
