import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GoToastModule } from 'ngx/go-modules/src/components/go-toast/go-toast.module';
import { NgxRichTextEditorModule } from 'ngx/go-modules/src/components/rich-text-editor/rich-text-editor.module';
import { NgxFeedbackCreatorComponent } from './feedback-creator.component';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { FormatTimeModule } from 'ngx/go-modules/src/pipes/format-time/format-time.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MarkerModule } from 'ngx/go-modules/src/components/marker';
import { NgxMediaThumbnailModule } from '../../media-thumbnail/media-thumbnail.module';
import { A11yModule } from '@angular/cdk/a11y';
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		TextFieldModule,
		GoToastModule,
		GoMaterialModule,
		GoTooltipModule,
		NgxRichTextEditorModule,
		FormatTimeModule,
		PickerModule,
		MarkerModule,
		NgxMediaThumbnailModule,
		A11yModule
	],
	declarations: [
		NgxFeedbackCreatorComponent
	],
	entryComponents: [
		NgxFeedbackCreatorComponent
	],
	exports: [
		NgxFeedbackCreatorComponent
	],
	providers: []
})
export class FeedbackCreatorModule {}
