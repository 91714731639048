export interface ReportResponse {
	filename: string;
	rows: string[][];
	data?: any;
	headers?: any;
}
export interface ReportConfig {
	id?: number;
	activity_id?: number|string;
	report_type?: string;
	group_id?: number|string;
	org_id?: number;
	timezone?: string;
	start_date?: string;
	end_date?: string;
}

export abstract class CsvReportService {

	/* @ngInject */
	constructor (private $window: ng.IWindowService) {}

	public processReport (rows: (string|number)[][]): string {
		const CARRIAGE_RETURN = '\r\n';
		let output = '';

		rows.forEach((row) => {
			output += row.map((item) => {
				if (item === null || item === undefined) return;
				// Only add quotes to strings
				if (Object.prototype.toString.call(item) === '[object String]') {
					// Add a second quotation mark to any instance of a quotation mark to escape it.
					// (RFC-4180.2.7: https://datatracker.ietf.org/doc/html/rfc4180#section-2)
					item = (item as string).replace(/["]/g, '"$&');
				}
				return '"' + item + '"';
			}).join(',');
			output += CARRIAGE_RETURN;
		});

		return output;
	}

	public generateBlob (output: string): Blob {
		return new Blob([ output ], { type: 'text/csv;charset=utf-8' });
	}

	public downloadCsv (data: Blob, filename: string): void {
		const anchor = this.$window.document.createElement('a');

		anchor.download = filename;
		anchor.href = this.$window.URL.createObjectURL(data);
		setTimeout(() => this.$window.URL.revokeObjectURL(anchor.href), 4E4); // 40s
		setTimeout(() => anchor.click());
	}

	public getUserTimezone () {
		return new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	public abstract requestCsvData (config: ReportConfig): Promise<ReportResponse>;
}
