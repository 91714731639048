import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DownloadPWADialogComponent } from './download-pwa-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	entryComponents: [
		DownloadPWADialogComponent
	],
	declarations: [
		DownloadPWADialogComponent
	]
})
export class DownloadPWADialogModule {}
