
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { UserSearchDialogModule } from 'ngx/go-modules/src/components/dialogs/user-search-dialog/user-search-dialog.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuperBarComponent } from './super-bar.component';
import { OrgPickerModule } from 'ngx/go-modules/src/components/org-picker/org-picker.module';
import { FolderMenuModule } from 'ngx/go-modules/src/components/menus/folder-menu/folder-menu.module';
import { MenuButtonModule } from 'ngx/go-modules/src/components/menus/menu-button/menu-button.module';
import { AccountMenuModule } from 'ngx/go-modules/src/components/menus/account-menu/account-menu.module';
import { OrgMenuModule } from 'ngx/go-modules/src/components/menus/org-menu/org-menu.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		OrgPickerModule,
		FolderMenuModule,
		AccountMenuModule,
		OrgMenuModule,
		MenuButtonModule,
		UserSearchDialogModule
	],
	declarations: [
		SuperBarComponent
	],
	exports: [
		SuperBarComponent
	],
	entryComponents: [
		SuperBarComponent
	]
})
export class SuperBarModule {}
