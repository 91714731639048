import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { FilterViewLayoutModule } from 'ngx/go-modules/src/components/filter-view-layout/filter-view-layout.module';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { NgxFolderTableViewComponent } from './folders-table-view/folders-table-view.component';
import { NgxFoldersViewComponent } from './folders-view.component';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';
import {
	FoldersZeroStateComponent
} from 'ngx/dashboard/src/components/folders-view/folders-zero-state/folders-zero-state.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GoSelectModule } from 'ngx/go-modules/src/directives/go-select/go-select.module';
import {
	DataSourceZeroStateModule
} from 'ngx/go-modules/src/components/data-source-zero-state/data-source-zero-state.module';
import { DotIndicatorModule } from 'ngx/go-modules/src/components/dot-indicator/dot-indicator.module';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { FoldersGridViewModule } from 'ngx/dashboard/src/components/folders-view/folders-grid-view/folders-grid-view.module';
import { OutsideClickModule } from 'ngx/go-modules/src/directives/outside-click';
import { MenuModule } from 'ngx/go-modules/src/components/menus/menu/menu.module';
import { CommentIndicatorModule } from 'ngx/go-modules/src/components/comment-indicator/comment-indicator.module';
import { AlphabeticalByKeyPipeModule } from 'ngx/go-modules/src/pipes/alphabetical/alphabetical-by-key-pipe.module';
import { OrgContextMenuModule } from 'ngx/dashboard/src/components/folders-view/org-context-menu/org-context-menu.module';
import { FolderMenuModule } from 'ngx/go-modules/src/components/menus/folder-menu/folder-menu.module';
import { FolderCardModule } from 'ngx/dashboard/src/components/folders-view/folder-card/folder-card.module';
import { BadgeModule } from 'ngx/go-modules/src/components/badge/badge.module';

@NgModule({
	imports: [
		CommonModule,
		FilterViewLayoutModule,
		TranslateModule,
		GoMaterialModule,
		NgxZeroStateModule,
		FolderMenuModule,
		ReactiveFormsModule,
		GoSelectModule,
		DataSourceZeroStateModule,
		DotIndicatorModule,
		FolderCardModule,
		GoTooltipModule,
		FoldersGridViewModule,
		OutsideClickModule,
		MenuModule,
		CommentIndicatorModule,
		AlphabeticalByKeyPipeModule,
		OrgContextMenuModule,
		BadgeModule
	],
	declarations: [
		NgxFoldersViewComponent,
		NgxFolderTableViewComponent,
		FoldersZeroStateComponent
	],
	entryComponents: [
		NgxFoldersViewComponent,
		NgxFolderTableViewComponent,
		FoldersZeroStateComponent
	],
	exports: [
		NgxFoldersViewComponent,
		NgxFolderTableViewComponent,
		FoldersZeroStateComponent
	],
	providers: [
		TranslatePipe
	]
})
export class NgxFoldersViewModule {}
