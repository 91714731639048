import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { goPasswordValidator, MULTIBYTE_MAX_LENGTH } from 'ngx/go-modules/src/form-validator/go-password/go-password.validator';

interface PasswordRequirement {
	text: string;
	fulfilled: boolean;
	required: boolean;
}

export const MIN_OPTIONAL_REQUIREMENTS = 3;

@Component({
	selector: 'go-password-requirements',
	template: require('./password-requirements.component.html'),
	styles: [require('./password-requirements.component.scss')],
	changeDetection: ChangeDetectionStrategy.Default
})
export class PasswordRequirementsComponent implements OnInit {
	public requirements: PasswordRequirement[] = [
		{ text: 'password-requirements_lowercase', fulfilled: false, required: true },
		{ text: 'password-requirements_uppercase', fulfilled: false, required: true },
		{ text: 'password-requirements_number', fulfilled: false, required: true },
		{ text: 'password-requirements_special', fulfilled: false, required: true }
	];

	public lengthRequirement = {
		text: 'password-requirements_length-requirement',
		fulfilled: false
	};

	public strengthPercentage = 0;
	public passwordLength = 0;

	private dummyControl = new FormControl('');
	private passwordValidator = goPasswordValidator();

	// individual requirement checks similar to password validator
	private readonly PATTERNS = {
		lowercase: /[a-z]/,
		uppercase: /[A-Z]/,
		number: /\d/,
		symbol: /\W/
	};

	constructor (private cdr: ChangeDetectorRef) {}

	public ngOnInit (): void {}

	public validatePassword (password: string): void {
		this.passwordLength = password.length;

		this.dummyControl.setValue(password);
		const validationErrors = this.passwordValidator(this.dummyControl);
		const isValid = validationErrors === null;

		// check individual requirements for icon color feedback
		this.lengthRequirement.fulfilled = password.length >= 8 &&
			new Blob([password]).size <= MULTIBYTE_MAX_LENGTH;

		this.requirements[0].fulfilled = this.PATTERNS.lowercase.test(password);
		this.requirements[1].fulfilled = this.PATTERNS.uppercase.test(password);
		this.requirements[2].fulfilled = this.PATTERNS.number.test(password);
		this.requirements[3].fulfilled = this.PATTERNS.symbol.test(password);

		this.calculateStrengthPercentage(password, isValid);
		this.cdr.markForCheck();
	}

	private calculateStrengthPercentage (password: string, isValid: boolean): void {
		if (password.length === 0) {
			this.strengthPercentage = 0;
			return;
		}

		const requirementsMet = this.requirements.filter((req) => req.fulfilled).length;
		let progress = 0;

		if (this.lengthRequirement.fulfilled) {
			progress += 40;
		} else if (password.length > 0) {
			progress += Math.min((password.length / 8) * 40, 40);
		}

		// progress for optional requirements (60% of total)
		// we need 3 out of 4, so each requirement is worth 20%
		const optionalProgress = Math.min(requirementsMet, MIN_OPTIONAL_REQUIREMENTS) * 20;
		progress += optionalProgress;

		// if meets minimum requirements, set to 100%
		if (isValid) {
			this.strengthPercentage = 100;
		} else {
			this.strengthPercentage = Math.min(Math.round(progress), 99); // otherwise max progress is 99%
		}
	}

	public enoughOptionalRequirementsMet (): boolean {
		const fulfilledRequirements = this.requirements.filter((req) => req.fulfilled).length;
		return fulfilledRequirements >= MIN_OPTIONAL_REQUIREMENTS;
	}

	public meetsMinimumRequirements (): boolean {
		return this.passwordValidator(this.dummyControl) === null;
	}

	public getStrengthBarClasses (): { [key: string]: boolean } {
		if (this.passwordLength === 0) {
			return { 'strength-empty': true };
		}

		if (this.meetsMinimumRequirements()) {
			return { 'strength-strong': true };
		} else if (this.strengthPercentage >= 50) {
			return { 'strength-medium': true };
		} else {
			return { 'strength-weak': true };
		}
	}

	public getBackgroundClasses (): { [key: string]: boolean } {
		if (this.passwordLength === 0) {
			return { 'strength-weak-bg': true };
		}

		if (this.meetsMinimumRequirements()) {
			return { 'strength-strong-bg': true };
		} else if (this.strengthPercentage >= 50) {
			return { 'strength-medium-bg': true };
		} else {
			return { 'strength-weak-bg': true };
		}
	}
}
