import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';

@Component({
	selector: 'ellipsis-with-count',
	template: require('./ellipsis-with-count.component.html'),
	styles: [require('./ellipsis-with-count.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EllipsisWithCountComponent implements OnInit, OnDestroy {
	@Input() public nameList: string[];
	private resizeObserver: ResizeObserver;

	constructor (
		private elementRef: ElementRef
	) {}

	public ngOnInit () {
		this.observeSize();
	}

	public ngOnDestroy (): void {
		this.resizeObserver?.disconnect();
	}

	private observeSize () {
		const hasNamesOverflown = () => {
			let hasOverflown = false;
			let count = 0;

			const namesContainer: HTMLElement = this.elementRef.nativeElement.querySelector('.names-container');
			if (namesContainer) {
				const { children }: { children: HTMLCollection } = namesContainer;
				let i: number;

				for (i = 0; i < children.length; i++) {
					const child = children[i];
					const elem = child.getBoundingClientRect();
					const parentRect = namesContainer.getBoundingClientRect();

					if (elem.right > parentRect.right) {
						hasOverflown = true;
						count++;
					}
				}
			}

			const overflowButton = this.elementRef.nativeElement.querySelector('.overflow-count');
			if (overflowButton) {
				overflowButton.style.display = hasOverflown ? 'block' : 'none';

				const countSpan = overflowButton.querySelector('span.overflow-counter');
				if (countSpan) {
					countSpan.textContent = `+ ${count}`;
				}
			}
		};

		const nameList = this.elementRef.nativeElement.querySelector('.name-list');
		this.resizeObserver = new ResizeObserver(hasNamesOverflown);
		this.resizeObserver.observe(nameList);
	}
}
