import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SessionGridComponent } from 'ngx/go-modules/src/components/activity-view/session-grid/session-grid.component';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { SessionCardModule } from 'ngx/go-modules/src/components/activity-view/session-card/session-card.module';
import { GridVirtualScrollViewportModule } from 'ngx/go-modules/src/components/grid-virtual-scroll-viewport/grid-virtual-scroll-viewport.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		SessionCardModule,
		GridVirtualScrollViewportModule
	],
	declarations: [
		SessionGridComponent
	],
	entryComponents: [
		SessionGridComponent
	],
	exports: [
		SessionGridComponent
	]
})
export class SessionGridModule {}
