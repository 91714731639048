import { UIRouterGlobals } from '@uirouter/angularjs';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { SessionStorageService } from './session-storage.service';

export class SessionManagerService {
	public static readonly NG1_INJECTION_NAME = 'sessionManager' as const;

	/* @ngInject */
	constructor (
		private $cookies: ng.cookies.ICookiesService,
		private $document: ng.IDocumentService,
		private $injector: ng.auto.IInjectorService,
		private $uiRouterGlobals: UIRouterGlobals,
		private sessionStorage: SessionStorageService
	) {
		this.startVisibilityWatcher();
		this.sessionStorage.setPrefix('goreact-');
	}

	/**
	 * Whether session has ended
	 *
	 * @returns {boolean}
	 */
	public isEnded () {
		return !this.isActive();
	};

	public isActive () {
		return this.$cookies.get('gr_expires_at') != null;
	};

	public isLockedOut (responseData) {
		return !!responseData?.remainingAccountLockSeconds;
	}

	public getAccountLockedTime (): number {
		let remainingAccountLockTimeMinutes = 0;
		const accountLockTime = sessionStorage.getItem('accountLockTime');

		if (accountLockTime) {
			sessionStorage.removeItem('accountLockTime');
			remainingAccountLockTimeMinutes = this.convertToMinutes(accountLockTime);
		}
		return remainingAccountLockTimeMinutes;
	}

	public convertToMinutes (timeInSeconds): number {
		timeInSeconds = parseInt(timeInSeconds, 10);
		return Math.ceil(timeInSeconds / 60);
	}

	private startVisibilityWatcher (): void {
		// Check version on tab focus
		this.$document[0].addEventListener('visibilitychange', () => {
			if (this.$document[0].visibilityState === 'visible') {
				// This will cause a 401 if someone comes back to the tab, but only "root"
				if (this.isEnded() && this.$uiRouterGlobals.$current.name.startsWith('root')) {
					(this.$injector.get('eventService') as EventService).broadcast(EVENT_NAMES.AUTH_ENDED);
				}
			}
		});
	}
}

export const sessionManagerToken = upgradeNg1Dependency(SessionManagerService);
