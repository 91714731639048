import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import type { License } from 'ngx/go-modules/src/interfaces/licenses';
import { ProductTier } from 'ngx/go-modules/src/interfaces/licenses/product-tier';

export enum LicensePlanBadgeType {
	FreeTrial = 'free-trial',
	Plus = 'advanced',
	Standard = 'essentials'
}

@Component({
	selector: 'license-plan-badge',
	template: require('./license-plan-badge.component.html'),
	styles: [require('./license-plan-badge.component.scss')]
})
export class LicensePlanBadgeComponent implements OnChanges {
	@Input() public license?: License;
	@Input() public loading? = false;
	@Input() public disabled? = false;
	@Input() public hideAction = false;
	@Input() public shouldRenew: boolean = false;
	@Output() public actionClick = new EventEmitter<void>();

	public currentBadgeType: LicensePlanBadgeType = null;

	constructor () {}

	public ngOnChanges (changes: SimpleChanges) {
		if (changes.license && changes.license.currentValue) {
			this.updateBadgeType(changes.license.currentValue);
		}
	}

	public updateBadgeType (license: License) {
		if (license == null || license.salesforce_license == null) {
			this.currentBadgeType = null;
			return;
		}

		if (license.salesforce_license.is_free_trial) {
			this.currentBadgeType = LicensePlanBadgeType.FreeTrial;
		} else if (license.salesforce_license.tier_name === ProductTier.Plus) {
			this.currentBadgeType = LicensePlanBadgeType.Plus;
		} else if (license.salesforce_license.tier_name === ProductTier.Standard) {
			this.currentBadgeType = LicensePlanBadgeType.Standard;
		}
	}

	public getMessageTranslationKey () {
		switch(this.currentBadgeType){
			case LicensePlanBadgeType.FreeTrial:
				return 'license-plan-free-trial';
			case LicensePlanBadgeType.Plus:
				return 'license-plan-plus';
			case LicensePlanBadgeType.Standard:
				return 'license-plan-standard';
			default:
				return '';
		}
	}

	public getActionTranslationKey () {
		if (this.shouldRenew) {
			return 'license-plan-upgrade_renew_plan';
		}

		switch(this.currentBadgeType){
			case LicensePlanBadgeType.FreeTrial:
				return 'license-plan-purchase';
			case LicensePlanBadgeType.Standard:
				return 'license-plan-upgrade';
			default:
				return '';
		}
	}

	public getAriaLabelTranslationKey () {
		if (this.shouldRenew) {
			return 'license-plan-upgrade_renew_plan';
		}

		switch(this.currentBadgeType){
			case LicensePlanBadgeType.FreeTrial:
				return 'license-plan-purchase-license';
			case LicensePlanBadgeType.Standard:
				return 'license-plan-upgrade-license';
			default:
				return '';
		}
	}

	public shouldShowAction () {
		if (this.hideAction) {
			return false;
		}

		return (this.currentBadgeType != null && this.currentBadgeType !== LicensePlanBadgeType.Plus)
			|| this.shouldRenew;
	}
}
