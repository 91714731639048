import { IModalInstanceService } from 'angular-ui-bootstrap';
import { FormValidationService } from '../form-validation/form-validation.service';
import {
	ActivityFeedbackInstructionsEditorModal,
	ActivityRecordingInstructionsEditorModal
} from '../modals/activity-instructions-editor';
import { ActivityInstructionModel, ActivitySourceMediaModel, PrivacyLevel } from '../models/activity';
import { LibraryCollectionViewerModal } from 'go-modules/modals/library-collection-viewer/modal.service';
import { CONTENT_TYPES, MODES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { noop } from 'angular';
import { GoLocalizeHelperService } from 'go-modules/translation-helper/go-localize-helper.service';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import {
	AIMarkersDialogComponent
} from 'ngx/go-modules/src/components/dialogs/ai-markers-dialog/ai-markers-dialog.component';
import { AiPrompt } from 'ngx/go-modules/src/services/ai-prompt/ai-prompt.service';
import { ActivityEditorTour } from './activity-editor-tour/activity-editor-tour.service';
import { ActivityEditorAiMarkersTour } from './activity-editor-ai-markers-tour/activity-editor-ai-markers-tour.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { ActivityEditorDefaultMarkerSetTour } from './activity-editor-default-marker-set-tour/activity-editor-default-marker-set-tour.service';
import { HeyGenPrompt } from 'ngx/go-modules/src/components/hey-gen-prompt-editor/hey-gen-prompt-editor.component';
import { GoPulseTourService } from 'ngx/go-modules/src/services/go-pulse-tour/go-pulse-tour.service';
import { DEFAULT_AI_TOUR_CONFIG } from 'ngx/go-modules/src/services/go-pulse-tour/constants/default-ai-tour';
import { BadgeColors } from 'ngx/go-modules/src/components/badge/badge.constants';
import { ProductTier } from 'ngx/go-modules/src/interfaces/licenses/product-tier';

export interface Bindings {
	user: any;
	activity: any;
	activityTemplates: any[];
	group: any;
	formController: ActivityEditorForm;
	libraryMode: boolean;
	isOrgCollectionAndCanModifyItems: boolean;
}

export interface GradingType {
	title: string;
	description: string;
	disabled?: boolean;
	needsTranslated?: boolean;
}

export interface GradingTypeLookup {
	NOT_GRADED: GradingType;
	POINTS: GradingType;
	INSTRUCTOR_RUBRIC: GradingType;
}

export interface TitleDescription {
	title: string;
	description: string;
	needsTranslated?: boolean;
}

export interface PrivacyOption {
	title: string;
	description: string;
	peerReview: boolean;
	publicFeedback: boolean;
	needsTranslated?: boolean;
}

export interface PrivacyTypeLookup {
	PRIVATE: PrivacyOption;
	CLOSED_PEER_REVIEW?: PrivacyOption; // this is optional based on activity type
	EVERYONE_SEES_EVERYTHING: PrivacyOption;
}

export interface SourceTypesLookup {
	INSTRUCTOR_SELECTED: TitleDescription;
	PRESENTER_SELECTED: TitleDescription;
}

export interface ActivityEditorForm extends ng.IFormController {
	name?: ng.INgModelController;
	template: ng.INgModelController;
	privacyType?: ng.INgModelController;
	sourceMedia?: ng.INgModelController;
	commentOnlySource?: ng.INgModelController;
	availabilityDate?: ng.INgModelController;
	dueDate?: ng.INgModelController;
	gradingType?: ng.INgModelController;
	pointsPossible?: ng.INgModelController;
	isGroupRecording?: ng.INgModelController;
	isRealTimeFeedback?: ng.INgModelController;
	singleRecordingAttempt?: ng.INgModelController;
	timeLimitEnabled?: ng.INgModelController;
	isVideoSeekingDisabled?: ng.INgModelController;
	isClosedCaptionsDisabled?: ng.INgModelController;
	timeLimit?: ng.INgModelController;
	isSlidesEnabled?: ng.INgModelController;
}

export enum ACCORDION_SECTIONS {
	DETAILS = 'details',
	RECORDING = 'recording',
	FEEDBACK = 'feedback'
}

export class ActivityEditorController implements Bindings {

	// Bindings
	public user: any;
	public activity: any;
	public activityTemplates: any[];
	public formController: ActivityEditorForm;
	public group: any;
	public uploadLimit: number;
	public libraryMode: boolean = false;
	public isOrgCollectionAndCanModifyItems: boolean = false;
	public orgSettings: any;
	public expandFirstSection: boolean = false;

	// Other
	public helpLinks: any;
	public environmentVarsService: EnvironmentVarsService;
	public openSections: ACCORDION_SECTIONS[] = [];
	public sections = ACCORDION_SECTIONS;

	// Form data
	public availableAt: Date;
	public dueAt: Date;
	public filteredPrivacyTypes: PrivacyOption[];
	public gradingTypeLookup: GradingTypeLookup;
	public gradingTypes: GradingType[];
	public canReviewersSeeRubrics: boolean;
	public isMarkerSetSelected: boolean;
	public isGroupRecording: boolean;
	public isRealTimeFeedback: boolean;
	public isSlidesEnabled: boolean;
	public isVideoSeekingDisabled: boolean;
	public isClosedCaptionsDisabled: boolean;
	public singleRecordingAttempt: boolean;
	public name: string;
	public pointsPossible: number;
	public privacyTypeLookup: PrivacyTypeLookup;
	public privacyTypes: PrivacyOption[];
	public selectedGradingType: GradingType;
	public selectedMarkerSet: any;
	public selectedPrivacyType: PrivacyOption;
	public selectedRubric: any;
	public selectedSourceType: TitleDescription;
	public template: any;
	public timeLimit: number;
	public timeLimitEnabled: boolean;
	public isVideoShareEnabled: boolean;
	public isAIPromptsEnabled: boolean;
	public aiTranscriptionLanguage: string|null = null;
	public canPreviewAiPrompts = true;
	public aiPrompts: AiPrompt[] | undefined;
	public initialVideoShareSetting: boolean;
	public isMasterActivityEnabled: boolean;
	public badgeColors = BadgeColors;

	// Heygen
	public heygenPrompt: HeyGenPrompt;
	public heygenPrompts: HeyGenPrompt[];

	// Source Media
	public sourceTypesLookup: SourceTypesLookup;
	public sourceTypes: TitleDescription[];
	public sourceMedia: any;
	public hasSelfSelectSourceMedia: boolean;

	// Student rubrics
	public isStudentRubricEnabled: boolean;
	public isStudentRubricSelfEvaluationEnabled: boolean;
	public isStudentRubricPeerEvaluationEnabled: boolean;
	public selectedStudentRubric: any;

	// Instructions
	public recordingInstructions: ActivityInstructionModel;
	public feedbackInstructions: ActivityInstructionModel;

	// Attachments
	public attachments: any[] = [];

	public formValidationService: FormValidationService;
	public defaultAvailabilityDate: Date;

	private templateOrder = [
		this.ActivityTemplateModel.TYPE.STANDARD,
		this.ActivityTemplateModel.TYPE.STIMULUS_RESPONSE,
		this.ActivityTemplateModel.TYPE.FEEDBACK_ONLY
	];

	/* @ngInject */
	constructor (
		public helpUrls: any,
		public featureFlag: FeatureFlag,
		private $http: ng.IHttpService,
		private $scope: ng.IScope,
		private RubricTemplateModel: any,
		private TagSetModel: any,
		private activityRecordingInstructionsEditorModal: ActivityRecordingInstructionsEditorModal,
		private activityFeedbackInstructionsEditorModal: ActivityFeedbackInstructionsEditorModal,
		private ActivityTemplateModel: any,
		private goLocalizeHelper: GoLocalizeHelperService,
		private libraryCollectionViewerModal: LibraryCollectionViewerModal,
		private $log: angular.ILogService,
		private MediaModel,
		private selectedService: SelectedService,
		private $translate: ng.translate.ITranslateService,
		private ngxDowngradeModalService: DowngradeModalService,
		private ngxGoModalService: GoModalService,
		private activityEditorTour: ActivityEditorTour,
		private activityEditorAiMarkersTour: ActivityEditorAiMarkersTour,
		private activityEditorDefaultMarkerSetTour: ActivityEditorDefaultMarkerSetTour,
		private ngxGoPulseTourService: GoPulseTourService,
		private ActivityModel,
		private mediaPreviewModal
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
		this.gradingTypeLookup = {
			NOT_GRADED: {title: 'activity-editor-new_not-graded', description: 'activity-editor-new_not-graded-description', needsTranslated: true},
			POINTS: {title: 'activity-editor-new_points', description: 'activity-editor-new_points-description', needsTranslated: true},
			INSTRUCTOR_RUBRIC: {title: 'activity-editor-new_instructor-rubrics', description: 'activity-editor-new_instructor-rubrics-description', needsTranslated: true}
		};
		this.gradingTypes = [
			this.gradingTypeLookup.NOT_GRADED,
			this.gradingTypeLookup.POINTS,
			this.gradingTypeLookup.INSTRUCTOR_RUBRIC
		];
		this.sourceTypesLookup = {
			INSTRUCTOR_SELECTED: {
				title: 'activity-editor-new_source-media-instructor-selected-title',
				description: 'activity-editor-new_source-media-instructor-selected-description',
				needsTranslated: true
			},
			PRESENTER_SELECTED: {
				title: 'activity-editor-new_source-media-presenter-selected-title',
				description: 'activity-editor-new_source-media-presenter-selected-description',
				needsTranslated: true
			}
		};
		this.sourceTypes = [
			this.sourceTypesLookup.INSTRUCTOR_SELECTED,
			this.sourceTypesLookup.PRESENTER_SELECTED
		];
		this.$scope.$watch('$ctrl.selectedGradingType', this.onGradingTypeChange);
		this.$scope.$on('toggleAccordionEvent', this.toggleAccordionError.bind(this));
	}

	/**
	 * Handles controller init lifecycle hook
	 */
	public $onInit (): void {
		if (this.activity.template == null) {
			throw new Error('ActivityEditorController:: need to supply Activity Template');
		}

		this.template = this.activity.template;
		this.activityTemplates.$promise.then(() => {
			this.activityTemplates.sort((a, b) =>
				this.templateOrder.indexOf(a.getType()) - this.templateOrder.indexOf(b.getType())
			);

			// Some account does not have a use_type.
			// In that case set make standard as default.
			if (!this.template.slug) {
				this.template = this.activityTemplates
					.find((value) => value.getType() === this.ActivityTemplateModel.TYPE.STANDARD);
			}

		});

		this.init();
	}

	public init () {
		this.generatePrivacyLists();
		// set default availability date datetimepicker value to today at 00:00:00
		this.defaultAvailabilityDate = new Date();
		this.defaultAvailabilityDate.setHours(0, 0, 0, 0);
		this.initFormData(this.activity);
		this.formValidationService = new FormValidationService(this.formController);

		// if rubric is in use then only show rubric grading type option
		// or else users can bypass rubric lockdown
		if (this.activity.rubric_template_id) {
			this.RubricTemplateModel.checkUsage({id: this.activity.rubric_template_id})
				.$promise.then((response) => {
					if (response.in_use && response.activities.includes(this.activity.activity_id)) {
						this.gradingTypes = [
							this.gradingTypeLookup.INSTRUCTOR_RUBRIC
						];
					}
				});
		}
		this.initialVideoShareSetting = this.isVideoShareEnabled;

		if (this.expandFirstSection) {
			this.openSections = [ACCORDION_SECTIONS.DETAILS];
		}

		this.activityEditorTour.getTour().start();
		this.ngxGoPulseTourService.startTour(DEFAULT_AI_TOUR_CONFIG).subscribe({
			next: () => {
				if (this.aiPrompts?.length > 0 && !this.isOpen(ACCORDION_SECTIONS.FEEDBACK)) {
					this.openSections.push(ACCORDION_SECTIONS.FEEDBACK);
				}
			},
			error: () => {}
		});

		// Do not launch this tour in LTI
		// LTI has its own separate conditions to launch this tour
		const environment = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) as any;
		if (environment.name !== ENVIRONMENTS.LTI) {
			if (this.activity.tag_set_id !== null) {
				this.activityEditorDefaultMarkerSetTour.getTour().start();
			}

			if (this.selectedService.getLicense()?.salesforce_license.ai_prompts_enabled) {
				this.activityEditorAiMarkersTour.getTour().start();
			}
		}
	}

	public showMasterActivityToggle (): boolean {
		return this.libraryMode && this.isOrgCollectionAndCanModifyItems;
	}

	public showMasterActivityWarning () {
		if (this.activity.activity_id) {
			this.ActivityModel.childrenCount({
				activity_id: this.activity.activity_id
			}).$promise.then((res) => {
				if (res.activities > 0) {
					this.ngxDowngradeModalService.openConfirmDialog({
						title: this.isMasterActivityEnabled ? this.$translate.instant('modal-activity-enable-master-warning_edit-activity') : this.$translate.instant('modal-activity-disable-master-warning_edit-activity'),
						message: this.$translate.instant(this.isMasterActivityEnabled ? 'modal-activity-enable-master-warning_warning-text' : 'modal-activity-disable-master-warning_warning-text', { folders: res.activities, videos: res.sessions }),
						confirmText: this.$translate.instant('common_continue')
					})
						.catch(() => {
							this.isMasterActivityEnabled = !this.isMasterActivityEnabled;
							this.$scope.$apply();
						});
				}
			});
		}
	}

	public toggleAccordion (section: ACCORDION_SECTIONS) {
		const position = this.openSections.indexOf(section);
		if (position > -1) {
			this.openSections.splice(position, 1);
		} else {
			this.openSections.push(section);
		}
	};

	public isOpen (section: ACCORDION_SECTIONS) {
		return this.openSections.includes(section);
	};

	public toggleAccordionError (_e, args) {
		const control = args.invalidControls.required[0].$name;
		// open just that section
		if (['sourceMedia', 'availabilityDate', 'dueDate'].includes(control)) {
			this.openSections = [ACCORDION_SECTIONS.DETAILS];
		} else if (control === 'timeLimit') {
			this.openSections = [ACCORDION_SECTIONS.RECORDING];
		} else if ( control === 'pointsPossible') {
			this.openSections = [ACCORDION_SECTIONS.FEEDBACK];
		}
	}

	public toggleAllowVideoSharing () {
		if (this.libraryMode) {
			return;
		}

		if (this.initialVideoShareSetting && !this.isVideoShareEnabled) {
			this.ngxDowngradeModalService.openConfirmDialog({
				title: this.$translate.instant('activity-editor-disable-video-sharing-modal-title'),
				message: this.$translate.instant('activity-editor-disable-video-sharing-modal-body'),
				confirmText: this.$translate.instant('activity-editor-disable-video-sharing-modal-btn-disable')
			})
				.then(noop)
				.catch(() => {
					this.isVideoShareEnabled = true;
					this.$scope.$apply();
				});
		}
	}

	public $doCheck () {
		if (this.formController.$submitted && this.formController.$valid) {
			this.applyFormDataTo(this.activity);
		}
	}

	/**
	 * Defines and updates what is shown in the privacy settings dropdown
	 */
	public generatePrivacyLists (): void {
		this.privacyTypeLookup = {
			PRIVATE: this.template.isActive('has_response_media') ?
				{title: 'activity-editor-new_private', description: 'activity-editor-new_private-description', peerReview: false, publicFeedback: false, needsTranslated: true} :
				{title: 'activity-editor-new_private', description: 'activity-editor-new_private-description-filtered', peerReview: false, publicFeedback: false, needsTranslated: true},
			CLOSED_PEER_REVIEW: {title: 'activity-editor-new_closed-peer-review', description: 'activity-editor-new_closed-peer-review-description', peerReview: true, publicFeedback: false, needsTranslated: true},
			EVERYONE_SEES_EVERYTHING: this.template.isActive('has_response_media') ?
				{title: 'activity-editor-new_everyone-sees-everything', description: 'activity-editor-new_everyone-sees-everything-description', peerReview: true, publicFeedback: true, needsTranslated: true} :
				{title: 'activity-editor-new_everyone-sees-everything', description: 'activity-editor-new_everyone-sees-everything-description-filtered', peerReview: false, publicFeedback: true, needsTranslated: true}
		};
		this.privacyTypes = [
			this.privacyTypeLookup.PRIVATE,
			this.privacyTypeLookup.CLOSED_PEER_REVIEW,
			this.privacyTypeLookup.EVERYONE_SEES_EVERYTHING
		];
		this.filteredPrivacyTypes = [
			this.privacyTypeLookup.PRIVATE,
			this.privacyTypeLookup.EVERYONE_SEES_EVERYTHING
		];
	}

	public getPrivacyLabel (): string {
		if (this.template.isActive('has_response_media')) {
			return 'activity-editor-new_recording-and-feedback-privacy';
		}
		return 'activity-editor-new_comment-privacy';
	}

	public getDateFormat (): string {
		return this.goLocalizeHelper.longDateFormat;
	}

	public getPrivacyTypes (): any {
		if (!this.privacyTypes) {
			this.generatePrivacyLists();
		}
		if (this.template.isActive('has_response_media')) {
			return this.privacyTypes;
		}
		return this.filteredPrivacyTypes;
	}

	/**
	 * Determine whether the user can modify the activity type
	 */
	public canModifyActivityType (): boolean {
		const isRootUser = !!this.user && this.user.is_root_user;
		return !this.activity.hasUsage() || this.activity.isDefault() || isRootUser;
	}

	/**
	 * Whether the points possible field should be displayed to the user
	 */
	public shouldDisplayPointsPossible (): boolean {
		switch (this.selectedGradingType) {
			case this.gradingTypeLookup.INSTRUCTOR_RUBRIC:
				return this.pointsPossible !== null;
			case this.gradingTypeLookup.POINTS:
				return true;
			default:
				return false;
		}
	}

	/**
	 * Whether the points possible input should be disabled
	 */
	public isPointsPossibleDisabled (): boolean {
		return this.selectedGradingType === this.gradingTypeLookup.INSTRUCTOR_RUBRIC ||
			this.activity.is_points_possible_readonly;
	}

	public getPointsDisabledTooltip (): string {
		if (this.isPointsPossibleDisabled()) {
			if (this.selectedGradingType === this.gradingTypeLookup.INSTRUCTOR_RUBRIC) {
				return this.$translate.instant('activity-editor-header_points-generated');
			}
			return this.$translate.instant('activity-editor-header_points-lms');
		}
		return '';
	}

	/**
	 * Whether points possible validation errors should be shown to the user
	 */
	public shouldDisplayPointsPossibleError (): boolean {
		return this.selectedGradingType === this.gradingTypeLookup.POINTS &&
			this.formValidationService.shouldDisplayError('pointsPossible');
	}

	/**
	 * Is viewable
	 */
	public isViewable (property: any): boolean {
		return !this.template.isNone(property) &&
			!this.template.isActive(property);
	}

	/**
	 * Open Recording Instructions modal
	 */
	public openRecordingInstructionsModal (): IModalInstanceService {
		const options = {
			instructions: this.recordingInstructions,
			instructionsType: ActivityInstructionModel.TYPE.RECORDING,
			uploadLimit: this.uploadLimit,
			groupId: this.activity.group_id,
			groupName: this.group.name,
			activityName: this.activity.name
		};

		const modalInstance = this.activityRecordingInstructionsEditorModal.open(options);

		modalInstance.result.then((recordingInstructions: ActivityInstructionModel) => {
			this.recordingInstructions = recordingInstructions;
			this.formController.$setDirty();
		}).catch(() => {
			//do nothing
		});

		return modalInstance;
	}

	/**
	 * Open Feedback Instructions modal
	 */
	public openFeedbackInstructionsModal (): IModalInstanceService {
		const options = {
			instructions: this.feedbackInstructions,
			instructionsType: ActivityInstructionModel.TYPE.FEEDBACK,
			uploadLimit: this.uploadLimit,
			groupId: this.activity.group_id,
			groupName: this.group.name,
			activityName: this.activity.name
		};

		const modalInstance = this.activityFeedbackInstructionsEditorModal.open(options);

		modalInstance.result.then((feedbackInstructions: ActivityInstructionModel) => {
			this.feedbackInstructions = feedbackInstructions;
			this.formController.$setDirty();
		}).catch(() => {
			//do nothing
		});

		return modalInstance;
	}

	public showAdvancePlanBadge (): boolean {
		if(this.selectedService.getLicense() == null) return true;

		const salesforceLicense = this.selectedService.getLicense()?.salesforce_license;
		return salesforceLicense?.tier_name === ProductTier.Standard || salesforceLicense?.tier_name === null
			|| salesforceLicense?.is_free_trial;
	}

	/**
	 * Handle activity type change event
	 */
	public onActivityTypeChange (): void {
		this.isRealTimeFeedback = false;
		this.generatePrivacyLists();

		if (this.template.isNone('is_video_share_enabled')) {
			this.isVideoShareEnabled = false;
		}

		if (!this.template.isActive('ai_prompts_enabled')) {
			this.handleAiMarkerSetRemove();
		}

		if (this.isViewable('source_media') || this.isViewable('has_self_select_source_media')) {
			if (!this.sourceMedia && !this.activity.isDefault()) {
				this.formController.$setValidity('required', false, this.formController);
			} else {
				this.formController.$setValidity('required', true, this.formController);
			}
		} else {
			this.formController.$setValidity('required', true, this.formController);
		}
	}

	/**
	 * Open Source Media modal
	 */
	public openSourceMediaModal () {
		return this.libraryCollectionViewerModal.open({
			modalData:{
				filterType: [CONTENT_TYPES.MEDIA, CONTENT_TYPES.DOCUMENTS],
				mode: MODES.SELECT,
				previewable: true,
				collectionManagement: true
			}
		}).result.then((res) => {
			const media = this.MediaModel.model(res);
			this.sourceMedia = ActivitySourceMediaModel.create({ media, media_id: media.media_id });
			this.formController.$setValidity('required', true, this.formController);
			if (this.formController.sourceMedia) {
				this.formController.sourceMedia.$setDirty();
			} else {
				this.formController.$setDirty();
			}
		})
			.catch((err) => {
				if (err instanceof Error) {
					throw err;
				}
				this.formController.$setValidity('required', false, this.formController);
				this.removeSourceMedia();
			});
	}

	/**
	 * Remove Source Media
	 */
	public removeSourceMedia (): void {
		this.sourceMedia = null;
		this.selectedSourceType = null;
	}

	/**
	 * Handle source type change event
	 */
	public onSourceTypeChange (): void {
		if (this.selectedSourceType === this.sourceTypesLookup.INSTRUCTOR_SELECTED) {
			this.hasSelfSelectSourceMedia = false;
			this.openSourceMediaModal();
		}

		if (this.selectedSourceType === this.sourceTypesLookup.PRESENTER_SELECTED) {
			this.sourceMedia = null;
			this.hasSelfSelectSourceMedia = true;
			this.formController.$setValidity('required', true, this.formController);
		}
	}

	/**
	 * Handle grading type change event
	 */
	public onGradingTypeChange = (newValue: GradingType, oldValue: GradingType): void => {
		if (newValue === oldValue) {
			return;
		}

		if (this.selectedGradingType === this.gradingTypeLookup.NOT_GRADED) {
			this.pointsPossible = null;
			this.selectedRubric = null;

		} else if (this.selectedGradingType === this.gradingTypeLookup.POINTS) {
			this.pointsPossible = this.activity.total_score;
			this.selectedRubric = null;

		} else if (this.selectedGradingType === this.gradingTypeLookup.INSTRUCTOR_RUBRIC) {
			this.libraryCollectionViewerModal.open({
				modalData:{
					filterType: [CONTENT_TYPES.RUBRICS],
					mode: MODES.SELECT,
					collectionManagement: true
				}
			})
				.result.then((rubricTemplate: any) => {
					this.selectedRubric = new this.RubricTemplateModel(rubricTemplate);
					this.pointsPossible = rubricTemplate.total;
				}).catch(() => {
					this.selectedGradingType = oldValue;
				});
		}
	};

	/**
	 * Handle editing of a rubric item
	 */
	public handleRubricEdited (rubricTemplate: any): void {
		this.selectedRubric = rubricTemplate;
		this.formController.gradingType.$setDirty();

		// We have to manually mark the form as dirty.
		if (this.pointsPossible !== rubricTemplate.total) {
			this.pointsPossible = rubricTemplate.total;
		}
	}

	/**
	 * Handle removing of a rubric item
	 */
	public handleRubricRemoved (): void {
		this.canReviewersSeeRubrics = false;
		this.selectedGradingType = this.gradingTypeLookup.NOT_GRADED.disabled ?
			this.gradingTypeLookup.POINTS :
			this.gradingTypeLookup.NOT_GRADED;
		this.onGradingTypeChange(this.selectedGradingType, this.gradingTypeLookup.INSTRUCTOR_RUBRIC);
	}

	/**
	 * Handle grading type updates
	 */
	public handleGradingTypeUpdate (): void {
		this.canReviewersSeeRubrics = false;
	}

	/**
	 * Handle update of Allow Reviewer Access
	 */
	public handleReviewersRubricsAccessUpdate (allowAccess: boolean): void {
		this.canReviewersSeeRubrics = allowAccess;
	}

	/**
	 * Handle marker set setting enabled/disabled setting change
	 */
	public openMarkerSelectionModal (): void {
		try {
			this.libraryCollectionViewerModal.open({
				modalData:{
					filterType: [CONTENT_TYPES.MARKERS],
					mode: MODES.SELECT,
					collectionManagement: true
				}
			})
				.result.then((markers) => {
					this.selectedMarkerSet = new this.TagSetModel(markers);
				}).catch(this.$log.error);
			this.formController.$setDirty();
		} catch (error) {
			// Do nothing when the modal is dismissed (unless it is an actual error)
			if (error instanceof Error) {
				throw error;
			}
		}
	}

	/**
	 * Handle editing of a marker set
	 */
	public handleMarkerSetEdited (markerSet: any): void {
		this.selectedMarkerSet = markerSet;
		this.formController.$setDirty();
	}

	/**
	 * Handle removing of a marker set
	 */
	public handleMarkerSetRemoved (): void {
		this.selectedMarkerSet = null;
		this.formController.$setDirty();
		this.$scope.$apply();
	}

	public handleAiMarkerSetRemove (): void {
		this.aiPrompts = [];
		this.activity.ai_prompts = [];
		this.formController.$setDirty();
		this.$scope.$apply();
	}

	/**
	 * Show the student rubric settings modal
	 */
	public openStudentRubricSetting (): void {
		this.selectedStudentRubric = null;

		// Default the self evaluation setting to on and the peer evaluation to off
		this.isStudentRubricSelfEvaluationEnabled = true;
		this.isStudentRubricPeerEvaluationEnabled = false;

		this.libraryCollectionViewerModal.open({
			modalData:{
				filterType: [CONTENT_TYPES.RUBRICS],
				mode: MODES.SELECT,
				collectionManagement: true
			}
		})
			.result.then((rubricTemplate: any) => {
				this.selectedStudentRubric = new this.RubricTemplateModel(rubricTemplate);
				this.formController.$setDirty();
			}).catch(() => {
				this.isStudentRubricEnabled = false;
			});
	}

	/**
	 * Handle the student rubric edited event
	 */
	public handleStudentRubricEdited (rubricTemplate: any): void {
		this.selectedStudentRubric = rubricTemplate;
		this.formController.$setDirty();
	}

	/**
	 * Handle the student rubric removed event
	 */
	public handleStudentRubricRemoved (): void {
		this.selectedStudentRubric = null;
		this.isStudentRubricEnabled = false;
		this.formController.$setDirty();
	}

	/**
	 * Handle the recording instructions edited event
	 */
	public handleRecordingInstructionsEdited (recordingInstructions: ActivityInstructionModel): void {
		this.recordingInstructions = recordingInstructions;
		this.formController.$setDirty();
	}

	/**
	 * Handle the recording instructions removed event
	 */
	public handleRecordingInstructionsRemoved (): void {
		this.recordingInstructions = null;
		this.formController.$setDirty();
		this.$scope.$apply();
	}

	/**
	 * Handle the feedback instructions edited event
	 */
	public handleFeedbackInstructionsEdited (feedbackInstructions: ActivityInstructionModel): void {
		this.feedbackInstructions = feedbackInstructions;
		this.formController.$setDirty();
	}

	/**
	 * Handle the feedback instructions removed event
	 */
	public handleFeedbackInstructionsRemoved (): void {
		this.feedbackInstructions = null;
		this.formController.$setDirty();
		this.$scope.$apply();
	}

	/**
	 * Toggle group recording
	 */
	public toggleGroupRecording (): void {
		this.singleRecordingAttempt = false;
		this.timeLimitEnabled = false;
		this.isSlidesEnabled = false;
	}

	public shouldShowVideoShareSetting (): boolean {
		return this.orgSettings
			&& this.orgSettings.allow_video_share
			&& this.isViewable('is_video_share_enabled');
	}

	public shouldShowAiMarkerSetting (): boolean {
		const license = this.selectedService.getLicense();

		if (!license || license.salesforce_license.may_tease) {
			return this.template.isActive('ai_prompts_enabled');
		}

		return this.template.isActive('ai_prompts_enabled') && license.salesforce_license.ai_prompts_enabled;
	}

	public previewAiMarkers () {
		const media = this.MediaModel.model({
			media_status: this.MediaModel.READY,
			media_type: this.MediaModel.TYPE.DOCUMENT,
			filename: 'ai-preview.gif',
			media_url: 'https://staticassets.goreact.com/ai-preview.gif',
			title: this.$translate.instant('activity-info_example-ai-markers')
		});

		this.mediaPreviewModal.open({
			modalData: {
				media,
				edit: false,
				allowDownload: false
			}
		});
	}

	public openAiMarkers () {
		return this.ngxGoModalService.open(AIMarkersDialogComponent, true, {
			data: {
				activity: this.activity,
				aiPrompts: structuredClone(this.aiPrompts),
				aiTranscriptionLanguage: this.aiTranscriptionLanguage
			}
		}).afterClosed().subscribe((result) => {
			if (result != null) {
				this.aiTranscriptionLanguage = result.ai_transcription_language;
				this.aiPrompts = result.prompts;

				this.formController.$setDirty();
				this.$scope.$evalAsync();
			}
		});
	}

	/**
	 * Write all changes to the source activity
	 */
	public applyFormDataTo (activity: any): void {
		activity.live_session_enabled = this.isRealTimeFeedback;
		activity.available_at = this.availableAt;
		activity.due_at = this.dueAt;
		activity.is_slides_enabled = this.isSlidesEnabled;
		activity.is_conversation = this.isGroupRecording;
		activity.has_single_recording_attempt = this.singleRecordingAttempt;
		activity.can_reviewers_see_grades = this.canReviewersSeeRubrics;
		activity.name = this.name;
		activity.tag_set_id = this.selectedMarkerSet ? this.selectedMarkerSet.id : null;
		activity.time_limit = this.timeLimit;
		activity.is_video_seeking_disabled = this.isVideoSeekingDisabled;
		activity.is_source_media_cc_disabled = this.isClosedCaptionsDisabled;
		activity.total_score = this.pointsPossible || null;
		const allowVideoShare = this.orgSettings?.allow_video_share;
		activity.is_video_share_enabled = !!(allowVideoShare && this.isVideoShareEnabled);
		activity.ai_prompts_enabled = this.isAIPromptsEnabled;
		activity.ai_transcription_language = this.aiTranscriptionLanguage;
		activity.ai_prompts = this.aiPrompts;
		activity.parent_child_sync = this.isMasterActivityEnabled;

		if (!this.timeLimitEnabled) {
			activity.time_limit = null;
		}

		// Recording / feedback instructions
		activity.recording_instructions = this.recordingInstructions;
		activity.feedback_instructions = this.feedbackInstructions;

		// Privacy settings
		activity.peer_review = this.selectedPrivacyType.peerReview;
		activity.public_feedback = this.selectedPrivacyType.publicFeedback;

		// Source Media
		activity.has_self_select_source_media = this.hasSelfSelectSourceMedia;
		activity.source_media = this.sourceMedia || false;

		activity.attachments = this.attachments;

		// Rubrics
		activity.rubric_template_id = this.selectedRubric ? this.selectedRubric.id : null;
		activity.peer_rubric_id = this.selectedStudentRubric ? this.selectedStudentRubric.id : null;
		Object.assign(activity, this.getRubricPrivacySettings(activity.peer_review, !!this.selectedStudentRubric));
		activity.setActivityTemplate(this.template);

		if (this.heygenPrompt && this.featureFlag.isAvailable('HEYGEN')) {
			const oldPrompt = this.heygenPrompts.find((p) => p.activityIds.includes(this.activity.activity_id));
			if (oldPrompt) {
				oldPrompt.activityIds.splice(
					oldPrompt.activityIds.findIndex((a) => a === this.activity.activity_id), 1
				);
			}
			this.heygenPrompt.activityIds.push(this.activity.activity_id);
			activity.hey_gen_prompts = this.heygenPrompts;
		}
	}

	/**
	 * Read from the source activity to set the form data
	 */
	private initFormData (activity: any): void {
		this.availableAt = activity.available_at;
		this.dueAt = activity.due_at;
		this.isSlidesEnabled = !!activity.is_slides_enabled;
		this.isGroupRecording = !!activity.is_conversation;
		this.singleRecordingAttempt = !!activity.has_single_recording_attempt;
		this.canReviewersSeeRubrics = !!activity.can_reviewers_see_grades;
		this.timeLimit = activity.time_limit;
		this.timeLimitEnabled = this.timeLimit > 0;
		this.isVideoSeekingDisabled = activity.is_video_seeking_disabled;
		this.isClosedCaptionsDisabled = activity.is_source_media_cc_disabled;
		this.isVideoShareEnabled = activity.is_video_share_enabled;
		this.isAIPromptsEnabled = activity.ai_prompts_enabled;
		this.aiTranscriptionLanguage = activity.ai_transcription_language;
		this.aiPrompts = structuredClone(activity.ai_prompts);
		this.name = activity.name;
		this.pointsPossible = activity.total_score;
		this.selectedMarkerSet = this.determineInitiallySelectedMarkerSet(activity);
		this.selectedGradingType = this.determineInitiallySelectedGradingType(activity);
		this.selectedPrivacyType = this.determineInitiallySelectedPrivacySetting();
		this.selectedRubric = this.determineInitiallySelectedRubric(activity);
		this.isMasterActivityEnabled = !!activity.parent_child_sync;

		// Source Media
		this.sourceMedia = activity.source_media || null;
		this.selectedSourceType = (activity.source_media && this.sourceTypesLookup.INSTRUCTOR_SELECTED)
			|| (activity.has_self_select_source_media && this.sourceTypesLookup.PRESENTER_SELECTED) || null;
		this.hasSelfSelectSourceMedia = activity.has_self_select_source_media;

		// Student rubric
		this.selectedStudentRubric = this.determineInitiallySelectedStudentRubric(activity);
		this.isStudentRubricEnabled = !!this.selectedStudentRubric;
		this.isStudentRubricSelfEvaluationEnabled = activity.self_rubric_critique_enabled;
		this.isStudentRubricPeerEvaluationEnabled = activity.peer_rubric_critique_enabled;

		// Recording / feedback instructions
		this.recordingInstructions = activity.hasRecordingInstructions() ? activity.recording_instructions : null;
		this.feedbackInstructions = activity.hasFeedbackInstructions() ? activity.feedback_instructions : null;

		this.attachments = [...activity.attachments];

		this.isRealTimeFeedback = (activity.hasOwnProperty('activity_id'))
			? activity.live_session_enabled
			: false;

		if (this.featureFlag.isAvailable('HEYGEN')) {
			this.$http.get<HeyGenPrompt[]>('/api/v2/heygen/prompts')
				.then((response) => {
					const prompts = response.data;
					this.heygenPrompt = prompts.find((p) => p.activityIds.includes(this.activity.activity_id));
					this.heygenPrompts = prompts;
				});
		}
	}

	/**
	 * Determines the grading type that should initially be selected
	 */
	private determineInitiallySelectedGradingType (activity: any): GradingType {
		let gradingType: GradingType;

		if (activity.rubric_template_id > 0) {
			gradingType = this.gradingTypeLookup.INSTRUCTOR_RUBRIC;
		} else if (activity.total_score != null) {
			gradingType = this.gradingTypeLookup.POINTS;
		} else {
			gradingType = this.gradingTypeLookup.NOT_GRADED;
		}

		this.gradingTypeLookup.NOT_GRADED.disabled = this.activity.is_points_possible_readonly;

		return gradingType;
	}

	/**
	 * Determines the privacy setting that should initially be selected
	 */
	private determineInitiallySelectedPrivacySetting (): PrivacyOption {
		switch (this.activity.getPrivacyLevel()) {
			case PrivacyLevel.CLOSED_PEER_REVIEW:
				return this.privacyTypeLookup.CLOSED_PEER_REVIEW;
			case PrivacyLevel.OPEN_PEER_REVIEW:
			case PrivacyLevel.OPEN_FEEDBACK:
				return this.privacyTypeLookup.EVERYONE_SEES_EVERYTHING;
			case PrivacyLevel.PRIVATE:
			default:
				return this.privacyTypeLookup.PRIVATE;
		}
	}

	/**
	 * Determines the rubric template that should initially be selected
	 */
	private determineInitiallySelectedRubric (activity: any): any {
		let rubricTemplate: any = null;

		if (activity.rubric_template_id > 0) {
			rubricTemplate = this.RubricTemplateModel.get({
				id: activity.rubric_template_id
			});
		}

		return rubricTemplate;
	}

	/**
	 * Determines the student rubric that should be initially selected
	 */
	private determineInitiallySelectedStudentRubric (activity: any): any {
		let rubricTemplate: any = null;

		if (activity.peer_rubric_id > 0) {
			rubricTemplate = this.RubricTemplateModel.get({
				id: activity.peer_rubric_id
			});
		}

		return rubricTemplate;
	}

	private getRubricPrivacySettings (hasPeerReview, hasStudentRubric) {
		const peerCritique = 'peer_rubric_critique_enabled';
		const selfCritique = 'self_rubric_critique_enabled';
		const privacySettings = {
			[peerCritique]: false,
			[selfCritique]: false
		};

		if (hasStudentRubric) {
			privacySettings[selfCritique] = true;
			if (hasPeerReview) {
				privacySettings[peerCritique] = true;
			}
		}

		return privacySettings;
	}

	/**
	 * Determines the marker that should initially be selected
	 */
	private determineInitiallySelectedMarkerSet (activity: any): any {
		let markerSet: any = null;

		if (activity.tag_set_id > 0) {
			markerSet = this.TagSetModel.get({
				id: activity.tag_set_id
			});
		}

		return markerSet;
	}
}
