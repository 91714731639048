import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { helpUrlsLookup } from 'go-modules/help-urls/help-urls.constant';
import { DownloadPWADialogComponent } from '../../dialogs/download-pwa/download-pwa-dialog.component';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Masquerade, masqueradeToken } from 'go-modules/masquerade/masquerade.service';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { States } from 'go-modules/enums/states.enum';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { BreadCrumbStateService } from 'ngx/go-modules/src/services/breadcrumb-state/breadcrumb-state.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { SameNameTourService } from 'ngx/go-modules/src/tours/same-name-tour/same-name-tour.service';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';

dayjs.extend(isBetween);

@Component({
	selector: 'dashboard-header',
	template: require('./dashboard-header.component.html'),
	styles: [require('./dashboard-header.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {
	@Input() public showSideNavToggle$: BehaviorSubject<boolean>;
	@Output() public toggleSideNav: EventEmitter<any> = new EventEmitter();

	public currentUser;
	public isLTI = false;
	public helpUrls = helpUrlsLookup;
	public environmentVarsService: EnvironmentVarsService;
	private userServiceSub;
	private componentDestroyed$$ = new Subject();

	constructor (
		private cdr: ChangeDetectorRef,
		public featureFlag: NgxFeatureFlagService,
		public breadCrumbStateService: BreadCrumbStateService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(masqueradeToken) private masquerade: Masquerade,
		@Inject($stateToken) private $state,
		@Inject('Window') private window: Window,
		private authService: NgxAuthService,
		private sameNameTour: SameNameTourService,
		private dialog: MatDialog,
		private translate: TranslateService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public ngOnInit () {
		this.isLTI = this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
		this.currentUser = this.userService.currentUser;
		this.userServiceSub = this.userService.userSubject
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe(() => {
				this.currentUser = this.userService.currentUser;
				this.cdr.detectChanges();
			});

		const startDate = dayjs('2023-09-01');
		const endDate = dayjs('2023-09-21');
		const userCreatedAt = dayjs(this.currentUser.created);
		if (this.currentUser.first_name === this.currentUser.last_name &&
			userCreatedAt.isBetween(startDate, endDate)) {
			this.sameNameTour.getTour().start();
		}
	}

	public ngOnDestroy () {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
		this.userServiceSub.unsubscribe();
	}

	public showAccountSettings () {
		return !(
			this.$state.is(States.DASHBOARD_WIZARD) ||
			this.$state.is(States.DASHBOARD_QUICKSTART_GUIDE)
		);
	}

	public openAccountSettingsPage () {
		this.$state.go(States.DASHBOARD_SETTINGS);
	}

	public openMobileApp () {
		this.dialog.open(DownloadPWADialogComponent);
	}

	public shouldShowAdminTools () {
		return this.authService.isAdmin() || this.currentUser.is_root_user;
	}

	public openAdminToolsPage () {
		// is_free_trial_user only returns true when all licenses they have are free trial
		// when users don't have licenses (student pay) or if they have a paid license, it will return false
		if (this.currentUser.is_root_user || !this.currentUser.is_free_trial_user) {
			// Open a placeholder tab immediately to avoid popup blocking
			const newTab = this.window.open('about:blank', '_blank');

			if (!newTab) {
				// The popup was blocked. Do nothing here and let the browser inform the user
				// by showing the "popup blocked" message in the url bar. This shouldn't happen.
				return;
			}

			// Go get the signed cross domain launch data
			this.authService.getSignedCrossDomainLaunchData().subscribe({
				next: (crossDomainLaunchData) => {
					// Create a form dynamically to do our POST
					const form = document.createElement('form');
					form.method = 'POST';
					form.action = clientSettings.AdminToolsUrl + 'api/v2/launch-cross-domain';

					// Add form fields with data from the async request
					for (const [key, value] of Object.entries(crossDomainLaunchData)) {
						const input = document.createElement('input');
						input.type = 'hidden';
						input.name = key;
						input.value = value;
						form.appendChild(input);
					}

					newTab.document.body.appendChild(form);
					form.submit();
				},
				error: () => {
					// If we have some error, let's just open the admin tools page directly
					// Some people will not be able to login, but it's better than looking
					// at a blank page.
					newTab.location.href = clientSettings.AdminToolsUrl;
				}
			});
		} else {
			this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('common_feature-not-available'),
					message: this.translate.instant('dashboard_feature-not-available-with-free-trial')
				}
			});
		}
	}

	public logout () {
		this.authService.logout().then(() => {
			this.window.location.replace(`https://${this.window.location.host}/dashboard/auth/login/`);
		});
	}

	public isMasked () {
		return this.masquerade.isMasked();
	}

	public unMask () {
		this.masquerade.unMask();
	}

	public shouldShowSaveAndExitButton () {
		return this.environmentVarsService.get(EnvironmentVarsService.VAR.FOLLOW_LTI_RETURN_URL)
			&& !!this.getLaunchPresentationUrl()
			&& !(this.$state.is(States.DASHBOARD_WIZARD)
			|| this.$state.is(States.DASHBOARD_SESSION_VIEW));
	};

	public getLaunchPresentationUrl () {
		return this.environmentVarsService.get(EnvironmentVarsService.VAR.LAUNCH_PRESENTATION_RETURN_URL);
	}
}
