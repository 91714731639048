import angular, {noop} from 'angular';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild, Inject, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { SessionListDataSource } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import { SessionMenuComponent } from '../session-menu/session-menu.component';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';
import { NgxSessionService } from 'ngx/go-modules/src/services/session/session.service';
import { SessionEditorPanelService, sessionEditorPanelServiceToken } from 'go-modules/session-editor-panel/session-editor-panel.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { sessionToken, Session as SessionModel } from 'go-modules/models/session/session.service';
import { VideoShareDialogService } from 'ngx/go-modules/src/components/dialogs/video-share-dialog/video-share-dialog.service';
import { FIRST_COMMENT_TOUR_CLASSES } from 'ngx/go-modules/src/services/go-pulse-tour/constants/first-comment-tour';

@Component({
	selector: 'session-table',
	template: require('./session-table.component.html'),
	styles: [require('./session-table.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionTableComponent implements OnInit {
	public FirstCommentTourClasses = FIRST_COMMENT_TOUR_CLASSES;

	@Output() public goToSession = new EventEmitter();
	@ViewChild('sessionMenu') public sessionMenu: SessionMenuComponent;
	@ViewChild(MenuComponent) public menuComponent: MenuComponent;
	@ViewChild('tableContainer', {read: ElementRef})
	public tableContainer: ElementRef;

	public displayColumns: string[];
	public selectedSession;

	constructor (
		public sessionListDataSource: SessionListDataSource,
		public ngxSessionService: NgxSessionService,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		private cdr: ChangeDetectorRef,
		private videoShareDialogService: VideoShareDialogService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(sessionEditorPanelServiceToken) private sessionEditorPanel: SessionEditorPanelService,
		@Inject(sessionToken) private Session: ReturnType<typeof SessionModel>
	) {}

	public ngOnInit () {
		this.displayColumns = ['affordance', 'name', 'participants', 'comments', 'score', 'status', 'actions'];

		if (!this.shouldDisplayScoreColumn()) {
			const index = this.displayColumns.indexOf('score');
			if (index > -1) {
				this.displayColumns.splice(index, 1);
			}
		}
	}

	public shouldDisplayScoreColumn (): boolean {
		const group = this.selectedService.getGroup();
		const activity = this.selectedService.getActivity();
		return group.hasReviewerRole(true) || !activity.shouldHoldFeedback(group.hasPresenterRole());
	}

	public openMenu (event: MouseEvent, session) {
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}
		if (!this.ngxSessionService.shouldDisplayOptionsMenu(session)) {
			return;
		}

		event.preventDefault();
		this.selectedSession = session;
		this.menuComponent.openMenu(event, this.tableContainer.nativeElement);
 	}

	public unviewedState (session): boolean {
		return !session.viewed;
	}

	public shareSession (session) {
		this.videoShareDialogService.open({
			orgSettings: this.selectedService.getOrg().org_settings,
			sessionId: session.session_id
		}).subscribe((res) => {
			if (res && (['invalidEmails', 'invalidUserNames', 'invalidEmailsAndUserNames'].includes(res.error) || res.openEditUserPanel)) {
				this.onEdit(session);
			}
		});
	}

	private onEdit (session) {
		const sessionCopy = angular.copy(session);

		this.sessionEditorPanel.open({
			session: sessionCopy,
			user: this.userService.currentUser,
			group: this.selectedService.getGroup(),
			activity: this.selectedService.getActivity(),
			activeTab: 1
		})
			.result
			.then((result) => {
				if (result) {
					const editedSession = this.Session.model(result);
					this.sessionListDataSource.editSession(editedSession);
					this.cdr.markForCheck();
				 }
			})
			.catch(noop);
	}
}
