import { Directive, ElementRef, Renderer2, ComponentFactoryResolver, ViewContainerRef, AfterViewInit } from '@angular/core';
import { ShowPasswordButtonComponent } from './show-password-button/show-password-button.component';

@Directive({
	selector: '[goPasswordVisibilityToggle]'
})
export class PasswordVisibilityToggleDirective implements AfterViewInit {
	private passwordInput: HTMLInputElement | null = null;
	private passwordHidden = true;
	private componentRef;

	constructor (
		private el: ElementRef,
		private renderer: Renderer2,
		private componentFactoryResolver: ComponentFactoryResolver,
		private viewContainerRef: ViewContainerRef
	) {}

	public ngAfterViewInit () {
		// Find the password input
		this.passwordInput = this.el.nativeElement.querySelector('input[type="password"]');

		if (this.passwordInput) {
			// Create and append the ShowPasswordButtonComponent
			this.createAndAppendToggleButton();
		}
	}

	private createAndAppendToggleButton () {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ShowPasswordButtonComponent);
		this.componentRef = this.viewContainerRef.createComponent(componentFactory);

		// Set initial state and bind to onClick event
		this.componentRef.instance.passwordHidden = this.passwordHidden;
		this.componentRef.instance.onClick.subscribe(() => this.togglePasswordVisibility());

		const matFormFieldSuffix = this.el.nativeElement.querySelector('.mat-form-field-suffix');
		if (!matFormFieldSuffix) {
			const div = this.renderer.createElement('div');
			this.renderer.addClass(div, 'mat-form-field-suffix');

			this.renderer.appendChild(
				this.el.nativeElement.querySelector('.mat-form-field-flex'),
				div
			);

			this.renderer.appendChild(
				div,
				this.componentRef.location.nativeElement
			);
		} else {
			this.renderer.insertBefore(
				matFormFieldSuffix,
				this.componentRef.location.nativeElement,
				matFormFieldSuffix.firstElementChild
			);
		}

		this.componentRef.changeDetectorRef.detectChanges();
	}

	private togglePasswordVisibility () {
		if (this.passwordInput) {
			this.passwordHidden = !this.passwordHidden;
			const type = this.passwordHidden ? 'password' : 'text';
			this.renderer.setAttribute(this.passwordInput, 'type', type);
			this.componentRef.instance.passwordHidden = this.passwordHidden;
		}
	}
}
