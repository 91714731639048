import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UseType } from 'go-modules/models/use-type/use-type.interface';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { AiPrompt, NgxAiPromptService } from 'ngx/go-modules/src/services/ai-prompt/ai-prompt.service';
import { SignUpService } from 'ngx/go-modules/src/services/sign-up/sign-up.service';
import {
	BehaviorSubject,
	Observable,
	combineLatest,
	forkJoin,
	of,
	Subject
} from 'rxjs';
import { map } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DefaultAiMarkersDialogData {
	mode: string;
	useTypeId: number;
}

export interface DefaultAiMarkersDialogResultData {
	useTypeId: number;
	useTypeName: string;
	aiPrompts: AiPrompt[];
}

@Component({
	selector: 'default-ai-markers-dialog',
	template: require('./default-ai-markers-dialog.component.html'),
	styles: [require('./default-ai-markers-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultAIMarkersDialogComponent implements OnInit, OnDestroy {

	public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	public filterBy$ = new BehaviorSubject({use_type_id: 1});
	public allPrompts$: Observable<AiPrompt[]>;
	public useTypes: UseType[];

	public mode;
	public selectedUseType: UseType;
	public defaultPrompts: AiPrompt[];

	private destroyed$ = new Subject<void>();

	constructor (
		public dialogRef: MatDialogRef<DefaultAIMarkersDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DefaultAiMarkersDialogData,
		private signUpService: SignUpService,
		private aiPromptService: NgxAiPromptService,
		@Inject(selectedServiceToken) private selectedService: SelectedService
	) {}

	public ngOnInit (): void {
		this.mode = this.data.mode;
		this.loading$.next(true);
		const account = this.selectedService.getAccount();

		forkJoin({
			useTypes: this.signUpService.getUseTypes(false, true),
			prompts: this.aiPromptService.getPrompts(account)
		}).subscribe(({useTypes, prompts})=> {
			this.useTypes = this.filterAvailableUseTypesWithDefaults(useTypes, prompts);
			const defaultUseTypeId = this.data.useTypeId ? this.data.useTypeId : account.use_type_id;
			this.selectedUseType = this.useTypes.find((useType) =>
				Number(useType.use_type_id) === Number(defaultUseTypeId)) || this.useTypes[0];
			this.filterUseType(this.selectedUseType);

			this.allPrompts$ = combineLatest([
				of(prompts),
				this.filterBy$
			]).pipe(
				map(([prompts, filterBy]) => {
					this.defaultPrompts = prompts.filter(
						(prompt: AiPrompt) => prompt.use_types
							.some((useType) => useType.use_type_id === filterBy.use_type_id && useType.is_default)
					);
					return this.defaultPrompts;
				})
			);
			this.loading$.next(false);
		});
	}

	public ngOnDestroy (): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	public filterUseType (useType): void {
		this.selectedUseType = useType;
		this.filterBy$.next(useType);
	}

	public cancel () {
		this.dialogRef.close(null);
	}

	public save () {
		const data: DefaultAiMarkersDialogResultData = {
			useTypeId: this.selectedUseType.use_type_id,
			useTypeName: this.selectedUseType.name,
			aiPrompts: this.defaultPrompts
		};
		this.dialogRef.close(data);
	}

	private filterAvailableUseTypesWithDefaults (useTypes, prompts) {
		return useTypes.filter((useType: any) => {
			return prompts.some((prompt: any) =>
				prompt.use_types.some((useTypeInPrompt: any) =>
					useTypeInPrompt.use_type_id === useType.use_type_id && useTypeInPrompt.is_default
				)
			);
		});
	}
}
