import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MessageRememberingDialogComponent } from './message-remembering-dialog.component';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { FormsModule } from '@angular/forms';

@NgModule({
	imports: [
		TranslateModule,
		CommonModule,
		A11yModule,
		FormsModule
	],
	declarations: [
		MessageRememberingDialogComponent
	],
	exports: [
		MessageRememberingDialogComponent
	],
	entryComponents: [
		MessageRememberingDialogComponent
	]
})
export class MessageRememberingDialogModule {}
