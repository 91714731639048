import { PulseTourConfig, PulseTourStep } from 'ngx/go-modules/src/services/go-pulse-tour/go-pulse-tour.service';

const TOUR_KEY = 'default-ai-panel-tour';
const TOUR_STEPS: PulseTourStep[] =  [
	{
		newBadgeSelectors: ['ai-marker-set-panel panel-heading > div.title'],
		completeSelectors: [
			'ai-marker-set-panel .preview-btn > button',
			'ai-marker-set-panel .edit-btn > button',
			'ai-marker-set-panel .remove-btn > button',
			'.ai-markers-btn'
		]
	}
];

export const DEFAULT_AI_TOUR_CONFIG: PulseTourConfig = {
	viewTrackKey: TOUR_KEY,
	steps: TOUR_STEPS,
	constraints: {
		tourStartDate: new Date('2025-02-20'),
		isOnboarding: false
	}
};
