import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { GoPulseTourService } from 'ngx/go-modules/src/services/go-pulse-tour/go-pulse-tour.service';
import { userModel } from 'go-modules/models/user';

export const ngxGoPulseTourServiceModule = angular.module('ngxGoPulseTourServiceModule', [
	userModel
])
	.factory('ngxGoPulseTourService', downgradeInjectable(GoPulseTourService) as any)
	.name;
