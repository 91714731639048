import template from './course-template.html';
import { CourseTemplateController as controller } from './course-template.controller';
import './style.less';

export const courseTemplate = {
	template,
	controller,
	bindings: {
		group: '=',
		currentGroup: '=',
		groups: '=',
		currentUser: '=',
		onActivityCopy: '&',
		orgSettings: '<',
		addDates$: '=',
		duplicateFromGroup: '<',
		autoSelectLicense: '='
	}
};
