import { Component, Inject } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';

export interface InstructionDataInterface {
	text: string;
	type: string;
	media: any;
}

@Component({
	selector: 'instructions-preview',
	template: require('./instructions-preview-dialog.component.html'),
	styles: [require('./instructions-preview-dialog.component.scss')]
})
export class InstructionsPreviewDialogComponent {
	public instruction: InstructionDataInterface;

	constructor (
		public dialogRef: GoDialogRef,
		@Inject(GO_MODAL_DATA) public data: {
			instruction: InstructionDataInterface;
		}
	) {}

	public close () {
		this.dialogRef.close();
	}
}
