import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '@ngx-translate/core';
import { filter, switchMap } from 'rxjs';
import { ResourceTypes } from 'ngx/go-modules/src/enums/archive';
import { ArchivedListDataSource } from 'ngx/go-modules/src/services/archive';
import { NgxSessionService } from 'ngx/go-modules/src/services/session/session.service';
import { ConfirmDialogComponent, ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { NgxCourseService } from 'ngx/go-modules/src/services/course/course.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { FolderListDataSource } from 'ngx/go-modules/src/services/folder-list-datasource/folder-list-datasource';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';
import { SessionListDataSource } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { States } from 'go-modules/enums/states.enum';

interface RestoreResponse {
	parent_id: number;
	parent_name: string;
}

@Component({
	selector: 'archive-menu',
	template: require('./archive-menu.component.html'),
	styles: [require('./archive-menu.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class ArchiveMenuComponent {
	@Input() public archived;

	constructor (
		private dialog: MatDialog,
		private translatePipe: TranslatePipe,
		private sessionService: NgxSessionService,
		private ngxGoToastService: NgxGoToastService,
		private activityService: NgxActivityService,
		private courseService: NgxCourseService,
		private archiveListDataSource: ArchivedListDataSource,
		private folderListDataSource: FolderListDataSource,
		private activityListDataSource: ActivityListDataSource,
		private sessionListDataSource: SessionListDataSource,
		@Inject($stateToken) private $state
	) {}

	public restore () {
		let request;
		switch (this.archived.resource_type) {
			case ResourceTypes.SESSION:
				request = this.sessionService.restore(this.archived.resource_id);
				break;
			case ResourceTypes.ACTIVITY:
				request = this.activityService.restore(this.archived.resource_id);
				break;
			default:
				request = this.courseService.restoreCourse(this.archived.resource_id);
		}
		request.subscribe({
			next: (response: RestoreResponse) => {
				this.archiveListDataSource.remove(this.archived);
				const actionLink = this.createActionLink(this.archived.resource_type, response);
				switch (this.archived.resource_type) {
					case ResourceTypes.SESSION:
						this.sessionListDataSource.reload();
						break;
					case ResourceTypes.ACTIVITY:
						this.activityListDataSource.reload();
						break;
					default:
						this.folderListDataSource.reload();
				}
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'archive-menu_restore-success',
					actionLink
				});
			},
			error: (error) => {
				if (error.status === 422 &&
					[ResourceTypes.ACTIVITY, ResourceTypes.SESSION].includes(this.archived.resource_type)) {
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.ERROR,
						message: this.archived.resource_type === ResourceTypes.SESSION ?
							'archive-menu_restore-unprocessable_error' :
							'archive-menu_restore-unprocessable_error-activity'
					});
				} else {
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.ERROR,
						message: 'archive-menu_restore-error'
					});
				}
			}
		});
	}

	public createActionLink (resourceType: ResourceTypes, response: RestoreResponse) {
		switch (resourceType) {
			case ResourceTypes.SESSION:
				return {
					title: this.translatePipe.transform('archive-menu_restore-destination', {name: response.parent_name}),
					action: () => this.$state.go(States.DASHBOARD_ACTIVITY_VIEW, {activity_id: response.parent_id})
				};
			case ResourceTypes.ACTIVITY:
				return {
					title: this.translatePipe.transform('archive-menu_restore-destination', {name: response.parent_name}),
					action: () => this.$state.go(States.DASHBOARD_FOLDER_VIEW, {folder_id: response.parent_id})
				};
			case ResourceTypes.GROUP:
				return {
					title: this.translatePipe.transform('archive-menu_restore-destination', {name: response.parent_name}),
					action: () => this.$state.go(States.DASHBOARD_FOLDERS, {id: response.parent_id})
				};
			default:
				throw new Error('Unsupported resource type');
		}
	}

	public deletePermanently () {
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translatePipe.transform('common_delete-permanently'),
				message: this.translatePipe.transform('archive-permanent-delete_message', {name: this.getDeleteItemTitle()}),
				confirmText: this.translatePipe.transform('common_permanently-delete')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().pipe(
			filter((confirm: boolean) => confirm),
			switchMap(() => {
				switch (this.archived.resource_type) {
					case ResourceTypes.SESSION:
						return this.sessionService.delete(this.archived.resource_id);
					case ResourceTypes.ACTIVITY:
						return this.activityService.deleteActivity(this.archived.resource_id);
					default:
						return this.courseService.deleteCourse(this.archived.resource_id);
				}
			})
		).subscribe(() => this.archiveListDataSource.remove(this.archived));
	}

	private getDeleteItemTitle (): string {
		return this.archived.name !== null ?
			`<strong>${this.archived.name}</strong>` :
			this.translatePipe.transform('archive-permanent-delete_message-no-name');
	}
}
