import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import './style.less';
import uiRouter from '@uirouter/angularjs';
import { common } from 'go-modules/models/common';
import { InstructorService } from './services/instructor.service';
import { useTypeModel } from 'go-modules/models/use-type';
import { loginViewComponent } from './login/login-view.component';
import { resetPasswordComponent } from './reset-password/reset-password.component';
import { userModel } from 'go-modules/models/user';
import { fullstoryModule } from 'go-modules/services/fullstory';
import { sessionManager } from 'go-modules/session-manager';
import { goPageModule } from 'go-modules/go-page';
import { formValidationModule } from 'go-modules/form-validation';
import { groupConfirmComponent } from './group-confirm/group-confirm.component';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { SignupComponent } from 'ngx/dashboard/src/components/signup/signup.component';
import { signupViewComponent } from './signup-view/signup-view.component';
import { ngxLoginModule } from 'ngx/go-modules/src/components/auth/login';
import { ngxResetPasswordModule } from 'ngx/go-modules/src/components/auth/reset-password';
import { ngxForgotPasswordModule } from 'ngx/go-modules/src/components/auth/forgot-password';
import { forgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ngxDowngradeModalServiceModule } from 'ngx/go-modules/src/services/downgrade-modal';

export const authModule = angular
	.module('auth', [
		useTypeModel,
		common,
		goPageModule,
		angularTranslate,
		formValidationModule,
		uiRouter,
		userModel,
		fullstoryModule,
		sessionManager,
		ngxLoginModule,
		ngxResetPasswordModule,
		ngxForgotPasswordModule,
		ngxDowngradeModalServiceModule
	])
	.directive('ngxSignup', downgradeComponent({ component: SignupComponent }))
	.component('groupConfirm', groupConfirmComponent)
	.component('loginView', loginViewComponent)
	.component('resetPassword', resetPasswordComponent)
	.component('forgotPassword', forgotPasswordComponent)
	.component('signupView', signupViewComponent)
	.service('instructorService', InstructorService)
	.factory('ngxAuthService', downgradeInjectable(NgxAuthService) as any)
	.name;
