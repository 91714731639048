import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { States } from 'go-modules/enums/states.enum';
import { GroupCreatorService, groupCreatorServiceToken } from 'go-modules/group-creator/group-creator.service';
import { goModal, goModalToken } from 'go-modules/modals/go-modal.factory';
import { groupToken } from 'go-modules/models/group-dep/group.factory';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import {
	FilterByOptions,
	FilterByOptionsTranslations,
	FolderListDataSource
} from 'ngx/go-modules/src/services/folder-list-datasource/folder-list-datasource';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { VIEWS } from 'ngx/go-modules/src/enums/views';
import { BehaviorSubject, catchError, EMPTY, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SortOrder, SortOrderTranslations } from 'ngx/go-modules/src/enums/sort-order-main';
import { TranslatePipe } from '@ngx-translate/core';
import type { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';
import { Member, NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { AccountService } from 'ngx/go-modules/src/services/account/account.service';
import { ViewStateDataSource } from 'ngx/go-modules/src/services/view-state-datasource/view-state.datasource';
import { ZeroStateChangedEvent } from 'ngx/go-modules/src/components/data-source-zero-state/data-source-zero-state.component';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';
import { targetElems } from 'ngx/go-modules/src/enums/context-menu-target-elems';
import { GoPulseTourService } from 'ngx/go-modules/src/services/go-pulse-tour/go-pulse-tour.service';
import { FIRST_COMMENT_TOUR_CONFIG } from 'ngx/go-modules/src/services/go-pulse-tour/constants/first-comment-tour';

@Component({
	selector: 'ngx-folders-view',
	template: require('./folders-view.component.html'),
	styles: [require('./folders-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class NgxFoldersViewComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(MenuComponent) public menuComponent: MenuComponent;
	@ViewChild('foldersContainer', {read: ElementRef}) public foldersContainer: ElementRef;
	public readonly componentDestroyed$$ = new Subject();
	public dataSourceZeroState: ZeroStateChangedEvent = {isLoading: true, hasZeroState: false, errorCode: null };
	public currentView$: Observable<VIEWS>;
	public org;
	public account;
	public groups;
	public user;
	public views = VIEWS;
	public hasFolders$: Observable<boolean>;
	public form: FormGroup;
	public filterByOptions: FilterByOptions[] = Object.values(FilterByOptions);
	public filterByTranslations = FilterByOptionsTranslations;
	public sortByOptions: SortOrder[] = Object.values(SortOrder).filter((option) => option !== SortOrder.DEFAULT);
	public sortByTranslations = SortOrderTranslations;
	public instructors$: Observable<Member[]>;
	public isCreating$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor (
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(goModalToken) private goModalService: ReturnType<typeof goModal>,
		@Inject(groupCreatorServiceToken) private GroupCreator: GroupCreatorService,
		@Inject($stateToken) private $state,
		@Inject(groupToken) private group,
		public folderListDataSource: FolderListDataSource,
		public viewStateDataSource: ViewStateDataSource,
		private ngxGroupService: NgxGroupService,
		private accountService: AccountService,
		private cdr: ChangeDetectorRef,
		private pulseTourService: GoPulseTourService
	) {}

	public ngOnInit (): void {
		this.account = this.group.model(this.selectedService.getAccount());
		this.org = this.group.model(this.selectedService.getOrg());
		this.groups = this.selectedService.getGroups();
		this.user = this.userService.currentUser;
		this.folderListDataSource.setOrgId(this.org.group_id);
		this.hasFolders$ = this.folderListDataSource.connect()
			.pipe(map((folders) => folders.length > 0));

		this.currentView$ = this.viewStateDataSource.viewState$;

		this.form = new FormGroup({
			sortBy: new FormControl(this.folderListDataSource.getCurrentSort()),
			filterBy: new FormControl(this.folderListDataSource.getCurrentFilter())
		});
		this.form.get('sortBy').valueChanges
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe((selected) => this.folderListDataSource.sortBy(selected));
		this.form.get('filterBy').valueChanges
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe((selected) => this.folderListDataSource.filterBy(selected));

		if (this.org.is_admin) {
			this.form.addControl('instructorId', new FormControl(this.initializeInstructor()));
			this.form.get('instructorId').valueChanges
				.pipe(takeUntil(this.componentDestroyed$$))
				.pipe(
					tap((instructorId) => localStorage.setItem(`goreact-selected-admin-org-${this.org.group_id}`, instructorId)),
					tap((instructorId) => {
						this.accountService.getUserOrgAccount(instructorId, this.org.group_id)
							.pipe(
								catchError(() => {
									this.selectedService.setAccount(null);
									return EMPTY;
								})
							).subscribe((response) => this.selectedService.setAccount(this.group.model(response)));
					})
				).subscribe((instructorId) => this.folderListDataSource.setInstructorId(instructorId));

			this.instructors$ = this.ngxGroupService.getOrgMembers(this.org.group_id);
		}
	}

	public ngAfterViewInit (): void {
		if (this.userService.currentUser.is_free_trial_user) {
			this.pulseTourService.startTour(FIRST_COMMENT_TOUR_CONFIG).subscribe({
				error: () => {}
			});
		}
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	public openInviteModal (): void {
		if (!this.org.role) {
			this.org.role = this.group.role.INSTRUCTOR;
		}
		this.goModalService.open({
			modal: 'inviteUsers',
			modalData: {
				group: this.org
			}
		});
	}

	public canInvite (): boolean {
		const hasInstructorRole = this.groups.some((gr) => {
			return gr.org_id === this.org.group_id && gr.hasInstructorRole(true);
		});
		return !!this.org && !this.org.placeholder &&
			(this.org.hasAdminRole(true) || hasInstructorRole || this.user.is_root_user);
	}

	public openCreateCoursePanel (): void {
		this.isCreating$.next(true);
		// Use instructorId for org admins using Account dropdown, otherwise default to current user
		const instructorId = this.form.get('instructorId')?.value;
		const userId = instructorId != null ? instructorId : this.userService.currentUser.user_id;
		this.accountService.getOrCreateUserAccount(userId, this.org.group_id)
			.pipe(
				catchError(() => {
					this.isCreating$.next(false);
					return EMPTY;
				})
			).subscribe((account) => {
				const accountModel = this.group.model(account);
				this.GroupCreator.createCourseFolder(
					accountModel,
					this.userService.currentUser,
					userId
				).then((folder: CourseFolder) => {
					this.isCreating$.next(false);
					return this.folderListDataSource.addFolder(folder);
				}).catch(() => this.isCreating$.next(false));
			});
	}

	public canCreateCourse (): boolean {
		return this.account.hasInstructorRole(true) ||
			this.groups.some((group) => group.hasInstructorRole(true) && this.org.group_id !== -4);
	}

	public goToFolder (folder) {
		this.$state.go(States.DASHBOARD_FOLDER_VIEW, {folder_id: folder.group_id});
	}

	public zeroStateChanged (event: ZeroStateChangedEvent) {
		// i tried changing this to an observable but no worky
		this.dataSourceZeroState = event;
		this.cdr.detectChanges();
	}

	public openMenu (event: MouseEvent) {
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}
		if (this.canInvite() || this.canCreateCourse()) {
			const target = event.target as any;
			if (targetElems.includes(target.className)) {
				this.menuComponent.openMenu(event, this.foldersContainer.nativeElement);
			}
		}
	}

	private initializeInstructor (): number {
		const userId = localStorage.getItem(`goreact-selected-admin-org-${this.org.group_id}`);
		if (userId !== null) {
			this.folderListDataSource.setInstructorId(parseInt(userId, 10));
			this.accountService.getUserOrgAccount(Number(userId), this.org.group_id)
				.pipe(
					catchError(() => {
						this.selectedService.setAccount(null);
						return EMPTY;
					})
				).subscribe((response) => this.selectedService.setAccount(this.group.model(response)));
			return parseInt(userId, 10);
		}
		return this.user.user_id;
	}
}
