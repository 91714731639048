import * as angular from 'angular';
import type {
	RawParams,
	StateParams,
	StateService,
	Transition
} from '@uirouter/angularjs';
import { States } from 'go-modules/enums/states.enum';
import type { SelectedService } from 'go-modules/services/selected/selected.service';
import { SessionManagerService } from 'go-modules/session-manager/session-manager.service';

const FALLBACK_KEY = 'fallback-route';
interface FallbackCacheItem {
	name: States;
	params: RawParams | StateParams;
}

export class FallbackRoute {
	private static readonly STATES_TO_CACHE = [
		States.LICENSE_MANAGEMENT,
		States.LICENSE_SEATS_MANAGEMENT,
		States.DASHBOARD_ACTIVITY_VIEW,
		States.DASHBOARD_FOLDER_VIEW,
		States.DASHBOARD_FOLDERS
	];

	private cache: Storage;
	private user;

	/* @ngInject */
	constructor (
		private $log: angular.ILogService,
		private $state: StateService,
		private selectedService: SelectedService,
		private sessionManager: SessionManagerService,
		private $window: ng.IWindowService
	) {
		this.cache = $window.localStorage;
	}

	public setUser (user) {
		this.user = user;
	}

	public capture (transition: Transition): void {
		const fallbackRoute = this.getFallbackFromTransition(transition);

		// Don't remember the routes that are irrelevant to the user.
		// For example, we don't want them to land on any auth page, blocked page, or a specific session
		if (!FallbackRoute.STATES_TO_CACHE.includes(fallbackRoute.name)) {
			this.$log.debug(`FallbackRouteService:: Fallback route ${fallbackRoute.name} not captured because it is not useful`);
			return;
		}

		if (!angular.equals(fallbackRoute, this.get())) {
			this.$log.debug(
				'FallbackRouteService:: Fallback route captured',
				fallbackRoute.name,
				this.paramsToStringForLog(fallbackRoute.params)
			);
			this.set(fallbackRoute);
		}
	}

	public navigate (lastTransition: Transition): void {
		if (this.sessionManager.isActive()) {
			if (!this.validateFallback(lastTransition)) {
				this.$log.debug('FallbackRouteService:: Fallback route not navigated to because it is not available, going to org instead');
				this.navigateWhenCacheUnavailable();
				return;
			}

			const fallback = this.get();
			this.$log.debug(
				'FallbackRouteService:: Navigating to the fallback route',
				fallback.name,
				this.paramsToStringForLog(fallback.params)
			);
			const lastTransitionParams = this.getFallbackFromTransition(lastTransition).params;
			const fallbackParams = fallback.params;
			if (lastTransitionParams.showAccountSettingsPanel) {
				fallbackParams.showAccountSettingsPanel = true;
			}
			this.$state.go(fallback.name, fallbackParams);
		} else {
			if (lastTransition.to().name === States.DASHBOARD_ROOT) {
				this.$state.go(States.AUTH_LOGIN);
			} else {
				this.$state.go(States.AUTH_LOGIN, {
					redirect: this.$window.location.href
				});
			}
		}
	}

	private validateFallback (lastTransition: Transition): boolean {
		const fallback = this.get();
		if (!fallback) {
			this.$log.debug('FallbackRouteService:: No fallback available');
			return false;
		}
		else if (!this.$state.target(fallback.name, fallback.params).valid()) {
			this.$log.debug('FallbackRouteService:: Fallback route in localstorage invalid. Perhaps old url version?');
			this.invalidate();
			return false;
		}
		else if (angular.equals(fallback, this.getFallbackFromTransition(lastTransition))) {
			this.$log.debug('FallbackRouteService:: Fallback route invalidated as it is the same as the last (failed) transition');
			this.invalidate();
			return false;
		}

		return true;
	}

	private navigateWhenCacheUnavailable () {
		// We'd prefer the current one if set. If not, let's just grab the first.
		const org = this.selectedService.getOrg() ||
			(this.selectedService.getMyOrgs() ? this.selectedService.getMyOrgs()[0] : null);

		if (org != null) {
			// And just go to the /folders/orgId route.
			// If not vertical nav, the folders route will handle it.
			this.$state.go(States.DASHBOARD_FOLDERS, {id: org.group_id});
		} else {
			// In the rare case we have no orgs, go to the special no org route
			this.$state.go(States.DASHBOARD_NO_GROUPS);
		}
	}

	private set (fallbackRoute: FallbackCacheItem): void {
		this.cache.setItem(this.getStorageKey(), JSON.stringify(fallbackRoute));
	}

	private get (): FallbackCacheItem|null {
		// Do not want to parse undefined
		if (this.getStorageKey() == null || this.cache.getItem(this.getStorageKey()) == null) {
			return null;
		}
		return JSON.parse(this.cache.getItem(this.getStorageKey())) as FallbackCacheItem|null;
	}

	private invalidate (): void {
		this.$log.debug('FallbackRouteService:: Fallback route invalidated');
		if (this.getStorageKey() != null) {
			this.cache.removeItem(this.getStorageKey());
		}
	}

	private getStorageKey (): string {
		if (this.user == null) {
			return null;
		}
		return `${this.user.user_id}-${FALLBACK_KEY}`;
	}

	private getFallbackFromTransition (transition: Transition): FallbackCacheItem {
		const targetState = transition.targetState();
		return {
			name: targetState.name() as States,
			params: targetState.params()
		};
	}

	private paramsToStringForLog (params: RawParams | StateParams): string {
		return Object.entries(params)
			.map((entry) => entry.join(': '))
			.join(', ');
	}
}
