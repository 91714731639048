import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { Activity } from 'ngx/go-modules/src/interfaces/activity';
import { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';

@Component({
	selector: 'activity-instructions-dialog',
	template: require('./activity-instructions-dialog.component.html'),
	styles: [require('./activity-instructions-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityInstructionsDialogComponent {

	public activity;
	public group;

	constructor (
		public dialogRef: GoDialogRef,
		@Inject(GO_MODAL_DATA) public data: {
			activity: Activity;
			group: CourseFolder;
		},
		@Inject(selectedServiceToken) public selectedService: SelectedService
	) {
		if (this.data) {
			this.activity = this.data.activity;
			this.group = this.data.group;
		} else {
			this.activity = this.selectedService.getActivity();
			this.group = this.selectedService.getGroup();
		}
	}

	public close () {
		this.dialogRef.close();
	}

	public shouldHideRecordingInstructions () {
		// TODO DEV-15860
		return this.group.hasPresenterRole() &&
			this.activity.has_single_recording_attempt &&
			!this.activity.have_i_submitted;
	}
}
