import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as dayjs from 'dayjs';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { ExpirationPolicies } from 'ngx/go-modules/src/enums/salesforce-license/expiration-policies';
import type { License } from 'ngx/go-modules/src/interfaces/licenses';
import { LicenseRenewalSnackBarComponent } from 'ngx/go-modules/src/components/snack-bars/license-renewal';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';

export const EXPIRING_LICENSE_LIST_PREFIX = 'expiring-licenses';

export const enum WarningTypes {
	FIRST = 'first',
	SECOND = 'second',
	FINAL = 'final'
}

@Injectable({
	providedIn: 'root'
})
export class LicenseExpirationHandler {

	public environmentVarsService: EnvironmentVarsService;

	constructor (
		@Inject(userServiceToken) private userService: UserService,
		@Inject('Window') private window: Window,
		private snackBar: MatSnackBar
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public isExpiredLicense (license: License): boolean {
		return license.salesforce_license.expiration_policy === ExpirationPolicies.RESTRICTED &&
			(!license.is_active || dayjs().isAfter(dayjs.utc(license.ends_at)));
	}

	public isExpiringLicense (license: License): boolean {
		const dateToShowWarning = dayjs.utc(license.ends_at).subtract(license.salesforce_license.expiring_warning_days, 'day');

		return !license.is_active ||
			dayjs.utc().isSame(dateToShowWarning) || dayjs.utc().isAfter(dateToShowWarning);
	}

	public isExpiringLicenseRequiringWarning (license: License): boolean {
		return license.salesforce_license.expiration_policy === ExpirationPolicies.RESTRICTED &&
			this.isExpiringLicense(license) &&
			!license.trial;
	}

	public isExpiringLicenseRequiringRenewal (license: License): boolean {
		return !license.salesforce_license.has_renewal &&
			this.isExpiringLicenseRequiringWarning(license) &&
			this.mayAdministerLicense(license);
	}

	public mayAdministerLicense (license: License): boolean {
		return this.userService.currentUser.is_root_user ||
			license.salesforce_license.is_license_admin ||
			license.salesforce_license.is_org_admin;
	}

	public displayLicenseExpirationWarningToastIfNeeded (license: License) {
		const warningType = this.determineWarningType(license);

		if (!this.isWhiteLabel() &&
			!this.hasAlreadyShownWarning(license, warningType) &&
			this.isExpiringLicenseRequiringRenewal(license)) {
			LicenseRenewalSnackBarComponent.open(this.snackBar, { license }).afterDismissed().subscribe(() => {
				this.addLicenseToListOfAlreadyWarnedExpiringLicenses(license, warningType);
			});
		}
	}

	private isWhiteLabel (): boolean {
		return !!this.environmentVarsService.get(EnvironmentVarsService.VAR.WHITELABEL);
	}

	private hasAlreadyShownWarning (license: License, warningType: WarningTypes): boolean {
		if (warningType === WarningTypes.FINAL) {
			const newLicenseList = this.getListOfAlreadyWarnedExpiringLicenses(warningType) ?? {};
			const currentDateString = dayjs.utc().format('YYYY-MM-DD');
			return (newLicenseList[license.id] ??= []).includes(currentDateString);
		}

		const newLicenseList = this.getListOfAlreadyWarnedExpiringLicenses(warningType);

		if (newLicenseList) {
			return newLicenseList.includes(license.id);
		}

		// for users with the "old" schema
		const oldLicenseList = this.getListOfAlreadyWarnedExpiringLicenses();

		if (oldLicenseList) {
			return oldLicenseList.includes(license.id);
		}
	}

	private getListOfAlreadyWarnedExpiringLicenses (warningType?: WarningTypes) {
		let suffix = '';

		if (warningType) {
			suffix += `-${warningType}`;
		}

		const licenseStorage = this.window.sessionStorage.getItem(`${EXPIRING_LICENSE_LIST_PREFIX}${suffix}`);
		return licenseStorage && JSON.parse(licenseStorage);
	}

	private addLicenseToListOfAlreadyWarnedExpiringLicenses (license: License, warningType: WarningTypes) {
		let newLicenseList;

		if (warningType === WarningTypes.FINAL) {
			const currentDateString = dayjs.utc().format('YYYY-MM-DD');
			newLicenseList = this.getListOfAlreadyWarnedExpiringLicenses(warningType) ?? {};
			(newLicenseList[license.id] ??= []).push(currentDateString);
		} else {
			newLicenseList = this.getListOfAlreadyWarnedExpiringLicenses(warningType) ?? [];
			newLicenseList.push(license.id);
		}

		this.window.sessionStorage.setItem(`${EXPIRING_LICENSE_LIST_PREFIX}-${warningType}`, JSON.stringify(newLicenseList));
	}

	private determineWarningType (license: License): WarningTypes
	{
		const { expiring_warning_days } = license.salesforce_license;
		const endDate = dayjs.utc(license.ends_at);
		const currentDate = dayjs.utc();
		const dateToShowWarning = endDate.subtract(expiring_warning_days, 'days');
		const daysPassedSinceThresholdReached = currentDate.diff(dateToShowWarning, 'days');
		const fiftyPercentOfThreshold = Math.round(expiring_warning_days * 0.50);
		const eightyPercentOfThreshold = Math.round(expiring_warning_days * 0.80);

		if (
			daysPassedSinceThresholdReached >= fiftyPercentOfThreshold &&
			daysPassedSinceThresholdReached < eightyPercentOfThreshold
		) {
			return WarningTypes.SECOND;
		} else if (daysPassedSinceThresholdReached >= eightyPercentOfThreshold) {
			return WarningTypes.FINAL;
		}

		return WarningTypes.FIRST;
	}
}
