import { ActivityEditorController } from '../activity-editor.controller';
import { IScope } from 'angular';
import { noop } from 'angular';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { MarkerSetDialogComponent } from 'ngx/go-modules/src/components/dialogs/marker-set-dialog/marker-set-dialog.component';

enum MarkerSetModalMode {
	EDIT_EXISTING = 'edit-existing',
	EDIT_COPY = 'edit-copy'
}

export interface Bindings {
	activityEditorController: ActivityEditorController;
	markerSet: any;
	activity: any;
	onEdited: ({markerSet: any}) => void;
	onRemoved: ({markerSet: any}) => void;
}

export class MarkerSetPanelController implements Bindings {

	// Bindings
	public activityEditorController: ActivityEditorController;
	public markerSet: any;
	public activity: any;
	public onEdited: ({markerSet: any}) => void;
	public onRemoved: ({markerSet: any}) => void;

	public isLoading: boolean;
	public isFetchingEditPermissions: boolean;

	/* @ngInject */
	constructor (
		private $scope: IScope,
		private $translate: ng.translate.ITranslateService,
		private ngxGoModalService: GoModalService,
		private TagSetModel: any,
		private TagModel: any,
		private confirmModal: any,
		private ngxGoToastService: NgxGoToastService,
		private ngxDowngradeModalService: DowngradeModalService
	) {
		this.isLoading = false;
		this.isFetchingEditPermissions = false;
	}

	public $onInit (): void {
		if (!this.markerSet) {
			throw new Error('Property binding `markerSet` is required!');
		} else if (!(this.markerSet instanceof this.TagSetModel)) {
			throw new Error('Property binding `markerSet` must be of type TagSetModel!');
		}

		this.initAsync().then(() => this.$scope.$digest());
	}

	public handleEdit (): Promise<void> {
		this.isFetchingEditPermissions = true;
		return this.TagSetModel.canUpdate({ id: this.markerSet.id }).$promise.then((res: { can_update: boolean } ) => {
			if (res.can_update) {
				this.openEditMarkerModal();
			} else {
				this.openConfirmCopyModals();
			}
		})
			.catch(()=> {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'marker-modifications-permission-error'
				});
			})
			.finally(()=> {
				this.isFetchingEditPermissions = false;
			});
	}

	public async handleRemove (): Promise<void> {
		const activity = this.activity ?? this.activityEditorController.activity;
		const isDefault = activity.name === '__ACCT_DEFAULT__';

		return this.ngxDowngradeModalService.openConfirmDialog({
			title: this.$translate.instant('modal-remove-marker-set_remove-markers'),
			message: isDefault ? this.$translate.instant('modal-remove-marker-set_sure-remove-default', {name: this.markerSet.title}) : this.$translate.instant('modal-remove-marker-set_sure-remove', {name: this.markerSet.title}),
			note: this.$translate.instant('modal-remove-marker-set_note'),
			confirmText: this.$translate.instant('common_remove')
		}).then(() => {
			this.onRemoved({markerSet: this.markerSet});
		}).catch(noop);
	}

	protected async initAsync (): Promise<void> {
		try {
			this.isLoading = true;
			await this.resolveMarkerSet();
		} finally {
			this.isLoading = false;
		}
	}

	protected async resolveMarkerSet (): Promise<void> {
		// Wait for the marker set to load
		if (this.markerSet.hasOwnProperty('$promise')) {
			await this.markerSet.$promise;
		}
	}

	private openConfirmCopyModals (): void {
		this.confirmModal.open({
			size: 'sm',
			modalData: {
				title: 'marker-modifications-disabled',
				message: 'marker-modifications-disabled-description',
				yes: 'common_create-copy',
				no: 'common_cancel'
			}
		}).result
			.then(() => {
				this.openMarkerSetModal(MarkerSetModalMode.EDIT_COPY).afterClosed().subscribe((res) => {
					if (!res.dismissed) {
						this.markerSet = res;
						this.markerSet.tags.sort((a, b) => a.sort_index - b.sort_index);
						this.onEdited({ markerSet: this.markerSet });
						this.safeDigest();
					}
				});
			})
			// We don't need to do anything if the user cancels / dismisses modal
			.catch(noop);
	}

	private openEditMarkerModal (): void {
		this.openMarkerSetModal(MarkerSetModalMode.EDIT_EXISTING).afterClosed().subscribe((res) => {
			if (!res.dismissed) {
				this.markerSet = res;
				this.markerSet.tags.sort((a, b) => a.sort_index - b.sort_index);
				this.onEdited({ markerSet: this.markerSet });
				this.safeDigest();
			}
		});
	}

	private safeDigest () {
		// eslint-disable-next-line angular/no-private-call
		const phase = this.$scope.$root.$$phase || this.$scope.$$phase;
		if (!phase) {
			this.$scope.$digest();
		}
	}

	/**
	 * The same modal is used for copying and editing existing marker sets
	 *
	 * @param mode
	 * @private
	 */
	private openMarkerSetModal (mode: MarkerSetModalMode) {
		let modalData: { markerSet: any; copying?: boolean};

		if (mode === MarkerSetModalMode.EDIT_EXISTING) {
			// If editing, just pass in the entire markerSet
			modalData = {
				markerSet: this.markerSet
			};
		} else if (mode === MarkerSetModalMode.EDIT_COPY) {
			// Select the tag properties we want to copy
			const copiedTags = this.markerSet.tags.map((tag) => new this.TagModel({
				tag_abbreviation: tag.tag_abbreviation,
				tag_color: tag.tag_color,
				tag_name: tag.tag_name
			}));
			// Add "COPY OF" to marker set title
			// Since we are potentially saving a string that was produced via $translate,
			// we need to decode the HTML entities to avoid any & -> &amp; situations
			const copiedTitle = this.$translate.instant('common_copy-of', { title: this.markerSet.title }, null, false, []);
			modalData = {
				markerSet: new this.TagSetModel({ tags: copiedTags, title: copiedTitle }),
				// In order to copy and edit a new marker set, we need to allow "copying"
				copying: true
			};
		}

		return this.ngxGoModalService.open(MarkerSetDialogComponent, false, {
			data: modalData
		});
	}
}
