import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { BehaviorSubject } from 'rxjs';
import {
	LicenseExpirationHandler
} from 'ngx/go-modules/src/services/license-expiration-handler/license-expiration-handler.service';
import { NgxCourseService } from 'ngx/go-modules/src/services/course/course.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { LazyPaymentLoader, lazyPaymentLoaderToken } from 'go-modules/payment-panel/lazy-payment-loader.service';
import * as dayjs from 'dayjs';
import {
	LICENSE_TRANSACTION_TYPE,
	PAYMENT_TYPES,
	PURCHASE_TYPES
} from 'go-modules/payment-panel/payment-panel.controller';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
	ConfirmDialogComponent,
	ConfirmDialogData
} from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { TranslatePipe } from '@ngx-translate/core';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import {
	GroupSettingsPanelService,
	groupSettingsPanelServiceToken
} from 'go-modules/group-settings-panel/group-settings-panel.service';
import { BillingOptions } from 'go-modules/services/group/group.service';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS, LTI_ENVIRONMENT_MODES } from 'ngx/go-modules/src/services/environment-vars/environments';
import { ExpirationPolicies } from 'ngx/go-modules/src/enums/salesforce-license';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { LicenseService } from 'ngx/go-modules/src/services/license/license.service';

@Component({
	selector: 'ngx-expiring-license',
	template: require('./expiring-license.component.html'),
	styles: [require('./expiring-license.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class NgxExpiringLicenseComponent implements OnInit {
	public license$ = new BehaviorSubject<any>(null);
	public loading$ = new BehaviorSubject<boolean>(false);
	public selectedGroup: any;
	public hasOtherAvailableLicense$ = new BehaviorSubject<boolean>(false);
	public environmentVarsService: EnvironmentVarsService;
	public isLTI: boolean = false;
	public isWhiteLabel: boolean = false;

	constructor (
		public licenseExpirationHandler: LicenseExpirationHandler,
		private courseService: NgxCourseService,
		private licenseService: LicenseService,
		private groupService: NgxGroupService,
		private dialog: MatDialog,
		private ngxGoToastService: NgxGoToastService,
		private translatePipe: TranslatePipe,
		private eventService: EventService,
		@Inject(userServiceToken) public userService: UserService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(lazyPaymentLoaderToken) private paymentLoader: LazyPaymentLoader,
		@Inject(groupSettingsPanelServiceToken) private groupSettingsPanel: GroupSettingsPanelService
	) {}

	public ngOnInit () {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
		const environment: any = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) || {};
		this.isLTI = environment.name === ENVIRONMENTS.LTI &&
			environment.mode === LTI_ENVIRONMENT_MODES.DEFAULT;
		this.isWhiteLabel = !!this.environmentVarsService.get(EnvironmentVarsService.VAR.WHITELABEL);
		this.selectedService.selectedSubject
			.subscribe((selected) => {
				if (!selected.group) {
					this.selectedGroup = null;
					return this.setLicense(null);
				}
				if (!selected.group.hasInstructorRole(true)) return this.setLicense(null);

				if (selected.license) {
					this.setLicense(selected.license);
				} else {
					if (selected.group.hasOwnProperty('license')) {
						this.setLicense(selected.group.license);
					}
				}

				this.selectedGroup = selected.group;
			});
		this.eventService.listen([EVENT_NAMES.LICENSE_RENEWED])
			.subscribe(() => {
				this.refreshCourse();
			});
	}

	public setLicense (license) {
		if (license && Object.keys(license).length) {
			this.license$.next(license);
			// check if there are other available licenses so the user can edit the course
			// and select one of those
			// dont show if lti because we dont ever want to show group settings panel in lti
			if (!this.isLTI &&
			this.licenseExpirationHandler.isExpiredLicense(license) &&
			!license.salesforce_license.has_renewal &&
			!this.licenseExpirationHandler.mayAdministerLicense(license)) {
				this.groupService.getEligibleBillingObservable(this.selectedService.getGroup().group_id)
					.subscribe((data: BillingOptions) => {
						this.hasOtherAvailableLicense$.next(data.licenses.length > 0);
					});
			}
		} else {
			this.license$.next(null);
		}
	}

	public getMessageTranslation (license) {
		const numDays = this.calculateDaysLeft(license);
		const translationKeys = {
			message: '',
			tooltip: 'expiring-license_days-remaining-tooltip',
			numDays
		};

		if (this.licenseExpirationHandler.isExpiredLicense(license)) {
			translationKeys.message = 'expiring-license_expired';
			translationKeys.tooltip = 'expiring-license_expired-tooltip';
		} else {
			if (numDays > 1) {
				translationKeys.message = license.salesforce_license.is_free_trial ?
					'expiring-free-trial-license_days-remaining' :
					'expiring-license_days-remaining';
			} else if (numDays === 1) {
				translationKeys.message = license.salesforce_license.is_free_trial ?
					'expiring-license_tomorrow-free-trial' :
					'expiring-license_tomorrow';
			} else {
				translationKeys.message = license.salesforce_license.is_free_trial ?
					'expiring-license_today-free-trial' :
					'expiring-license_today';
			}
		}

		return translationKeys;
	}

	public calculateDaysLeft (license) {
		return dayjs(license.ends_at).diff(dayjs(), 'day');
	}

	public courseEndsAfterLicense (license) {
		// if they are able to administer the license, then prompt them to renew, regardless of when course ends
		if (this.licenseExpirationHandler.isExpiringLicenseRequiringRenewal(license)) {
			return true;
		}
		// if unrestricted expiration policy then we don't care
		if (license.salesforce_license.expiration_policy === ExpirationPolicies.UNRESTRICTED) {
			return false;
		}
		// otherwise we only want them to move the course if it ends after the license does
		// so either neverending course,
		// course end date after license ends at,
		// or license already expired and the course is still going
		return this.selectedService.getGroup().end_date === null ||
			dayjs(this.selectedService.getGroup().end_date).isAfter(dayjs(license.ends_at)) ||
			(!license.is_active && dayjs(this.selectedService.getGroup().end_date).isAfter(dayjs()));
	}

	public actionNeeded (license) {
		return this.licenseExpirationHandler.isExpiringLicenseRequiringRenewal(license) ||
		license.salesforce_license.has_renewal;
	}

	public renewLicense (license) {
		const invertedLicense = license.salesforce_license;
		invertedLicense.license = Object.assign({}, license, {salesforce_license: undefined});
		return this.licenseService.fetchOrCreateUserAccount(
			invertedLicense.license_id,
			this.selectedService.getOrg().group_id
		).subscribe((account)=> {
			this.paymentLoader.openPayForm(
				this.userService.currentUser,
				account,
				() => this.refreshCourse(),
				invertedLicense,
				PAYMENT_TYPES.CARD,
				PURCHASE_TYPES.LICENSE,
				LICENSE_TRANSACTION_TYPE.INITIAL
			);
		});
	}

	public moveFolder () {
		this.loading$.next(true);
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translatePipe.transform('expiring-license_move-course'),
				message: this.translatePipe.transform('expiring-license_move-course-message', {name: this.selectedService.getGroup().name}),
				confirmText: this.translatePipe.transform('expiring-license_move-course')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.courseService.updateCourseLicense(this.selectedService.getGroup().group_id)
					.subscribe({
						next: (license) => {
							this.loading$.next(false);
							this.setLicense(license);
							this.selectedService.setLicense(license);
							this.ngxGoToastService.createToast({
								type: GoToastStatusType.SUCCESS,
								message: 'expiring-license_move-course-success'
							});
						},
						error: () => {
							this.loading$.next(false);
							this.ngxGoToastService.createToast({
								type: GoToastStatusType.ERROR,
								message: 'expiring-license_move-course-error'
							});
						}
					});
			} else {
				this.loading$.next(false);
			}
		});
	}

	public editFolder () {
		this.loading$.next(true);
		this.groupSettingsPanel.open(this.selectedService.getGroup(), this.userService.currentUser, 'info', {}).result.then(
			(group?) => {
				// group is not-null if changed
				if (group != null) {
					this.refreshCourse();
				}
				this.loading$.next(false);
			}
		);
	}

	private refreshCourse () {
		this.courseService.getCourseLicense(this.selectedService.getGroup().group_id)
			.subscribe((license) => {
				this.setLicense(license);
				this.selectedService.setLicense(license);
			});
	}
}
