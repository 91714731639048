import * as angular from 'angular';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { clientSettings } from '../common/client.settings';

/* @ngInject */
export function User (
	$resource: ng.resource.IResourceService,
	modelConfig,
	userModelTransformer
) {
	const basePath = modelConfig.defaults.serviceBaseUrl + '/users/:user_id';

	const UserModel = $resource<any, any>(basePath, {user_id: '@user_id'}, {
		get: {
			method: 'GET',
			transformResponse: userModelTransformer.response,
			url: `${clientSettings.GoReactV2API}/users/:user_id`
		},
		save: {
			method: 'PUT',
			url: `${clientSettings.GoReactV2API}/users/self`,
			transformResponse: userModelTransformer.response,
			transformRequest: userModelTransformer.request
		},
		getByGroup: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/user_group/:groupId/users?includeDeleted=:includeDeleted`,
			isArray: true,
			transformResponse: userModelTransformer.response
		},
		getBillingBalances: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/users/:userId/billing_credits`
		},
		getRecentLoginHistory: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/users/:user_id/recent_login_history`,
			isArray: true
		},
		getSelf: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/users/self`,
			transformResponse: userModelTransformer.response
		},
		getLinkedUsers: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/users/:user_id/linked_users`,
			isArray: true,
			transformResponse: userModelTransformer.response
		},
		restoreRole: {
			method: 'POST',
			url: `${clientSettings.GoReactV2API}/users/:userId/restore/:groupId`,
			params: {userId: '@userId', groupId: '@groupId'}
		},
		getIpAddress: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/ip`
		}
	});

	/**
	 * Creates a new user instance
	 *
	 * @param data
	 * @returns {Object}
	 */
	UserModel.model = function (data) {
		return new UserModel(userModelTransformer.response[1](data || {}));
	};

	/**
	 * Wrap multiple users with the user model
	 *
	 * @param array
	 * @returns {*}
	 */
	UserModel.setModels = function (array) {
		angular.forEach(array, function (data, index) {
			array[index] = UserModel.model(data);
		});
		return array;
	};

	/**
	 * Get user full name
	 *
	 * @param user
	 * @returns {string}
	 */
	UserModel.getFullName = function (user) {
		let name = '';
		if (angular.isObject(user)) {
			const array = [];
			if (user.first_name) {
				array.push(user.first_name);
			}

			if (user.last_name) {
				array.push(user.last_name);
			}
			name = array.join(' ');
		}
		return name;
	};

	// User model prototype

	UserModel.prototype.user_id = null;
	UserModel.prototype.first_name = '';
	UserModel.prototype.last_name = '';
	UserModel.prototype.email = '';
	UserModel.prototype.phone_number = null;
	UserModel.prototype.video_phone_number = null;

	/**
	 * Get Id
	 *
	 * @returns {int}
	 */
	UserModel.prototype.getId = function () {
		return this.user_id;
	};

	/**
	 * Get user full name
	 *
	 * @returns {*}
	 */
	UserModel.prototype.getFullName = function () {
		return UserModel.getFullName(this);
	};

	/**
	 * Save the user
	 *
	 * @returns {*}
	 */
	UserModel.prototype.save = function () {
		return UserModel.save(this).$promise.then((data) => {
			angular.extend(this, data);
		});
	};

	return UserModel;
}

User.NG1_INJECTION_NAME = 'User' as const;
export const userToken = upgradeNg1Dependency(User);
