import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';

interface ApiTourData {
	id: number;
	name: string;
	user_id: number;
	views: number;
	created_at: string;
}

// response can be either ApiTourData (if tour was viewed) or null (if not viewed)
export type ApiGetTourResponse = ApiTourData | null;

export type ApiViewTourResponse = ApiTourData;

@Injectable({
	providedIn: 'root'
})
export class HttpTourService {
	constructor (private http: HttpClient) {}

	public viewTour (name: string) {
		return this.http.put<ApiViewTourResponse>(`${clientSettings.GoReactV2API}/tours/${name}`, {});
	}

	public getTour (name: string) {
		return this.http.get<ApiGetTourResponse>(`${clientSettings.GoReactV2API}/tours/${name}`);
	}
}
