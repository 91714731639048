import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArchiveFilter } from 'ngx/go-modules/src/enums/archive-filter';
import { Observable } from 'rxjs';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { PaginatedArchiveList } from 'ngx/go-modules/src/interfaces/archived/paginated-archive-list';
import { SortOrder } from 'ngx/go-modules/src/enums/sort-order-main';

export const ARCHIVE_PER_PAGE = 200;

@Injectable({
	providedIn: 'root'
})
export class ArchiveService {
	constructor (private http: HttpClient) {}

	public getArchivedItems (
		filter: ArchiveFilter,
		sortBy: SortOrder,
		page: number,
		perPage = ARCHIVE_PER_PAGE
	): Observable<PaginatedArchiveList> {
		const url = `${clientSettings.GoReactV2API}/archived`;
		const params = new URLSearchParams({
			filter,
			sortBy,
			page: page.toString(),
			perPage: perPage.toString()
		});
		return this.http.get<PaginatedArchiveList>(`${url}?${params}`);
	}
}
