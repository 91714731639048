import * as angular from 'angular';
import * as ngTranslate from 'angular-translate';
import * as ngCookies from 'angular-cookies';

import { fineUploaderModule } from 'go-modules/fine-uploader';
import { goAppEnvModule } from 'go-modules/go-app-env';
import { goBannerModule } from 'go-modules/go-banner';
import { goPubnubModule } from 'go-modules/go-pubnub';
import { goModal } from 'go-modules/modals';
import { messageModalModule } from 'go-modules/modals/message';
import { userModel } from 'go-modules/models/user';
import { analyticsModule } from 'go-modules/services/analytics';
import { bannerNotificationsModule } from 'go-modules/services/banner-notifications';
import { fullstoryModule } from 'go-modules/services/fullstory';
import { watsonUsageTrackingModule } from 'go-modules/services/watson-usage-tracking';
import { zendeskModule } from 'go-modules/services/zendesk';
import { sessionManager } from 'go-modules/session-manager';
import { goLocalizeHelperModule } from 'go-modules/translation-helper';
import { AppDataService } from './app-data.service';
import { AppSessionManagerService } from './app-session-manager.service';
import { FallbackRoute } from './fallback-route.service';
import { authServiceModule } from 'go-modules/services/auth';
import { eventServiceModule } from 'ngx/go-modules/src/services/event';

export const dashboardServicesModule = angular.module('dashboardServices', [
	goAppEnvModule,
	goPubnubModule,
	fullstoryModule,
	fineUploaderModule,
	zendeskModule,
	authServiceModule,
	analyticsModule,
	watsonUsageTrackingModule,
	userModel,
	bannerNotificationsModule,
	goLocalizeHelperModule,
	goBannerModule,
	ngTranslate,
	messageModalModule,
	ngCookies,
	goModal,
	sessionManager,
	eventServiceModule
]).service(AppDataService.NG1_INJECTION_NAME, AppDataService)
	.service('appSessionManagerService', AppSessionManagerService)
	.service('fallbackRouteService', FallbackRoute)
	.name;
