import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HeyGenPromptEditorComponent } from './hey-gen-prompt-editor.component';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { HttpClientModule } from '@angular/common/http';
import { HeyGenPromptCreatorComponent } from './hey-gen-prompt-creator/hey-gen-prompt-creator.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		GoMaterialModule,
		ReactiveFormsModule,
		A11yModule,
		HttpClientModule
	],
	declarations: [
		HeyGenPromptEditorComponent,
		HeyGenPromptCreatorComponent
	],
	exports: [
		HeyGenPromptEditorComponent
	],
	entryComponents: [
		HeyGenPromptEditorComponent
	]
})
export class HeyGenPromptEditorComponentModule {}
