import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';

@Component({
	selector: 'welcome-step',
	template: require('./welcome-step.component.html'),
	styles: [require('./welcome-step.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { '[class.free-trial]': 'showFreeTrialStep' }
})
export class WelcomeStepComponent implements OnChanges {
	private safeShowFreeTrialStep$ = new BehaviorSubject<boolean>(false);
	private isE2E = new BehaviorSubject<boolean>(!!this.cookieService.get('e2e-test-run'));
	private videoEnded$ = new BehaviorSubject<boolean>(false);
	private furthestUserWatchTime = 0;
	@Input() public data: { user: any; numDays: any };
	@Input() public showSelfPayStep: boolean;
	@Input() public loading$: Observable<boolean>;
	@Input() public showFreeTrialStep: boolean | undefined;
	@Output() public onProceed = new EventEmitter<void>();

	public videoEnded$$ = this.videoEnded$.asObservable();
	public forceIntroVideoFlag = this.featureFlagService.isAvailable('FORCE_INTRODUCTORY_VIDEO');
	public canClose$: Observable<boolean> = combineLatest([
		this.safeShowFreeTrialStep$,
		this.videoEnded$$,
		this.isE2E,
		of(this.forceIntroVideoFlag)
	]).pipe(
		map(([freeTrialSafelyDetermined, videoEnded, isTestEnv, forceIntroVideoFlag]) => {
			if (!freeTrialSafelyDetermined || isTestEnv) {
				return true;
			}

			return forceIntroVideoFlag ? videoEnded : true;
		}),
		distinctUntilChanged()
	);

	constructor (private cookieService: CookieService, private featureFlagService: NgxFeatureFlagService) {}

	public ngOnChanges (changes: SimpleChanges) {
		// in this specific component, changes.{some-input-property} is undefined on first change (not true/false)
		if (changes.showFreeTrialStep.currentValue !== undefined) {
			this.safeShowFreeTrialStep$.next(changes.showFreeTrialStep.currentValue);
		}
	}

	public onTimeUpdate (event: Event): void {
		const video = event.target as HTMLVideoElement;

		// track the furthest position the user has watched
		if (!video.seeking) {
			this.furthestUserWatchTime = video.currentTime;
		}

		// mark it as ended
		const timeRemaining = video.duration - video.currentTime;
		if (timeRemaining <= 5/*seconds*/ && !this.videoEnded$.getValue()) {
			this.videoEnded$.next(true);
		}
	}

	public onSeeking (event: Event): void {
		if (!this.forceIntroVideoFlag) return;

		const video = event.target as HTMLVideoElement;
		if (!this.videoEnded$.getValue()) {
			const delta = video.currentTime - this.furthestUserWatchTime;
			// if seeking forward (with a small buffer for rounding errors)
			if (delta > 0.01) {
				video.currentTime = this.furthestUserWatchTime;
			}
		}
	}

	public onVideoEnded (): void {
		this.videoEnded$.next(true);
	}

	public proceed (): void {
		this.onProceed.emit();
	}
}
