import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from 'ngx/go-modules/src/components/auth/reset-password/reset-password.component';
import { AuthTemplateModule } from 'ngx/go-modules/src/components/auth-template/auth-template.module';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { EmailVerificationModule } from 'ngx/go-modules/src/components/email-verification/email-verification.module';
import { PasswordVisibilityToggleModule } from 'ngx/go-modules/src/directives/password-visibility-toggle/password-visibility-toggle.module';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';
import {
	PasswordRequirementsModule
} from 'ngx/go-modules/src/directives/password-requirements/password-requirements.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		ReactiveFormsModule,
		FormsModule,
		GoTooltipModule,
		AuthTemplateModule,
		EmailVerificationModule,
		PasswordVisibilityToggleModule,
		FocusOnFirstInvalidFieldModule,
		PasswordRequirementsModule
	],
	exports: [
		ResetPasswordComponent
	],
	declarations: [
		ResetPasswordComponent
	],
	entryComponents: [
		ResetPasswordComponent
	]
})
export class ResetPasswordModule {}
