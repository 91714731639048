import * as angular from 'angular';

import 'go-styles/src/goreact-style.less';

import { activityInfoModule } from './activity-info';
import { goAttachment } from './attachment';
import { commonFiltersModule } from './common-filters';
import { courseEditorModule } from './course-editor';
import { goDateTimePickerModule } from './datetimepicker';
import { detectModule } from './detect';
import { event } from './event-channel';
import { feedbackSessionModule } from './feedback-session';
import { fineUploaderModule } from './fine-uploader';
import { formValidationModule } from './form-validation';
import { goCache } from './go-cache';
import { goListModule } from './go-list';
import { goNoUISliderModule } from './go-nouislider';
import { goPdfModule } from './go-pdf';
import { goPopoverModule } from './go-popover';
import { goPubnubModule } from './go-pubnub';
import { goStorageModule } from './go-storage';
import { helpUrlsModule } from './help-urls';
import { goHotKeys } from './hotkeys';
import { labelInputModule } from './label-input';
import { masqueradeModule } from './masquerade';
import { mediaPlayerModule } from './media-player';
import { mediaPreviewModule } from './media-preview';
import { mediaThumbnailModule } from './media-thumbnail';
import { goModal } from './modals';
import { allModelsModule } from './models';
import { goPlayerControls } from './player-controls';
import { playerSyncModule } from './player-sync';
import { richDropdownModule } from './rich-dropdown';
import { rubricModule } from './rubric';
import { sessionCreationWizardModule } from './session-creation-wizard';
import { sessionEditorModule } from './session-editor';
import { sessionEditorPanelModule } from './session-editor-panel';
import { sessionManager } from './session-manager';
import { SidePanelModule } from './side-panel';
import { time } from './time';
import { timeagoModule } from './timeago';
import { timerModule } from './timer';
import { treeViewModule } from './tree-view';
import { uiDecimal } from './ui-decimal';
import { universalMediaChooserModule } from './universal-media-chooser';
import { updateManagerModule } from './update-manager';
import { userActivityHistory } from './user-activity-history';
import { presenterListModule } from './presenter-list';
import { confirmModalModule } from './modals/confirm';
import { rubricFeedbackViewerModule } from './rubric-feedback-viewer';
import { coursePaymentWallModule } from './course-payment-wall';
import { appRunModule } from './app-run';
import { featureFlagModule } from './feature-flag/index';
import { responsiveViewModule } from './responsive-view/index';
import { uibModalDecoratorModule } from 'go-modules/$uib-modal-decorator';
import { ariaDecoratorModule } from 'go-modules/aria-decorator';
import { groupsIndexModule } from './groups-index';

import 'angular-translate-loader-static-files';
import { ngxGroupServiceModule } from 'ngx/go-modules/src/services/group';
import { ngxGoSidepanelServiceModule } from 'ngx/go-modules/src/services/go-sidepanel';
import { ngxFeatureFlagServiceModule } from 'ngx/go-modules/src/services/feature-flag';
import { ngxUnsavedDataManagerServiceModule } from 'ngx/go-modules/src/services/unsaved-data-manager';

export {
	activityInfoModule,
	goAttachment,
	commonFiltersModule,
	courseEditorModule,
	goDateTimePickerModule,
	detectModule,
	event,
	feedbackSessionModule,
	fineUploaderModule,
	formValidationModule,
	goCache,
	goListModule,
	goNoUISliderModule,
	goPdfModule,
	goPopoverModule,
	goPubnubModule,
	goStorageModule,
	helpUrlsModule,
	goHotKeys,
	labelInputModule,
	masqueradeModule,
	mediaPlayerModule,
	mediaPreviewModule,
	mediaThumbnailModule,
	goModal,
	allModelsModule,
	goPlayerControls,
	playerSyncModule,
	richDropdownModule,
	rubricModule,
	sessionCreationWizardModule,
	sessionEditorModule,
	sessionEditorPanelModule,
	sessionManager,
	SidePanelModule,
	time,
	timeagoModule,
	timerModule,
	treeViewModule,
	uiDecimal,
	universalMediaChooserModule,
	updateManagerModule,
	userActivityHistory,
	presenterListModule,
	confirmModalModule,
	rubricFeedbackViewerModule,
	coursePaymentWallModule,
	appRunModule,
	featureFlagModule,
	responsiveViewModule,
	uibModalDecoratorModule,
	ariaDecoratorModule,
	groupsIndexModule
};

export const goModules = angular.module('go-modules', [
	activityInfoModule,
	goAttachment,
	commonFiltersModule,
	courseEditorModule,
	goDateTimePickerModule,
	detectModule,
	event,
	feedbackSessionModule,
	fineUploaderModule,
	formValidationModule,
	goCache,
	goListModule,
	goNoUISliderModule,
	goPdfModule,
	goPopoverModule,
	goPubnubModule,
	goStorageModule,
	helpUrlsModule,
	goHotKeys,
	labelInputModule,
	masqueradeModule,
	mediaPlayerModule,
	mediaPreviewModule,
	mediaThumbnailModule,
	goModal,
	allModelsModule,
	goPlayerControls,
	playerSyncModule,
	richDropdownModule,
	rubricModule,
	sessionCreationWizardModule,
	sessionEditorModule,
	sessionEditorPanelModule,
	sessionManager,
	SidePanelModule,
	time,
	timeagoModule,
	timerModule,
	treeViewModule,
	uiDecimal,
	universalMediaChooserModule,
	updateManagerModule,
	userActivityHistory,
	presenterListModule,
	confirmModalModule,
	rubricFeedbackViewerModule,
	coursePaymentWallModule,
	appRunModule,
	featureFlagModule,
	responsiveViewModule,
	uibModalDecoratorModule,
	ariaDecoratorModule,
	groupsIndexModule,
	ngxGroupServiceModule,
	ngxGoSidepanelServiceModule,
	ngxFeatureFlagServiceModule,
	ngxUnsavedDataManagerServiceModule
]).name;
