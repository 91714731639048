import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
	selector: 'optional-date-picker-dialog',
	template: require('./optional-date-picker-dialog.component.html'),
	styles: [require('./optional-date-picker-dialog.component.scss')]
})
export class OptionalDatePickerDialogComponent implements OnInit, OnDestroy {
	public readonly destroy$ = new Subject<void>();

	public form: FormGroup = new FormGroup({
		start_date: new FormControl(null),
		end_date: new FormControl(null)
	});

	constructor (
		@Inject(MAT_DIALOG_DATA) public data: {
			submitText: string;
			title: string;
		},
		public dialogRef: MatDialogRef<OptionalDatePickerDialogComponent>,
		public dialog: MatDialog
	) {}

	public ngOnInit () {
		this.form.addValidators(this.dateRangeValidator);
	}

	public ngOnDestroy (): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public dismiss () {
		this.dialogRef.close({ dismissed: true });
	}

	public submit (form) {
		if (form.invalid) {
			return;
		}

		this.dialogRef.close({
			start_date: form.controls.start_date.value,
			end_date: form.controls.end_date.value
		});
	}

	private dateRangeValidator: ValidatorFn = (group: AbstractControl): { [key: string]: any } | null => {
		const start = group.get('start_date')?.value;
		const end = group.get('end_date')?.value;

		// Validate only if both dates are provided
		if (start && end && start > end) {
			return { dateRangeInvalid: true };
		}
		return null;
	};
}
