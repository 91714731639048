import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {  CustomerSurveySnackBarComponent } from './customer-survey-snack-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		GoMaterialModule,
		FocusOnFirstInvalidFieldModule,
		A11yModule
	],
	declarations: [
		 CustomerSurveySnackBarComponent
	],
	entryComponents: [
		 CustomerSurveySnackBarComponent
	],
	exports: [
		 CustomerSurveySnackBarComponent
	]
})
export class CustomerSurveySnackBarModule {}
