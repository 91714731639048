import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as ColorPkg from 'color';
import type { Color } from 'ngx/go-modules/src/interfaces/colors';

interface Bindings {
	abbreviation?: string;
	selectedColor: Color;
	colors: Color[];
	onSelect: EventEmitter<any>;
}

export enum ColorPickerStyle {
	TAG = 'tag-icon',
	BOX = 'box-icon'
};

@Component({
	selector: 'color-menu',
	template: require('./color-menu.component.html'),
	styles: [require('./color-menu.component.scss')]
})
export class ColorMenuComponent implements Bindings {
	@Input() public abbreviation: string;
	@Input() public selectedColor: Color;
	@Input() public colors: Color[];
	@Input() public style: ColorPickerStyle = ColorPickerStyle.TAG;
	@Output() public onSelect: EventEmitter<any> = new EventEmitter();

	constructor (
		private elementRef: ElementRef,
		private translate: TranslateService
	) {
	}

	public getAriaLabel (color: Color, index: number) {
		let ariaLabel = this.translate.instant(color.translation) + ', ';
		const isSelected = this.selectedColor.hex === color.hex;
		ariaLabel += isSelected ? this.translate.instant('common_selected') : this.translate.instant('common_not-selected');
		ariaLabel += ', ';
		ariaLabel += this.translate.instant('common_out-of', {num: index + 1, total: this.colors.length});
		return ariaLabel;
	}

	public bestContrast (hex) {
		const color = ColorPkg(hex);
		return color.contrast(ColorPkg('#ffffff')) > color.contrast(ColorPkg('#333333')) ? '#ffffff' : '#333333';
	}

	public select = (color: Color) => {
		this.selectedColor = color;
		this.elementRef.nativeElement.querySelector('.open-menu-btn').focus();
		this.onSelect.emit(color);
	};
}
