import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { UserSearchDialogComponent } from 'ngx/go-modules/src/components/dialogs/user-search-dialog/user-search-dialog.component';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { groupToken } from 'go-modules/models/group-dep/group.factory';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { States } from 'go-modules/enums/states.enum';
import { GroupSettingsPanelService, groupSettingsPanelServiceToken } from 'go-modules/group-settings-panel/group-settings-panel.service';

@Component({
	selector: 'super-bar',
	template: require('./super-bar.component.html'),
	styles: [require('./super-bar.component.scss')]
})
export class SuperBarComponent implements OnInit {
	public searchValue: string = '';

	constructor (
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(groupToken) private groupModel,
		@Inject($stateToken) private $state,
		@Inject(groupSettingsPanelServiceToken) private groupSettingsPanel: GroupSettingsPanelService,
		private modal: GoModalService,
		private elementRef: ElementRef
	) {}

	public ngOnInit (): void {
		if (!this.userService.currentUser.is_root_user) {
			throw new Error('SuperBar::Not Authorized!');
		}
	}

	public openUserSearch (initialQuery) {
		if (initialQuery?.length > 2) {
			this.modal.open(UserSearchDialogComponent, true, {
				data: {
					initialQuery
				}
			}).afterClosed().subscribe((resource) => {
				if (resource != null) {
					if (resource && resource.session_id) {
						return this.$state.go(States.DASHBOARD_SESSION_VIEW, {
							session_id: resource.session_id
						});
					} else if (resource instanceof this.groupModel.GroupModel) {
						if (resource.isCourse() || resource.isFolder()) {
							return this.$state.go(States.DASHBOARD_FOLDER_VIEW, {folder_id: resource.group_id});
						}

						if (resource.isAccount()) {
							this.selectedService.setAccount(resource);
							return this.groupSettingsPanel.open(resource, this.userService.currentUser, 'defaults', {});
						}

						// resource is org
						this.groupSettingsPanel.open(resource, this.userService.currentUser, '', {});
					}
				}
			});
		}
	}

	public clearSearch () {
		this.searchValue = '';
		const input = this.elementRef.nativeElement.querySelector('.search-input');
		input?.focus();
	}
}
