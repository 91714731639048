import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CardContainerModule } from 'ngx/go-modules/src/components/card-container/card-container.module';
import { CommentIndicatorModule } from 'ngx/go-modules/src/components/comment-indicator/comment-indicator.module';
import { DotIndicatorModule } from 'ngx/go-modules/src/components/dot-indicator/dot-indicator.module';
import { MenuModule } from 'ngx/go-modules/src/components/menus/menu/menu.module';
import { OutsideClickModule } from 'ngx/go-modules/src/directives/outside-click';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FolderMenuModule } from 'ngx/go-modules/src/components/menus/folder-menu/folder-menu.module';
import { FolderCardComponent } from './folder-card.component';
import { BadgeModule } from 'ngx/go-modules/src/components/badge/badge.module';

@NgModule({
	imports: [
		CommonModule,
		GoMaterialModule,
		CardContainerModule,
		DotIndicatorModule,
		FolderMenuModule,
		TranslateModule,
		OutsideClickModule,
		MenuModule,
		CommentIndicatorModule,
		BadgeModule
	],
	declarations: [
		FolderCardComponent
	],
	exports: [
		FolderCardComponent
	]
})
export class FolderCardModule {}
