import { ActivityEditorController as controller } from './activity-editor.controller';
import template from './activity-editor.component.html';

export const activityEditor = {
	bindings: {
		user: '<',
		activity: '<',
		activityTemplates: '<',
		group: '<',
		uploadLimit: '<?',
		hideName: '<',
		hideAvailabilityDate: '<',
		hideDueDate: '<',
		libraryMode: '<',
		isOrgCollectionAndCanModifyItems: '<',
		orgSettings: '<',
		expandFirstSection: '<'
	},
	controller,
	require: {
		formController: '^form'
	},
	template
};
