import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';
import { MarkerSetDialogComponent } from './marker-set-dialog.component';
import { AccessibleDropListModule } from 'ngx/go-modules/src/directives/accessible-drop-list/accessible-drop-list.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColorMenuModule } from 'ngx/go-modules/src/components/color-menu/color-menu.module';
import { TwoLettersPipeModule } from 'ngx/go-modules/src/pipes/two-letters/two-letters.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		A11yModule,
		FocusOnFirstInvalidFieldModule,
		DragDropModule,
		AccessibleDropListModule,
		ColorMenuModule,
		TwoLettersPipeModule
	],
	declarations: [
		MarkerSetDialogComponent
	],
	entryComponents: [
		MarkerSetDialogComponent
	],
	exports: [
		MarkerSetDialogComponent
	]
})
export class MarkerSetDialogModule {}
