import template from './activity-editor-default-marker-set-tour.html';
import { GoTour, GoTourService } from 'go-modules/go-tour/go-tour.service';
import { PlacementEngine } from 'go-modules/go-tour/placement-engine.factory';
import dayjs from 'dayjs';

export class ActivityEditorDefaultMarkerSetTour {
	public readonly startDate = dayjs('2024-09-30 8:00:00').toDate();
	public readonly title = 'activity-editor_default-marker-set-tour-title' as const;
	public readonly viewTrackKey = 'activity-editor-default-marker-set-tour' as const;
	public readonly selector = '.feedback-settings-accordion' as const;
	public tour: GoTour;

	/** @ngInject */
	constructor (
		public goTour: GoTourService,
		private $translate: ng.translate.ITranslateService,
		private PlacementEngine: PlacementEngine
	) {}

	public getTour (): GoTour {
		const placement = this.PlacementEngine.POSITION.RIGHT;
		const title = this.$translate.instant(this.title);

		if (!this.tour) {
			this.tour = this.goTour.defineTour({
				viewTrackKey: this.viewTrackKey,
				constraints: {
					isOnboarding: false,
					tourStartDate: this.startDate,
					maxTourViews: 1
				},
				tourStepClass: this.viewTrackKey,
				steps: [
					{
						tourTitleAriaLabel: title,
						title,
						template,
						selector: this.selector,
						placement
					}
				]
			});
		}

		return this.tour;
	};
}
