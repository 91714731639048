import * as angular from 'angular';
import * as angulartics from 'angulartics';
import * as angularticsGoogleAnalytics from 'angulartics-google-analytics';
import * as angularTranslate from 'angular-translate';
import * as angularAria from 'angular-aria';
import * as angularAnimate from 'angular-animate';

import dropdown from 'angular-ui-bootstrap/src/dropdown';
import modal from 'angular-ui-bootstrap/src/modal';
import popover from 'angular-ui-bootstrap/src/popover';
import tooltip from 'angular-ui-bootstrap/src/tooltip';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as $ocLazyLoad from 'oclazyload';
import { logProvider } from './configs/log-provider.config';
import { compileProviderConfig } from './configs/compiler.config';
import { locationDecorator } from './decorators/location.decorator';
import { uiDatepickerConfig } from './configs/ui-datepicker.config';
import { updateSettings } from './runs/update-settings.run';
import { routesRun } from './runs/routes.run';
import { routesConfig } from './configs/routes.config';
import { FolderEditorController } from './controllers/folder-editor.controller';
import { GroupSettingsUserListItemController } from './controllers/group-settings-user-list-item.controller';
import { ManageUsersController } from './controllers/manage-users.controller';
import { revert } from './directives/revert.directive';
import { environmentConstants } from './constants/environment-constants.constant';
import { requestMonitorModule } from './modules/request-monitor';
import { uibModalDecoratorModule } from 'go-modules/$uib-modal-decorator';
import { groupCreatorModule } from 'go-modules/group-creator';
import { modelRouterModule } from './modules/model-router';
import { updateManagerModule } from 'go-modules/update-manager';
import { goModal } from 'go-modules/modals';
import { fineUploaderModule } from 'go-modules/fine-uploader';
import { richDropdownModule } from 'go-modules/rich-dropdown';
import { goPopoverModule } from 'go-modules/go-popover';
import { goListModule } from 'go-modules/go-list';
import { goDateTimePickerModule } from 'go-modules/datetimepicker';
import { mediaPreviewModule } from 'go-modules/media-preview';
import { feedbackSessionModule } from 'go-modules/feedback-session';
import { sessionEditorModule } from 'go-modules/session-editor';
import { sessionManager } from 'go-modules/session-manager';
import { presenterListModule } from 'go-modules/presenter-list';
import { courseEditorModule } from 'go-modules/course-editor';
import { masqueradeModule } from 'go-modules/masquerade';
import { goPdfModule } from 'go-modules/go-pdf';
import { mediaPlayerModule } from 'go-modules/media-player';
import { rubricModule } from 'go-modules/rubric';
import { goPubnubModule } from 'go-modules/go-pubnub';
import { goAppEnvModule } from 'go-modules/go-app-env';
import { timeagoModule } from 'go-modules/timeago';
import { helpUrlsModule } from 'go-modules/help-urls';
import { detectModule } from 'go-modules/detect';
import { time } from 'go-modules/time';
import { commonFiltersModule } from 'go-modules/common-filters';
import { formValidationModule } from 'go-modules/form-validation';
import { sessionEditorPanelModule } from 'go-modules/session-editor-panel';
import { coursePaymentWallModule } from 'go-modules/course-payment-wall';
import { sessionCreationWizardModule } from 'go-modules/session-creation-wizard';
import { confirmModalModule } from 'go-modules/modals/confirm';
import { appRunModule } from 'go-modules/app-run';
import { allModelsModule, featureFlagModule, groupsIndexModule } from 'go-modules/index';
import { responsiveViewModule } from 'go-modules/responsive-view';
import { unhandledRejectionConfig } from './configs/unhandled-rejection.config';
import { messageModalModule } from 'go-modules/modals/message';
import { groupServiceModule } from 'go-modules/services/group';
import { watsonUsageTrackingModule } from 'go-modules/services/watson-usage-tracking';
import uiRouter from '@uirouter/angularjs';
import { dashboardRootViewModule } from './modules/dashboard-root';
import { AppBaseController } from './controllers/app-base.controller';
import { authModule } from './modules/auth';
import { headerViewModule } from 'go-modules/header';
import { analyticsModule } from 'go-modules/services/analytics';
import { fullstoryModule } from 'go-modules/services/fullstory';
import { zendeskModule } from 'go-modules/services/zendesk';
import { bannerNotificationsModule } from 'go-modules/services/banner-notifications';
import { goBannerModule } from 'go-modules/go-banner';
import { ariaDecoratorModule } from 'go-modules/aria-decorator';
import { dashboardServicesModule } from './services';

// Circular dependency
// tslint:disable-next-line:import-spacing
import 'go-modules/universal-media-chooser';

// Upgraded Angular imports
import { licenseManagementViewRouteModule } from './modules/license-management-view-route';
import { licenseSeatsManagementModule } from './modules/license-seats-management';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { goLogoModule } from 'ngx/dashboard/src/components/go-logo';
import { eventServiceModule } from 'ngx/go-modules/src/services/event';
import { downgradeInjectable } from '@angular/upgrade/static';
import { goLocalizeHelperModule } from 'go-modules/translation-helper';
import { ngxActivityViewModule } from 'ngx/go-modules/src/components/activity-view';
import { ngxSessionViewModule } from 'ngx/go-modules/src/components/feedback-session/session-view';
import { ngxFolderViewModule } from 'ngx/go-modules/src/components/folder-view';
import { ngxArchiveViewModule } from 'ngx/go-modules/src/components/archive/archive-view';
import { ngxNoGroupsModule } from 'ngx/go-modules/src/components/no-groups';
import { activityReportPanelModule } from 'go-modules/activity-report-panel';
import { groupSettingsPanelModule } from 'go-modules/group-settings-panel';
import { csvReportModule } from 'go-modules/csv-report';
import { goTourModule } from 'go-modules/go-tour';
import { ngxUserSettingsModule } from 'ngx/go-modules/src/components/user-settings';
import { transactionModelModule } from 'go-modules/models/transaction';
import { GoLocationModule } from 'go-modules/services/go-location';
import { PWAModule } from 'go-modules/services/pwa';
import { uiViewInnerModule } from 'go-modules/ui-view-inner';
import { removeCurrentFilter } from './runs/remove-current-filter';
// Circular dependency
// tslint:disable-next-line:import-spacing
import 'go-modules/activity-editor-panel';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const dashboardModule = angular.module('app', [
	angularAria,
	angularAnimate,
	allModelsModule,
	updateManagerModule,
	goModal,
	groupServiceModule,
	fineUploaderModule,
	richDropdownModule,
	goPopoverModule,
	goListModule,
	goDateTimePickerModule,
	mediaPreviewModule,
	feedbackSessionModule,
	sessionEditorModule,
	sessionManager,
	presenterListModule,
	courseEditorModule,
	masqueradeModule,
	goPdfModule,
	mediaPlayerModule,
	rubricModule,
	goAppEnvModule,
	goPubnubModule,
	timeagoModule,
	helpUrlsModule,
	detectModule,
	time,
	commonFiltersModule,
	formValidationModule,
	tooltip,
	popover,
	modal,
	dropdown,
	angulartics,
	angularticsGoogleAnalytics,
	requestMonitorModule,
	angularTranslate,
	modelRouterModule,
	groupsIndexModule,
	groupCreatorModule,
	groupSettingsPanelModule,
	sessionEditorPanelModule,
	uibModalDecoratorModule,
	coursePaymentWallModule,
	sessionCreationWizardModule,
	confirmModalModule,
	licenseManagementViewRouteModule,
	licenseSeatsManagementModule,
	ngxActivityViewModule,
	ngxSessionViewModule,
	ngxFolderViewModule,
	ngxArchiveViewModule,
	ngxUserSettingsModule,
	ngxNoGroupsModule,
	appRunModule,
	messageModalModule,
	responsiveViewModule,
	featureFlagModule,
	$ocLazyLoad,
	uiRouter,
	dashboardRootViewModule,
	authModule,
	headerViewModule,
	analyticsModule,
	fullstoryModule,
	zendeskModule,
	bannerNotificationsModule,
	goBannerModule,
	goLocalizeHelperModule,
	ariaDecoratorModule,
	watsonUsageTrackingModule,
	csvReportModule,
	PWAModule,
	dashboardServicesModule,
	'universal-media-chooser',
	goLogoModule,
	eventServiceModule,
	transactionModelModule,
	activityReportPanelModule.name,
	goTourModule,
	GoLocationModule,
	uiViewInnerModule,
	'activity-editor-panel'
])
	// Adding config for $mdGestureProvider will fix <mat-checkbox> not triggering its change event
	// The issue only occurs in Chrome-family browsers in mobile, issue does not occur in Safari
	// Used this ticket with similar issue as reference: https://github.com/angular/components/issues/20725
	.config([
		'$mdGestureProvider',
		// eslint-disable-next-line angular/di
		function ($mdGestureProvider) {
			$mdGestureProvider.disableAll();
		}
	])
	.config(logProvider)
	.factory('ngxGoModalService', downgradeInjectable(GoModalService))
	.factory('ngxTranslationService', downgradeInjectable(TranslateService))
	.decorator('$location', locationDecorator)
	.config(unhandledRejectionConfig)
	.config(uiDatepickerConfig)
	.config(routesConfig)
	.constant('ENVIRONMENT_CONSTANTS', environmentConstants)
	.config(compileProviderConfig)
	.run(routesRun)
	.run(updateSettings)
	.run(removeCurrentFilter)
	.controller('AppBaseController', AppBaseController)
	.controller('FolderEditorController', FolderEditorController)
	.controller('GroupSettingsUserListItemController', GroupSettingsUserListItemController)
	.controller('ManageUsersController', ManageUsersController)
	.directive('revert', revert)
	.name;
