import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';
import { VideoShareDialogComponent } from './video-share-dialog.component';
import { A11yModule } from '@angular/cdk/a11y';
import { UserFormFieldModule } from 'ngx/go-modules/src/components/user-form-field/user-form-field.module';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { CopyToClipboardDirectiveModule } from 'ngx/go-modules/src/directives/go-clipboard/copy-to-clipboard.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		FocusOnFirstInvalidFieldModule,
		A11yModule,
		UserFormFieldModule,
		GoTooltipModule,
		CopyToClipboardDirectiveModule
	],
	declarations: [
		VideoShareDialogComponent
	],
	entryComponents: [
		VideoShareDialogComponent
	],
	exports: [
		VideoShareDialogComponent
	]
})
export class VideoShareDialogModule {}
