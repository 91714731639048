import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceBootstrapComponent } from './components/service-bootstrap/service-bootstrap.component';
import { GoMaterialModule } from './go-material.module';
import { LicenseManagementModule } from 'ngx/go-modules/src/components/license-management';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GoMonitorTabEventModule } from './directives/go-monitor-tab-event';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';
import { FeedbackCreatorModule } from './components/feedback-session/feedback-creator';
import { GoToastModule } from 'ngx/go-modules/src/components/go-toast/go-toast.module';
import { NgxRichTextEditorModule } from './components/rich-text-editor/rich-text-editor.module';
import { LicenseSeatsManagementModule } from './components/license-seats-management';
import { WelcomeDialogModule } from './components/dialogs/welcome-dialog/welcome-dialog.module';
import { NgxActivityViewModule } from 'ngx/go-modules/src/components/activity-view/activity-view.module';
import { GoSelectModule } from './directives/go-select/go-select.module';
import { OutsideClickModule } from './directives/outside-click';
import { LicenseRenewalSnackBarModule } from 'ngx/go-modules/src/components/snack-bars/license-renewal';
import { LoginModule } from './components/auth/login';
import { UserSettingsModule } from 'ngx/go-modules/src/components/user-settings';
import { ResetPasswordModule } from 'ngx/go-modules/src/components/auth/reset-password';
import { CommentReactionsModule } from './components/feedback-session/comment-reactions';
import { TranscriptViewerModule } from './components/feedback-session/transcript-viewer';
import { SessionAnalyticsModule } from 'ngx/go-modules/src/components/feedback-session/session-analytics';
import { SpeedGraderModule } from 'ngx/go-modules/src/components/feedback-session/speed-grader/speed-grader.module';
import { CopyToClipboardDirectiveModule } from 'ngx/go-modules/src/directives/go-clipboard/copy-to-clipboard.module';
import { DropUserDialogModule } from './components/dialogs/drop-user-dialog';
import { GoSidepanelModule } from './services/go-sidepanel/go-sidepanel.module';
import { MessageDialogModule } from './components/dialogs/message-dialog/message-dialog.module';
import { ConfirmDialogModule } from './components/dialogs/confirm-dialog/confirm-dialog.module';
import { ForgotPasswordModule } from 'ngx/go-modules/src/components/auth/forgot-password/forgot-password.module';
import { NgxArchiveViewModule } from 'ngx/go-modules/src/components/archive/archive-view/archive-view.module';
import { DashboardModule } from 'ngx/go-modules/src/components/dashboard/dashboard.module';
import { NgxFolderViewModule } from 'ngx/go-modules/src/components/folder-view/folder-view.module';
import { AIMarkersDialogModule } from './components/dialogs/ai-markers-dialog/ai-markers-dialog.module';
import { WelcomeTourDialogModule } from 'ngx/go-modules/src/components/dialogs/welcome-tour-dialog/welcome-tour-dialog.module';
import { NgxSessionViewModule } from './components/feedback-session/session-view/session-view.module';
import { TimeDisplayModule } from 'ngx/go-modules/src/components/time-display/time-display.module';
import { RateLimitInterceptor } from 'ngx/go-modules/src/services/interceptors/rate-limit-interceptor/rate-limit-interceptor.service';
import { RateLimitDialogModule } from 'ngx/go-modules/src/components/dialogs/rate-limit-dialog/rate-limit-dialog.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TermsOfUseDialogModule } from 'ngx/go-modules/src/components/dialogs/terms-of-use-dialog/terms-of-use-dialog.module';
import { ActivitySourceDialogModule } from 'ngx/go-modules/src/components/dialogs/activity-source-dialog/activity-source-dialog.module';
import { VideoSceneModule } from 'ngx/go-modules/src/components/video-scene/video-scene.module';
import { MarkerFilterDropdownModule } from './components/feedback-session/feedback-filters/marker-filter/marker-filter-dropdown.module';
import { CommentFilterDropdownModule } from './components/feedback-session/feedback-filters/comment-filter/comment-filter-dropdown.module';
import { VideoDetailsDialogModule } from 'ngx/go-modules/src/components/dialogs/video-details-dialog/video-details-dialog.module';
import { CompareProductsDialogModule } from './components/dialogs/compare-products-dialog/compare-products-dialog.module';
import {
	LicenseUpgradeSnackBarModule
} from 'ngx/go-modules/src/components/snack-bars/license-upgrade/license-upgrade-snack-bar.module';
import { RubricFilterDropdownModule } from './components/feedback-session/feedback-filters/rubric-filter/rubric-filter-dropdown.module';
import { PaymentPanelModule } from 'ngx/go-modules/src/components/payment-panel/payment-panel.module';
import { LibraryCollectionViewerModule } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.module';
import { NgxNoGroupsModule } from 'ngx/go-modules/src/components/no-groups/no-groups.module';
import { SuperBarModule } from 'ngx/dashboard/src/components/super-bar/super-bar.module';
import { InterceptorsModule } from './services/interceptors';
import { CopyPreviousAssignmentDialogModule } from 'ngx/go-modules/src/components/dialogs/copy-previous-assignment-dialog/copy-previous-assignment-dialog.module';
import { StartTestDialogModule } from './components/dialogs/start-test-dialog/start-test-dialog.module';
import { NgxToolbarModule } from 'ngx/go-modules/src/components/toolbar/toolbar.module';
import { NgxToolbarButtonModule } from 'ngx/go-modules/src/components/toolbar/toolbar-button/toolbar-button.module';
import { MediaCardModule } from 'ngx/go-modules/src/components/media-card/media-card.module';
import { GoUploaderModule } from './components/go-uploader/go-uploader.module';
import { IosUploadDialogModule } from './components/dialogs/ios-upload-dialog/ios-upload-dialog.module';
import { AccountLockoutDialogModule } from './components/dialogs/account-lockout-dialog';
import { CountryPickerModule } from './components/country-picker/country-picker.module';
import { RichInstructionsModule } from './components/rich-instructions/rich-instructions.module';
import { MessageRememberingDialogModule } from './components/dialogs/message-remembering/message-remembering.dialog.module';
import { InstructionsPreviewDialogModule } from './components/dialogs/instructions-preview/instructions-preview-dialog.module';

// Only import modules here if you really need to (i.e.
// - the module exports a directive
// - the component is opened from a service)
// - the component is downgraded and used in ng1 (if so leave a comment about when to remove)
@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		GoMaterialModule,
		InterceptorsModule,
		LicenseManagementModule, /* TODO - remove when licenseManagementRouteView STAB-802 and routingConfig */
		LicenseSeatsManagementModule, /* TODO - remove when licenseSeatsManagement STAB-637 and routingConfig  */
		FeedbackCreatorModule, /* TODO - remove when feedbackSession ngx STAB-680 */
		A11yModule,
		CopyToClipboardDirectiveModule,
		GoMonitorTabEventModule,
		NgxZeroStateModule, /* TODO - remove when rubricFeedbackViewer STAB-748, coursePaymentWall STAB-615, activityReport STAB-661 */
		GoToastModule,
		NgxRichTextEditorModule, /* TODO - remove when activityInstructionsEditor STAB-947 */
		GoSelectModule,
		WelcomeDialogModule, /* TODO - remove when dashboardRootView STAB-634 */
		NgxActivityViewModule, /* TODO - remove when routesConfig ngx STAB-1182 */
		OutsideClickModule,
		LicenseRenewalSnackBarModule,
		LicenseUpgradeSnackBarModule,
		LoginModule, /* TODO - remove when loginView STAB-630 */
		UserSettingsModule, /* TODO - remove when routesConfig ngx STAB-1182 */
		ResetPasswordModule, /* TODO - remove when resetPassword STAB-631 */
		CommentReactionsModule, /* TODO - remove when feedbackSession ngx STAB-680 */
		TranscriptViewerModule, /* TODO - remove when feedbackSession ngx STAB-680 */
		SessionAnalyticsModule, /* TODO - remove when feedbackSession ngx STAB-680 */
		SpeedGraderModule, /* TODO - remove when feedbackSession ngx STAB-680 */
		DropUserDialogModule, /* TODO - remove when coursePaymentWall STAB-615 */
		GoSidepanelModule,
		MessageDialogModule,
		ConfirmDialogModule,
		ForgotPasswordModule, /* TODO - remove when forgotPassword ngx STAB-628 */
		NgxArchiveViewModule, /* TODO - remove when routesConfig ngx STAB-1182 */
		DashboardModule,
		NgxFolderViewModule, /* TODO - remove when routesConfig ngx STAB-1182 */
		NgxSessionViewModule, /* TODO - remove when routesConfig ngx STAB-1182 */
		AIMarkersDialogModule, /* TODO - remove when activityEditor STAB-645 and feedbackDisplay STAB-846 */
		WelcomeTourDialogModule,
		RateLimitDialogModule,
		TermsOfUseDialogModule, /* TODO - remove when dashboardRootView STAB-634 */
		ActivitySourceDialogModule, /* TODO - remove when activityEditorWizardView STAB-807 */
		TimeDisplayModule, /* TODO - remove when videoScene STAB-779 */
		VideoSceneModule, /* TODO - remove when UMCRecordController STAB-774 and videoScene STAB-779 */
		CommentFilterDropdownModule, /* TODO - remove when feedbackSession STAB-680 */
		MarkerFilterDropdownModule, /* TODO - remove when feedbackSession STAB-680 */
		RubricFilterDropdownModule, /* TODO - remove when rubricFeedbackViewer STAB-748 */
		VideoDetailsDialogModule, /* TODO - remove when feedbackSessionHeader STAB-686 and SessionEditorPanel STAB-916 */
		CompareProductsDialogModule, /* TODO - remove when licensePaymentWizardView STAB-810 licenseDetailsForm STAB-738 and LazyPaymentLoader STAB-984 */
		PaymentPanelModule, /* TODO - remove from here when paymentPanel STAB-908 */
		LibraryCollectionViewerModule, /* TODO - remove from here when umcLibrary STAB-776 and libraryCollectionViewer STAB-721 */
		NgxNoGroupsModule, /* TODO - remove from here when routesConfig ngx STAB-1182 */
		SuperBarModule, /* TODO - remove from here when dashboardRootView ngx STAB-634 */
		CopyPreviousAssignmentDialogModule, /* TODO - remove from here when activityEditorWizardView ngx STAB-807  */
		StartTestDialogModule, /* TODO - remove from here when sessionCreationWizardStartTestStep STAB-1126 */
		NgxToolbarModule,
		NgxToolbarButtonModule,
		MediaCardModule, /* TODO - remove from here when activityInfo STAB-656 */
		RichInstructionsModule, /* TODO - remove from here when activityInfo STAB-656 */
		GoUploaderModule,
		IosUploadDialogModule,
		AccountLockoutDialogModule, /* TODO - remove when loginView STAB-630 */
		CountryPickerModule,
		MessageRememberingDialogModule, /* TODO - remove from here when videoScene STAB-779 */
		InstructionsPreviewDialogModule /* TODO - remove from here when activityEditor STAB-645 and activityInstructionsEditor STAB-947 */
	],
	declarations: [
		ServiceBootstrapComponent
	],
	exports: [
		ServiceBootstrapComponent
	],
	entryComponents: [
		ServiceBootstrapComponent
	],
	providers: [
		{ provide: 'Window', useValue: window },
		{ provide: HTTP_INTERCEPTORS, useClass: RateLimitInterceptor, multi: true }
	]
})
export class GoModulesModule {}
